import React from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {getDataSpecs, getPricingSpecs} from "../../actions/pipelineActions";
import {withRouter} from "react-router-dom";
import styled from "styled-components";
import {LayoutStretch} from "../../../../utils/UIUtils";
import {deck} from "../../../../assets/copy/CopyDeck";
import ListHeader from "../../../ClientManager/components/ListHeader/ListHeader";
import {Form} from "react-final-form";
import TextField from "../../../../components/FormComponents/TextField";
import TextArea from "../../../../components/FormComponents/TextArea";
import LoadingElement from "../../../../components/Loading/LoadingElement";
import DropDown from "../../../../components/FormComponents/DropDown.js";
import color_palette from "../../../../assets/color/style-constants";

const TopContainer = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;

  background-color: ${color_palette.card_background_color};
  border-radius: 4px;
  box-shadow: -2px 2px 4px 0 ${color_palette.default_box_shadow};
`;

const FormContainer = styled.div`

  margin: 15px;
`;

const SubmitContainer = styled.div`
  margin-top: 15px;
`;

const SubmitButton = styled.button`

  width: 140px;
  height: 35px;
  border-radius: 2px;
  border: solid 1px ${color_palette.default_border};

  background-color: ${color_palette.blue_action_button};
  color: white;
`;

const required = value => (value ? undefined : deck().create_subscription_required);

class ViewFileSchema extends React.Component {

  constructor(props){
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {

    if (!this.props.dataSpecs) {
      this.props.dispatch(getDataSpecs());
    }

    if (!this.props.pricingSpecs) {
      this.props.dispatch(getPricingSpecs());
    }
  }

  componentDidUpdate() {

    this.isEditing();

  }

  isNewFile() {

    let routeDetails = this.routeDetails();
    return routeDetails.file === "new-file";
  }

  isEditing() {

    if (!this.props.editingSpec) {
      return false;
    }

    let routeDetails = this.routeDetails();

    if (routeDetails.file === "new-file") {
      return true;
    }

    //TODO: Verify that the IDs are related to the Data Spec or the Price Spec; currently not unique!
    return this.props.editingSpec.id === routeDetails.specId && this.props.editingSpec.version === routeDetails.version;

  }

  routeDetails() {
    const { specification } = this.props;
    const splitString = this.props.router.location.pathname.split('/');

    const specType = splitString[2];
    const specId = splitString[3];
    const version = parseInt(splitString[4]);
    const file = specification ? specification.name : splitString[5];

    return ({
      specType: specType,
      specId: specId,
      version: version,
      file: file,
    });

  }

  currentFile() {
    let currentFile;

    if (!this.props.dataSpecs || !this.props.pricingSpecs) {
      return;
    }

    let routeDetails = this.routeDetails();

    if (this.isNewFile()) {

      return {
        name: "",
        fileNamePattern: {
          expression: ""
        },
        fileType: "",
      }
    }

    if (this.isEditing()) {

      this.props.editingSpec.dataFileSpecifications.map(fileSpecification => {

        if (fileSpecification.name === routeDetails.file){
          currentFile = fileSpecification;
        }
      });

      return currentFile;
    }

    let currentSpec = this.currentSpecVersion();
    if (currentSpec &&
      currentSpec.dataFileSpecifications) {

      currentSpec.dataFileSpecifications.map(fileSpecification => {

        if (fileSpecification.name === routeDetails.file){
          currentFile = fileSpecification;
        }

      });
    }

    return currentFile;
  }

  currentSpecVersion() {
    let currentSpecVersion;

    if (!this.props.dataSpecs || !this.props.pricingSpecs) {
      return;
    }

    let routeDetails = this.routeDetails();

    if (routeDetails.specType === "view-dataspecs") {

      this.props.dataSpecs.map(spec => {

        if (spec.id === routeDetails.specId &&
          spec.version === routeDetails.version) {

          currentSpecVersion = spec;
        }

      });

    } else if (routeDetails.specType === "view-pricing-config") {

      this.props.pricingSpecs.map(spec => {

        if (spec.id === routeDetails.specId &&
          spec.version === routeDetails.version) {

          currentSpecVersion = spec;
        }

      });

    }

    return currentSpecVersion;
  }

  handleSubmit(values) {

    this.props.handleSubmit(this.props.onSubmitCallback, this.props.editingSpec, values);

  }

  renderViewFileOptions() {

    return (
      <div>
        <TextField
          name="nameField"
          placeholder={deck().file_specification_file_name_placeholder}
          title={deck().file_specification_file_name_title}
          validation={required}
          disabled={!this.isEditing()}
        />

        <TextField
          name="filePatternField"
          placeholder={deck().file_specification_file_name_pattern_placeholder}
          title={deck().file_specification_file_name_pattern_title}
          validation={required}
          disabled={!this.isEditing()}
        />

        {this.isEditing() ? (
          <DropDown
            name="fileTypeField"
            placeholder={deck().file_specification_file_type_placeholder}
            title={deck().file_specification_file_type_title}
            validation={required}
            dropDownOptions={['CSV']} />
        ) : (
          <TextField
            name="fileTypeField"
            placeholder={deck().file_specification_file_type_placeholder}
            title={deck().file_specification_file_type_title}
            validation={required}
            disabled
          />
        )}

        <TextArea
          name="fileSchemaField"
          title={deck().file_specification_file_schema_title}
          titleTooltip={deck().file_specification_file_schema_title_tooltip}
          validation={required}
          disabled={!this.isEditing()}
        />
      </div>
    );
  }

  renderSubmit(submitting) {

    if (this.isEditing()) {
      return (
        <SubmitContainer className="btn-group justify-content-center d-flex" >
          <SubmitButton type="submit" disabled={submitting}>
            {deck().create_subscription_submit_action}
          </SubmitButton>
        </SubmitContainer>
      );
    }

  }

  renderSimpleForm() {

    let currentFile = this.currentFile();

    let initialValues = {
      nameField: currentFile.name,
      filePatternField: currentFile.fileNamePattern.expression,
      fileTypeField: currentFile.fileType,
      fileSchemaField: JSON.stringify(currentFile.fileContentSchema, undefined, 4),
    };

    return(
      <FormContainer>
        <Form
          initialValues={initialValues}
          onSubmit={this.handleSubmit}>

          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>

              {this.renderViewFileOptions()}

              {this.renderSubmit(submitting)}

            </form>
          )}
        </Form>
      </FormContainer>
    );

  }

  renderListHeader() {
    const { layoutStretch } = this.props;

    if (layoutStretch  === LayoutStretch.PRIMARY_FULL) {

      let viewFileLabel = deck().file_specification_view_title;
      if (this.currentSpecVersion()) {
        viewFileLabel = viewFileLabel + " for " + this.currentSpecVersion().name + " - v" + this.currentSpecVersion().version;
      }

      return (
        <ListHeader
          title={viewFileLabel} />
      );
    }
  }


  render() {

    let currentFile = this.currentFile();
    if (!currentFile){
      return(<LoadingElement/>);
    }

    return(
      <div>

        {this.renderListHeader()}

        <TopContainer className="container">

          {this.renderSimpleForm()}
        </TopContainer>

      </div>
    );
  }
}

ViewFileSchema.propTypes = {
  dispatch: PropTypes.func,
  specification: PropTypes.shape({
    name: PropTypes.string,
    fileType: PropTypes.string,
    fileNamePattern: PropTypes.object,
    fileContentSchema: PropTypes.object,
  }),
  cardSelected: PropTypes.func,
  selected: PropTypes.bool,
  dataSpecs: PropTypes.object,
  pricingSpecs: PropTypes.object,
  router: PropTypes.object,
  layoutStretch: PropTypes.number,
  editingSpec: PropTypes.object,
  history: PropTypes.object,
  onSubmitCallback: PropTypes.func,
  handleSubmit: PropTypes.func,
};

ViewFileSchema.defaultProps = {
  onSubmitCallback: () => {}
};

function mapStateToProps(state) {
  return {
    router: state.router,
    dataSpecs: state.PipelineManager.pipeline.dataSpecs,
    pricingSpecs: state.PipelineManager.pipeline.pricingSpecs,
    editingSpec: state.PipelineManager.specEdit.editingSpec,
  };
}

export default connect(
  mapStateToProps,
)(withRouter(ViewFileSchema));

