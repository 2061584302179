import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {deck} from "../../assets/copy/CopyDeck";
import { ErrorIcon } from "../Icons";
import color_palette from "../../assets/color/style-constants";

const IconRow = styled.div`

  height: 90px;
`;

const Title = styled.p`
  font-size: 17px;
  color: ${color_palette.dark_text};
  
  margin-bottom: 0px;
`;

const SupportTitle = styled.p`
  font-size: 13px;
  color: ${color_palette.light_header};
  
  margin-bottom: 0px;
`;

const TitleRow = styled.div`
  margin-bottom: 15px;
`;

const ErrorLabel = styled.p`
  font-size: 13px;
  color: ${color_palette.cancel_item};
  
  margin-bottom: 0px;
`;

const ErrorCodeLabel = styled.p`
  font-size: 13px;
  color: ${color_palette.cancel_item};
`;

const ErrorMessageLabel = styled.p`
  font-size: 14px;
  color: ${color_palette.cancel_item};
`;

class ErrorElement extends React.PureComponent {

  constructor(props) {
    super(props);

    this.renderErrors = this.renderErrors.bind(this);

  }

  renderErrors(errors) {

    if (!errors) {
      return
    }

    let errorElements = [];

    errors.map((error, index) => {

      let errorText = "";
      if (error.error) {
        errorText = error.error;
      }

      let errorCodeText = "";
      if (error.errorCode) {
        errorCodeText = error.errorCode;
      }

      let errorMessageText = "";
      if (error.errorMessage) {
        errorMessageText = error.errorMessage;
      }

      errorElements.push(
        <div className="row"
          key={"errorOccurred" + index}>

          <div className="col-3 text-right">
            <ErrorLabel>{errorText}</ErrorLabel>
            <ErrorCodeLabel>{errorCodeText}</ErrorCodeLabel>
          </div>

          <div className="col-9 text-left">
            <ErrorMessageLabel>{errorMessageText}</ErrorMessageLabel>
          </div>

        </div>
      );
    });

    return errorElements;
  }


  render() {

    let type = null;
    if (this.props.type) {
      type = (
        <SupportTitle>{deck().error_type_label}{this.props.type}</SupportTitle>
      );
    }

    return (
      <div className="container">

        <div className="row">
          <IconRow className="col col-sm-8 col-md-6 col-lg-6 col-xl-6 text-center">
            <ErrorIcon height={"80px"} width={"80px"} fill={color_palette.cancel_item} />

          </IconRow>
        </div>

        <TitleRow className="row">
          <div className="col col-sm-8 col-md-6 col-lg-6 col-xl-6 text-center">

            <Title>{deck().error_title_label}</Title>
            <SupportTitle>{deck().error_support_title_label}</SupportTitle>
            {type}

          </div>
        </TitleRow>

        {this.renderErrors(this.props.errors)}
      </div>
    );
  }
}

ErrorElement.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.shape({
    error: PropTypes.string,
    errorCode: PropTypes.number,
    errorMessage: PropTypes.string
  })),
  type: PropTypes.string,
};

export default ErrorElement;
