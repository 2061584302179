import { combineReducers } from 'redux';
import pipelineReducer from "./pipelineReducer";
import specEditReducer from "./specEditReducer";

const PipelineManager = combineReducers({
  pipeline: pipelineReducer,
  specEdit: specEditReducer,
});

export default PipelineManager;
