import React from "react";
import PropTypes from "prop-types";

const RefreshIcon = ({ height, width, fill, ...rest }) => (

  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 290 312.5"
    {...rest}

    >
    <path d="M221 205c-24 29-59 45-96 45C56 250 0 194 0 125S56 0 125 0c62 0 113 45 123 105l26-33 16 12-49 62-62-48 13-16 37 29c-7-51-51-91-104-91C67 20 20 67 20 125s47 105 105 105c31 0 60-14 80-38l16 13z"
          fill={fill}
          fillRule="evenodd"/>
  </svg>
);

RefreshIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
};

RefreshIcon.defaultProps = {
  fill: "#000",
  height: "200",
  width: "200"
};

export default RefreshIcon
