import {Field} from "react-final-form";
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import AceEditor from 'react-ace';
import 'brace/mode/java';
import 'brace/mode/json';
import 'brace/theme/github';
import sizeMe from "react-sizeme";
import { Tooltip } from "@blueprintjs/core";
import color_palette from "../../assets/color/style-constants";

const TitleLabel = styled.label`
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${color_palette.dark_text};
`;

const ErrorLabel = styled.span`
  color: ${color_palette.cancel_item};
`;

class TextArea extends React.Component {

  constructor(props){
    super(props);
  }

  componentDidMount() {
    // Prevent editor scrolling out of view on focus
    let editor = this.aceEditor.editor;
    let textarea = document.querySelector(`#${editor.container.id} textarea`);
    editor.focus = () => {
      textarea.focus({ preventScroll: true });
    };
  }

  render() {

    const DEFAULT_WIDTH = 600;
    var textAreaWidth = this.props.size.width ? this.props.size.width : DEFAULT_WIDTH;

    let title = (<TitleLabel>{this.props.title}</TitleLabel>);
    if (this.props.titleTooltip) {
      title = (
        <Tooltip content={this.props.titleTooltip}>
          <TitleLabel>{this.props.title}</TitleLabel>
        </Tooltip>
      );
    }

    return(
      <Field
        name={this.props.name}
        validate={this.props.validation}>

        {({ input, meta }) => (

          <div >

            {title}

            <div>
              <AceEditor
                ref={ref => this.aceEditor = ref}
                editorProps={{
                  $blockScrolling: Infinity
                }}
                mode="json"
                theme="github"
                readOnly={this.props.disabled}
                value={input.value}
                onChange={input.onChange}
                width={textAreaWidth + "px"}
                maxLines={Infinity}
              />

            </div>

            <div >
              {meta.error && meta.touched && <ErrorLabel>{meta.error}</ErrorLabel>}
            </div>
          </div>
        )}

      </Field>
    );
  }
}


TextArea.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  titleTooltip: PropTypes.string,
  placeholder: PropTypes.string,
  validation: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.object,
};

export default sizeMe()(TextArea);
