import React from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { Redirect } from "react-router-dom";
import ViewClients from "./views/ViewClients/ViewClients";
import ViewOfferings from "./views/ViewOfferings/ViewOfferings";
import GenericPage from "../../components/GenericPage/GenericPage";
import {PropsRoute, wrapHOC} from "../../utils/RouteUtils";
import {LayoutSide, LayoutStretch} from "../../utils/UIUtils";
import {deck} from "../../assets/copy/CopyDeck";
import ViewSubscriptionSideBar from "./views/ViewClients/ViewSubscriptionSideBar";
import CreateSubscription from "./views/CreateSubscription/CreateSubscription";
import { canCreateSubscription } from "../../reducers";


class ClientManager extends React.Component {

  static mapRoutes(canCreateSubscription) {

    return(
      <switch>
        <PropsRoute
          path={'/client-manager/view-clients'}
          exact
          component={GenericPage}
          components={[
            {
              component: wrapHOC(ViewClients),
              layoutStretch: LayoutStretch.PRIMARY_FULL,
            },
            {
              component: wrapHOC(ViewSubscriptionSideBar),
              layoutStretch: LayoutStretch.SIDE_BAR,
              layoutSide: LayoutSide.RIGHT,
            },
          ]} />

        <PropsRoute
          path={'/client-manager/view-offerings'}
          component={GenericPage}
          components={[
            {
              component: wrapHOC(ViewOfferings),
              layoutStretch: LayoutStretch.PRIMARY_FULL,
            },
          ]} />

        <PropsRoute
          path={'/client-manager/view-clients/:clientId/create-subscription'}
          component={canCreateSubscription ? GenericPage : <Redirect to="/client-manager/view-clients" />}
          components={[
            {
              component: wrapHOC(ViewOfferings),
              id: "create_subscription_view_offerings",
              layoutStretch: LayoutStretch.PRIMARY_ONE_THIRD,
              layoutSide: LayoutSide.LEFT,
            },
            {
              component: wrapHOC(CreateSubscription),
              id: "create_subscription_form",
              layoutStretch: LayoutStretch.PRIMARY_TWO_THIRD,
              layoutSide: LayoutSide.RIGHT,
              subscribedTo: ["create_subscription_view_offerings"],
            },
          ]}
          title={deck().create_subscription_title} />

      </switch>

    );
  }

  render() {
    const { canCreateSubscription } = this.props;
    return(
      <div>

        {ClientManager.mapRoutes(canCreateSubscription)}
      </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    router: state.router,
    canCreateSubscription: canCreateSubscription(state)
  }
}

ClientManager.propTypes = {
  router: PropTypes.object,
  canCreateSubscription: PropTypes.bool.isRequired
};

export default connect(
  mapStateToProps,
)(ClientManager);



// mapGenericDemo() {
//
//   return (
//     <div>
//       <PropsRoute path={'/client-manager/generic'} component={GenericPage} components={[
//         {
//           component: wrapHOC(TestComponent),
//           layoutStretch: LayoutStretch.PRIMARY_FULL,
//         },
//       ]} />
//
//       <PropsRoute path={'/client-manager/generic-2'} component={GenericPage} components={[
//         {
//           component: wrapHOC(TestComponent),
//           layoutStretch: LayoutStretch.PRIMARY_ONE_THIRD,
//           layoutSide: LayoutSide.LEFT,
//         },
//         {
//           component: wrapHOC(TestComponent),
//           layoutStretch: LayoutStretch.PRIMARY_TWO_THIRD,
//           layoutSide: LayoutSide.RIGHT,
//         },
//       ]} />
//
//       <PropsRoute path={'/client-manager/generic-3'} component={GenericPage} components={[
//         {
//           component: wrapHOC(TestComponent),
//           layoutStretch: LayoutStretch.PRIMARY_ONE_THIRD,
//           layoutSide: LayoutSide.RIGHT,
//         },
//         {
//           component: wrapHOC(TestComponent),
//           layoutStretch: LayoutStretch.PRIMARY_TWO_THIRD,
//           layoutSide: LayoutSide.LEFT,
//         },
//       ]} />
//
//       <PropsRoute path={'/client-manager/generic-4'} component={GenericPage} components={[
//         {
//           component: wrapHOC(TestComponent),
//           layoutStretch: LayoutStretch.PRIMARY_HALF,
//           layoutSide: LayoutSide.RIGHT,
//         },
//         {
//           component: wrapHOC(TestComponent),
//           layoutStretch: LayoutStretch.PRIMARY_HALF,
//           layoutSide: LayoutSide.LEFT,
//         },
//       ]} />
//
//       <PropsRoute path={'/client-manager/generic-5'} component={GenericPage} components={[
//         {
//           component: wrapHOC(TestComponent),
//           layoutStretch: LayoutStretch.PRIMARY_FULL,
//         },
//         {
//           component: wrapHOC(TestComponent),
//           layoutStretch: LayoutStretch.SIDE_BAR,
//           layoutSide: LayoutSide.LEFT,
//         },
//       ]} />
//
//       <PropsRoute path={'/client-manager/generic-6'} component={GenericPage} components={[
//         {
//           component: wrapHOC(TestComponent),
//           layoutStretch: LayoutStretch.PRIMARY_FULL,
//         },
//         {
//           component: wrapHOC(TestComponent),
//           layoutStretch: LayoutStretch.SIDE_BAR,
//           layoutSide: LayoutSide.LEFT,
//         },
//         {
//           component: wrapHOC(TestComponent),
//           layoutStretch: LayoutStretch.SIDE_BAR,
//           layoutSide: LayoutSide.RIGHT,
//         },
//       ]} />
//
//       <PropsRoute path={'/client-manager/generic-7'} component={GenericPage} components={[
//         {
//           component: wrapHOC(TestComponent),
//           layoutStretch: LayoutStretch.PRIMARY_FULL,
//         },
//         {
//           component: wrapHOC(TestComponent),
//           layoutStretch: LayoutStretch.SIDE_BAR,
//           layoutSide: LayoutSide.RIGHT,
//         },
//       ]} />
//
//       <PropsRoute path={'/client-manager/generic-8'} component={GenericPage} components={[
//         {
//           component: wrapHOC(TestComponent),
//           layoutStretch: LayoutStretch.PRIMARY_ONE_THIRD,
//           layoutSide: LayoutSide.RIGHT,
//         },
//         {
//           component: wrapHOC(TestComponent),
//           layoutStretch: LayoutStretch.PRIMARY_TWO_THIRD,
//           layoutSide: LayoutSide.LEFT,
//         },
//         {
//           component: wrapHOC(TestComponent),
//           layoutStretch: LayoutStretch.SIDE_BAR,
//           layoutSide: LayoutSide.RIGHT,
//         },
//       ]} />
//
//       <PropsRoute path={'/client-manager/generic-9'} component={GenericPage} components={[
//         {
//           component: wrapHOC(TestComponent),
//           layoutStretch: LayoutStretch.PRIMARY_ONE_THIRD,
//           layoutSide: LayoutSide.RIGHT,
//         },
//         {
//           component: wrapHOC(TestComponent),
//           layoutStretch: LayoutStretch.PRIMARY_TWO_THIRD,
//           layoutSide: LayoutSide.LEFT,
//         },
//         {
//           component: wrapHOC(TestComponent),
//           layoutStretch: LayoutStretch.SIDE_BAR,
//           layoutSide: LayoutSide.LEFT,
//         },
//         {
//           component: wrapHOC(TestComponent),
//           layoutStretch: LayoutStretch.SIDE_BAR,
//           layoutSide: LayoutSide.RIGHT,
//         },
//       ]} />
//     </div>
//   );
// }
