import React from "react";
import PropTypes from "prop-types";
import CreateSubscription from "../../views/CreateSubscription/CreateSubscription";
import {deck} from "../../../../assets/copy/CopyDeck";
import { toast } from 'react-toastify';

// Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {LayoutStretch} from "../../../../utils/UIUtils";
import styled from "styled-components";
import TitleElement, {TITLE_ELEMENT_TYPE} from "../../../../components/Elements/TitleElement";
import color_palette from "../../../../assets/color/style-constants";

const MainContainer = styled.div`

  padding-right: 8px !important;
  padding-left: 8px !important;

`;

const CardContainer = styled.div`
  margin-bottom: 20px;
  padding: 10px;

  background-color: ${color_palette.card_background_color};
  box-shadow: -2px 2px 4px 0 ${color_palette.default_box_shadow};
  border-radius: 6px;

  ${props => (
    props.selected ?
      'border: solid 4px ' + color_palette.default_border + ';' :
      'border: solid 4px transparent;'
  )};
`;

const ClientDetailsRow = styled.div`

  border-radius: 6px;

  margin-right: 15px !important;
  margin-left: 15px !important;

  margin-bottom: 10px;

  border: solid 1px #e9eff4;

`;

const ClientDetailSingleItem = styled.div`
  padding-left: 5px !important;
  padding-right: 5px !important;

  ${props => (props.leftBorder ?  "border-left: solid 1px #e9eff4;" : "") }
`;

const ClientDetailSingleItemTitle = styled.p`

  font-size: 11px;
  letter-spacing: 0.4px;
  color: ${color_palette.dark_text};

  margin-top: 8px;
  margin-bottom: 0px;
`;

const ClientDetailSingleItemValue = styled.p`

  font-size: 20px;
  letter-spacing: normal;
  color: ${color_palette.support_highlighted_text};

  margin-bottom: 8px;
`;

class ClientCard extends React.Component {

  constructor() {
    super();

    this.state = {
      modalIsOpen: false,
      toCreateSubscription: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openCreateSubscriptionPage = this.openCreateSubscriptionPage.bind(this);
  }

  openModal() {
    const { cardSelected, client, selected } = this.props;
    if (!selected) {
      cardSelected(client);
    }
  }

  openCreateSubscriptionPage() {
    this.setState({toCreateSubscription: true});
  }

  closeModal(success) {
    this.setState({modalIsOpen: false});

    if (success === true) {

      toast.success(deck().client_card_created_subscription, {
        position: toast.POSITION.BOTTOM_CENTER
      });

      this.props.forceRefresh();
    }
  }

  renderMaterialModal() {

    return (
      <Dialog
        open={this.state.modalIsOpen}
        onClose={this.closeModal}
        maxWidth="sm"
        fullWidth={true}>

        <DialogContent>
          <CreateSubscription client={this.props.client} success={this.closeModal} />
        </DialogContent>

      </Dialog>

    );
  }

  render() {

    var colSize = "col-12 col-sm-6 col-md-4";

    switch (this.props.layoutStretch) {

      case LayoutStretch.PRIMARY_FULL: {
        colSize = "col-12 col-sm-6 col-md-4";
        break;
      }

      case LayoutStretch.PRIMARY_HALF: {
        colSize = "col-12";
        break;
      }

      case LayoutStretch.PRIMARY_ONE_THIRD: {
        colSize = "col-12";
        break;
      }

      case LayoutStretch.PRIMARY_TWO_THIRD: {
        colSize = "col-12";
        break;
      }
    }

    let id = "";
    if (this.props.client.clientId !== null) {
      id = this.props.client.clientId;
    }

    let clientCode = "";
    if (this.props.client.clientCode !== null) {
      clientCode = this.props.client.clientCode;
    }

    let subscriptions = "0";
    if (this.props.client.subscriptions !== null) {
      subscriptions = this.props.client.subscriptions.length;
    }

    return (

      <MainContainer className={colSize}>
        <CardContainer
          onClick={e => {
            e.stopPropagation();
            this.openModal()
          }}
          selected={this.props.selected}
        >

          <TitleElement
            name={this.props.client.name}
            supportName={clientCode}
            type={TITLE_ELEMENT_TYPE.CLIENT}/>

          <ClientDetailsRow className="row">
            <ClientDetailSingleItem className="col-6 text-center">
              <ClientDetailSingleItemTitle>{deck().client_card_client_id}</ClientDetailSingleItemTitle>
              <ClientDetailSingleItemValue>{id}</ClientDetailSingleItemValue>
            </ClientDetailSingleItem>

            <ClientDetailSingleItem className="col-6 text-center" leftBorder={true}>
              <ClientDetailSingleItemTitle>{deck().client_card_subscriptions}</ClientDetailSingleItemTitle>
              <ClientDetailSingleItemValue>{subscriptions}</ClientDetailSingleItemValue>
            </ClientDetailSingleItem>
          </ClientDetailsRow>

        </CardContainer>

        {this.renderMaterialModal()}
      </MainContainer>

    );
  }
}

ClientCard.propTypes = {
  client: PropTypes.shape({
    name: PropTypes.string,
    version: PropTypes.number,
    id: PropTypes.number,
    clientId: PropTypes.string,
    clientCode: PropTypes.string,
    subscriptions: PropTypes.array,
  }).isRequired,
  forceRefresh: PropTypes.func,
  layoutStretch: PropTypes.number,
  cardSelected: PropTypes.func,
  selected: PropTypes.bool,
};

export default ClientCard;
