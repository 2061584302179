import React from "react";
import PropTypes from "prop-types";

const EditIcon = ({ fill, height, width, ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 140 148" {...rest}>
    <g fill={fill} fillRule="nonzero">
      <path d="M114.545 91.895c0-3.716 2.85-6.728 6.364-6.728 3.515 0 6.364 3.012 6.364 6.728v35.923c0 11.146-8.548 20.182-19.091 20.182H19.09C8.547 148 0 138.964 0 127.818V33.636C0 22.49 8.547 13.455 19.09 13.455h33.983c3.514 0 6.363 3.011 6.363 6.727 0 3.715-2.849 6.727-6.363 6.727H19.09c-3.515 0-6.364 3.012-6.364 6.727v94.182c0 3.716 2.85 6.727 6.364 6.727h89.09c3.515 0 6.364-3.011 6.364-6.727V91.895z" />
      <path d="M50.91 76.787v17.395h16.454l57.273-60.546-16.455-17.395-57.273 60.546zM112.681 1.97l25.454 26.91c2.485 2.627 2.485 6.886 0 9.513L74.5 105.666c-1.194 1.262-2.812 1.97-4.5 1.97H44.545c-3.514 0-6.363-3.012-6.363-6.727V74c0-1.784.67-3.495 1.864-4.757L103.682 1.97c2.485-2.627 6.514-2.627 9 0z" />
    </g>
  </svg>
);

EditIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
};

EditIcon.defaultProps = {
  fill: "#000",
  height: "148",
  width: "148"
};

export default EditIcon;
