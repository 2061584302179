import React from 'react';
import PropTypes from "prop-types";
import {deck} from "../../../../assets/copy/CopyDeck";
import SectionTitleElement from "../../../ClientManager/components/Elements/SectionTitleElement";
import ListHeader from "../../../ClientManager/components/ListHeader/ListHeader";
import connect from "react-redux/es/connect/connect";
import DropCard from "../../components/Cards/DropCard";
import {getEnvironments, getPipelineDrops} from "../../actions/pipelineActions";
import {environmentFromPipelineId, pipelineFromId} from "../../controllers/PipelineController";
import {withRouter} from "react-router-dom";
import {GET_ENVIRONMENTS, GET_PIPELINE_DROPS} from "../../actions/actionTypes";
import LoadingElement from "../../../../components/Loading/LoadingElement";
import ErrorElement from "../../../../components/Error/ErrorElement";

class ViewDrops extends React.Component {

  constructor(props) {
    super(props);

    this.viewDropDetails = this.viewDropDetails.bind(this);
  }

  componentDidMount() {

    if (!this.props.environments) {
      this.props.dispatch(getEnvironments());
    }

    if (this.props.environments &&
      !this.props.currentPipelineDrops) {

      this.refreshData();
    }
  }

  componentDidUpdate() {

    if (this.props.environments &&
      !this.props.loading.includes(GET_PIPELINE_DROPS) &&
      !this.props.currentPipelineDrops) {

      this.refreshData();
    }
  }

  refreshData() {

    if (!this.props.environments || !window.location.pathname.split("/")[3]) {
      return;
    }

    let pipelineId = window.location.pathname.split("/")[3];

    let values = {
      environmentId: environmentFromPipelineId(this.props.environments, pipelineId).id,
      pipelineId: pipelineId
    };

    this.props.dispatch(getPipelineDrops(values));
  }

  routeDetails() {

    var splitString = this.props.router.location.pathname.split('/');

    let pipelineId = splitString[3];

    return ({
      pipelineId: pipelineId,
    });
  }

  getPipeline() {
    const { environments } = this.props;
    const { pipelineId } = this.routeDetails();

    return pipelineFromId(environments, pipelineId)
  }

  viewDropDetails(dropId) {

    let pipeline = this.getPipeline();

    let route = "/pipeline-manager/pipelines/" + pipeline.id + "/drops/" + dropId;

    this.props.history.push(route);
  }

  renderDrops(drops) {

    if (!drops) {
      return
    }

    let dropsItems = [];

    for (var key in this.props.currentPipelineDrops) {

      dropsItems.push(
        <DropCard
          key={"drop_" + key}
          cardSelected={this.viewDropDetails}
          drop={this.props.currentPipelineDrops[key]}/>
      );
    }

    return dropsItems;
  }

  render() {

    if (this.props.loading.includes(GET_ENVIRONMENTS) ||
      this.props.loading.includes(GET_PIPELINE_DROPS)) {

      return (<LoadingElement/>);

    } else if (this.props.errors.some(error => error.type === GET_ENVIRONMENTS) ||
      this.props.errors.some(error => error.type === GET_PIPELINE_DROPS)) {

      let failedCallElements = [];

      this.props.errors.map(error => {
        failedCallElements.push(<ErrorElement errors={error.errors} type={error.type}/>);
      });

      return failedCallElements;
    }


    let pipeline = this.getPipeline();

    let title;
    if (pipeline &&
      pipeline.subscription &&
      pipeline.subscription.offering &&
      pipeline.subscription.offering.name) {

      title = pipeline.subscription.offering.name + deck().view_drops_list_title;
    }

    return (
      <div className="container">

        <ListHeader title={title}/>

        <div className="row">
          <div className="col">
            <SectionTitleElement title={deck().view_drops_list_previous_label}/>

          </div>
        </div>

        <div className="row">

          {this.renderDrops(this.props.currentPipelineDrops)}

        </div>
      </div>
    );
  }
}

ViewDrops.propTypes = {
  loading: PropTypes.object,
  errors: PropTypes.object,
  dispatch: PropTypes.func,
  router: PropTypes.object,
  history: PropTypes.object,

  currentPipelineDrops: PropTypes.object,
  environments: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    loading: state.common.loading,
    errors: state.common.errors,
    router: state.router,
    environments: state.PipelineManager.pipeline.environments,
    currentPipelineDrops: state.PipelineManager.pipeline.currentPipelineDrops,
  };
}

export default connect(
  mapStateToProps,
)(withRouter(ViewDrops));
