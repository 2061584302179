import React from 'react';
import PropTypes from "prop-types";
import {LayoutSide, LayoutStretch} from "../../utils/UIUtils";
import ListHeader from "../../Modules/ClientManager/components/ListHeader/ListHeader";
import styled from "styled-components";
import color_palette from "../../assets/color/style-constants";

export const SIDEBAR_ANIMATION_LENGTH = 500;

const TopContainer = styled.div`
  padding: 30px;
  margin: 30px;
  min-height: calc(100vh - 148px);
`;

const Container = styled.div`

  ${props => (
  props.white ?
    'background-color: ' + color_palette.white_text + ";" +
    'border-radius: 4px;' +
    'box-shadow: -2px 2px 4px 0 ' + color_palette.default_box_shadow + ';' :
    ''
  )};
`;


const SideBarContainer = styled.div.attrs({
  onClick: e => e.stopPropagation()
})`
  background-color: ${color_palette.white_text};

  margin-top: -60px;
  margin-bottom: -92px;
  padding-top: 70px;
  transform: translate(${({ isItemSelected }) => isItemSelected ? '0%' : '100%'}, 0);
  transition-property: transform;
  transition-duration: ${SIDEBAR_ANIMATION_LENGTH}ms;
  transition-timing: ease-in-out;
`;

const StickySideBar = styled.div`
    position: sticky;
    top: 60px;
`

class GenericPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedItem: null,
      selectedItems: [],
    };

    this.selectedItemAction = this.selectedItemAction.bind(this);
  }

  renderPrimaryColumn(layoutStretch, component) {

    var colStyle = "";
    let containerBackgroundWhite = true;
    switch (layoutStretch) {

      case LayoutStretch.PRIMARY_ONE_THIRD: {
        colStyle = "col-12 col-sm-6 col-md-4";
        containerBackgroundWhite = false;
        break;
      }

      case LayoutStretch.PRIMARY_TWO_THIRD: {
        colStyle = "col-12 col-sm-6 col-md-8";
        containerBackgroundWhite = false;
        break;
      }

      case LayoutStretch.PRIMARY_HALF: {
        colStyle = "col-12 col-sm-6 col-md-6";
        containerBackgroundWhite = false;
        break;
      }

    }

    return (
      <div className={colStyle}>
        <Container white={containerBackgroundWhite}>
          {
            this.setupInnerComponent(
              component,
              layoutStretch)
          }
        </Container>
      </div>
    );

  }

  setupInnerComponent(component, layoutStretch) {

    let subscriptions = [];

    if (component.subscribedTo) {

      component.subscribedTo.map(subscription => {

        this.state.selectedItems.map(selectedItem => {

          if (subscription === selectedItem.containerId) {
            // this is now the one you should subscribe to
            subscriptions.push(selectedItem);
          }

        });

      });
    }



    return component.component({
      layoutStretch: layoutStretch,
      selectedItemAction: this.selectedItemAction,
      currentSelectedItem: this.state.selectedItem,
      containerId: component.id,
      subscriptions: subscriptions,
    });
  }

  selectedItemAction(item, containerId) {

    let selectedItems = this.state.selectedItems;

    let contains = false;
    selectedItems = selectedItems.map(selection => {

      if (selection.containerId === containerId) {

        selection.item = item;
        contains = true;
      }

      return selection;

    });

    if (!contains) {
      selectedItems.push({
        item: item,
        containerId: containerId,
      })
    }


    this.setState({
      selectedItem: item,
      selectedItems: selectedItems,
    });
  }

  renderPrimaryContent(primaryContent, leftPrimaryContent, rightPrimaryContent, title) {

    // TODO: grab the selected values for each of the content values

    if (primaryContent) {

      return this.setupInnerComponent(
        primaryContent,
        LayoutStretch.PRIMARY_FULL);

    } else if (leftPrimaryContent && rightPrimaryContent) {

      return (

        <div>

          <ListHeader title={title} />

          <div className="row">

            {leftPrimaryContent}

            {rightPrimaryContent}

          </div>

        </div>
      );

    }

  }

  sideBarSplit(leftComponent, rightComponent, primaryContent, leftPrimaryContent, rightPrimaryContent) {

    var leftOutput;
    var rightOutput;
    var mainOutput;

    let emptySideBar = (<div className="col-lg-1 d-none d-lg-block"/>);

    if (rightComponent && !leftComponent) {

      leftOutput = emptySideBar;

      rightOutput = (
        <SideBarContainer isItemSelected={!!this.state.selectedItem} className="col-lg-3 d-none d-lg-block">
          <StickySideBar>
            {this.setupInnerComponent(
              rightComponent,
              LayoutStretch.SIDE_BAR)}
          </StickySideBar>
        </SideBarContainer>
      );

    } else if (leftComponent && !rightComponent) {

      leftOutput = (
        <SideBarContainer className="col-lg-3 d-none d-lg-block">
          <StickySideBar>
            {this.setupInnerComponent(
              leftComponent,
              LayoutStretch.SIDE_BAR)}
          </StickySideBar>
        </SideBarContainer>
      );

      rightOutput = emptySideBar;

    } else if (leftComponent && rightComponent) {


      leftOutput = (
        <SideBarContainer className="col-lg-2 d-none d-lg-block">
          <StickySideBar>
            {this.setupInnerComponent(
              leftComponent,
              LayoutStretch.SIDE_BAR)}
          </StickySideBar>
        </SideBarContainer>
      );

      rightOutput = (
        <SideBarContainer className="col-lg-2 d-none d-lg-block">
          <StickySideBar>
            {this.setupInnerComponent(
              rightComponent,
              LayoutStretch.SIDE_BAR)}
          </StickySideBar>
        </SideBarContainer>
      );

    }

    mainOutput = (
      <div className="col-12 col-lg-8">
        {this.renderPrimaryContent(primaryContent, leftPrimaryContent, rightPrimaryContent, this.props.title)}
      </div>
    );

    return {
      left: leftOutput,
      right: rightOutput,
      main: mainOutput,
    };

  }

  renderComponents(components) {

    if(!components) {
      return
    }

    var leftSideBar;
    var rightSideBar;

    var primaryContent;
    var leftPrimaryContent;
    var rightPrimaryContent;

    components.forEach(component => {

      switch (component.layoutStretch) {

        case LayoutStretch.PRIMARY_FULL: {
          primaryContent = component;
          break;
        }

        case LayoutStretch.PRIMARY_ONE_THIRD: {

          if (component.layoutSide === LayoutSide.LEFT) {
            leftPrimaryContent = this.renderPrimaryColumn(LayoutStretch.PRIMARY_ONE_THIRD, component);
          } else {

            rightPrimaryContent = this.renderPrimaryColumn(LayoutStretch.PRIMARY_ONE_THIRD, component);
          }

          break;

        }

        case LayoutStretch.PRIMARY_TWO_THIRD: {

          if (component.layoutSide === LayoutSide.LEFT) {
            leftPrimaryContent = this.renderPrimaryColumn(LayoutStretch.PRIMARY_TWO_THIRD, component);
          } else {
            rightPrimaryContent = this.renderPrimaryColumn(LayoutStretch.PRIMARY_TWO_THIRD, component);
          }

          break;

        }

        case LayoutStretch.PRIMARY_HALF: {

          if (component.layoutSide === LayoutSide.LEFT) {
            leftPrimaryContent = this.renderPrimaryColumn(LayoutStretch.PRIMARY_HALF, component);
          } else {
            rightPrimaryContent = this.renderPrimaryColumn(LayoutStretch.PRIMARY_HALF, component);
          }

          break;
        }

        case LayoutStretch.SIDE_BAR: {

          if (component.layoutSide === LayoutSide.LEFT) {
            leftSideBar = component;
          } else {
            rightSideBar = component;
          }

          break;

        }
      }

    });

    var sideBarSplit = this.sideBarSplit(leftSideBar, rightSideBar, primaryContent, leftPrimaryContent, rightPrimaryContent);

    return (

      <div className="row justify-content-lg-center">
        {sideBarSplit.left}

        {sideBarSplit.main}

        {sideBarSplit.right}
      </div>

    );
  }

  render() {
    const { selectedItem } = this.state

    return (
      <div onClick={() => selectedItem && this.setState({ selectedItem: null })}>
        <TopContainer className="container-fluid">

          {this.renderComponents(this.props.components)}

        </TopContainer>
      </div>
    );
  }
}

GenericPage.propTypes = {
  components: PropTypes.arrayOf(PropTypes.shape({
    component: PropTypes.object.isRequired,
    layoutStretch: PropTypes.number.isRequired,
    layoutSide: PropTypes.number,
    id: PropTypes.string,
    subscribedTo: PropTypes.string,
  })).isRequired,
  title: PropTypes.string,
};

export default GenericPage;
