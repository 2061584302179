import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {deck} from "../../../../assets/copy/CopyDeck";
import {CSVLink} from "react-csv";
import color_palette from "../../../../assets/color/style-constants";

const CardContainer = styled.div`
  padding-top: 15px;
  padding-bottom: 5px;

  border-radius: 6px;
  border: solid 1px #e9eff4;
  margin-bottom: 20px;

  background-color: ${color_palette.card_background_color};

  box-shadow: -2px 2px 4px 0 ${color_palette.default_box_shadow};
`;

const MainRow = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;

  padding-left: 20px;
  padding-right: 20px;
`;

const TitleLabel = styled.p`
  font-size: 17px;
  color: ${color_palette.dark_text};
`;

const ActionButton = styled.button`
  border-radius: 2px;
  background-color: ${props => props.backgroundColor || color_palette.support_highlighted_text};

  width: 60px;
  height: 30px;

  font-size: 13px;
  text-align: center;
  color: ${color_palette.white_text};
`;

const SupportLabel = styled.p`
  opacity: 0.7;
  font-size: 13px;
  color: ${color_palette.light_header};
`;

const DownloadButtonContainer = styled.div`
  .downloadButton {

    opacity: 0.7;
    font-size: 13px;
  }
`;

class FileSpecificationCard extends React.Component {

  constructor(props) {
    super(props);

    this.viewFile = this.viewFile.bind(this);
  }

  viewFile() {
    this.props.cardSelected(this.props.specification);
  }


  // Disable ES-Lint for the Download button section
  /* eslint-disable */
  renderDownloadButton() {

    if (!this.props.specification &&
      !this.props.specification.fileContentSchema &&
      !this.props.specification.fileContentSchema.properties) {

      return;
    }

    let headers = [
      { label: deck().spec_version_download_field_name, key: "fieldName" },
      { label: deck().spec_version_download_description, key: "description" },
      { label: deck().spec_version_download_type, key: "type" },
      { label: deck().spec_version_download_enum, key: "enum" },
      { label: deck().spec_version_download_format, key: "format" },
      { label: deck().spec_version_download_minimum, key: "minimum" },
      { label: deck().spec_version_download_maximum, key: "maximum" },
      { label: deck().spec_version_download_pattern, key: "pattern" },
      { label: deck().spec_version_download_minLength, key: "minLength" },
      { label: deck().spec_version_download_maxLength, key: "maxLength" },
    ];

    let properties = this.props.specification.fileContentSchema.properties;

    let data = [];

    for (var key in properties) {

      if (Object.prototype.hasOwnProperty.call(properties, key)) { // properties.hasOwnProperty(key)) {

        let type = "";
        if (properties[key].type) {
          type = properties[key].type;
        }

        let description = "";
        if (properties[key].description) {
          description = properties[key].description;
        }

        let enumValue = "";
        if (properties[key].enum) {
          enumValue = properties[key].enum;
        }

        let format = "";
        if (properties[key].format) {
          format = properties[key].format;
        }

        let minimum = "";
        if (properties[key].minimum !== null) {
          minimum = properties[key].minimum;
        }

        let maximum = "";
        if (properties[key].maximum !== null) {
          maximum = properties[key].maximum;
        }

        let pattern = "";
        if (properties[key].pattern !== null) {
          pattern = properties[key].pattern;
        }

        let minLength = "";
        if (properties[key].minLength !== null) {
          minLength = properties[key].minLength;
        }

        let maxLength = "";
        if (properties[key].maxLength !== null) {
          maxLength = properties[key].maxLength;
        }

        data.push({
          fieldName: key,
          type: type,
          description: description,
          enum: enumValue,
          format: format,
          minimum: minimum,
          maximum: maximum,
          pattern: pattern,
          minLength: minLength,
          maxLength: maxLength,
        });
      }
    }

    return (
      <DownloadButtonContainer>
        <CSVLink
          className="downloadButton"
          data={data}
          headers={headers}
          filename={this.props.specification.name + ".csv"}>{deck().spec_version_download_title}</CSVLink>
      </DownloadButtonContainer>
    );

  }
  /* eslint-enable */

  render() {

    // NOTE: for some reason the triple nested PropTypes are not working correctly for here, so just turning off the rule for this case
    let expression = this.props.specification.fileNamePattern.expression; // eslint-disable-line react/prop-types

    return (
      <CardContainer className="col-12">

        <MainRow className="row">
          <div className="col-8">

            <div className="row">
              <div className="col-12">
                <TitleLabel>{this.props.specification.name}</TitleLabel>
              </div>

            </div>

            <div className="row">
              <div className="col-12">
                <ActionButton onClick={this.viewFile} backgroundColor={'#4da1ff'}>
                  {deck().spec_version_view_action_button}
                </ActionButton>
              </div>

            </div>
          </div>

          <div className="col-4">
            <SupportLabel>{deck().spec_version_file_type}{this.props.specification.fileType}</SupportLabel>
            <SupportLabel>{deck().spec_version_file_name_pattern}{expression}</SupportLabel>

            {this.renderDownloadButton()}
          </div>

        </MainRow>
      </CardContainer>
    );
  }
}

FileSpecificationCard.propTypes = {
  specification: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    fileType: PropTypes.string,
    fileNamePattern: PropTypes.object,
    fileContentSchema: PropTypes.object,
  })),
  cardSelected: PropTypes.func,
  selected: PropTypes.bool
};

export default FileSpecificationCard;
