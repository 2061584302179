import React from 'react';
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getEnvironments, getPipelineDrops} from "../../actions/pipelineActions";
import ViewDrop from "./ViewDrop";
import {LayoutStretch} from "../../../../utils/UIUtils";
import {GET_ENVIRONMENTS, GET_PIPELINE_DROPS} from "../../actions/actionTypes";
import LoadingElement from "../ViewDrops/ViewDrops";
import ErrorElement from "../../../../components/Error/ErrorElement";
import ListHeader from "../../../ClientManager/components/ListHeader/ListHeader";
import {environmentFromPipelineId, pipelineFromId} from "../../controllers/PipelineController";

class ViewDropPage extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {

    if (!this.props.environments) {
      this.props.dispatch(getEnvironments());
    }

    if (this.props.environments &&
      !this.props.currentPipelineDrops) {

      this.refreshData();
    }
  }

  componentDidUpdate() {

    if (this.props.environments &&
      !this.props.loading.includes(GET_PIPELINE_DROPS) &&
      !this.props.errors.some(error => error.type === GET_PIPELINE_DROPS) &&
      !this.props.currentPipelineDrops) {

      this.refreshData();
    }
  }

  refreshData() {

    if (!this.props.environments || !window.location.pathname.split("/")[3]) {
      return;
    }

    let pipelineId = window.location.pathname.split("/")[3];

    let values = {
      environmentId: environmentFromPipelineId(this.props.environments, pipelineId).id,
      pipelineId: pipelineId
    };

    this.props.dispatch(getPipelineDrops(values));
  }

  routeDetails() {

    var splitString = this.props.router.location.pathname.split('/');

    let pipelineId = splitString[3];
    let dropId = splitString[5];

    return ({
      pipelineId: pipelineId,
      dropId: dropId,
    });
  }

  render() {

    if (this.props.loading.includes(GET_ENVIRONMENTS) ||
      this.props.loading.includes(GET_PIPELINE_DROPS)) {

      return (<LoadingElement/>);

    } else if (this.props.errors.some(error => error.type === GET_ENVIRONMENTS) ||
      this.props.errors.some(error => error.type === GET_PIPELINE_DROPS)) {

      let failedCallElements = [];

      this.props.errors.map(error => {
        failedCallElements.push(<ErrorElement errors={error.errors} type={error.type}/>);
      });

      return failedCallElements;
    }

    let route = this.routeDetails();

    let dropToPass;
    if (this.props.currentPipelineDrops) {

      for (var key in this.props.currentPipelineDrops) {

        if (key === route.dropId) {
          dropToPass = Object.assign({}, this.props.currentPipelineDrops[key]);
        }
      }
    }

    let dropItem;
    if (dropToPass) {

      dropItem = (
        <ViewDrop
          layoutStretch={LayoutStretch.PRIMARY_FULL}
          pipelineDrop={dropToPass}
          pipelineId={route.pipelineId}
          viewAllDropsSelected={() =>
            this.props.history.push(`/pipeline-manager/pipelines/${route.pipelineId}/drops`)
          }
        />
      )
    }

    let pipeline = pipelineFromId(this.props.environments, route.pipelineId);

    let header;
    if (pipeline &&
      pipeline.subscription &&
      pipeline.subscription.offering &&
      pipeline.subscription.offering.name) {

      header = (
        <ListHeader title={pipeline.subscription.offering.name + " - " + route.dropId + " drop"}/>
        );
    }

    return (
      <div>

        {header}
        {dropItem}

      </div>
    );
  }
}

ViewDropPage.propTypes = {
  loading: PropTypes.object,
  errors: PropTypes.object,
  dispatch: PropTypes.func,
  router: PropTypes.object,
  history: PropTypes.object,

  currentPipelineDrops: PropTypes.object,
  environments: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    loading: state.common.loading,
    errors: state.common.errors,
    router: state.router,

    environments: state.PipelineManager.pipeline.environments,
    currentPipelineDrops: state.PipelineManager.pipeline.currentPipelineDrops,
  };
}

export default connect(
  mapStateToProps,
)(withRouter(ViewDropPage));
