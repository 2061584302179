import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import color_palette from "../../assets/color/style-constants";

const DownloadButton = styled.button`
  background-color: ${color_palette.blue_action_button};
  border-radius: 4px;
  color: ${color_palette.white_text};
  cursor: pointer;
  font-size: 16px;
  left: 0;
  line-height: 1.5;
  padding: 8px 16px;
  position: sticky;
  text-align: center;
  width: auto;
  :visited {
    color: ${color_palette.white_text};
  }
`;

const downloadCSV = (csv, filename) => {
  var csvFile;
  var downloadLink;
  csvFile = new Blob([csv], { type: "text/csv" });

  downloadLink = document.createElement("a");

  downloadLink.download = filename;

  downloadLink.href = window.URL.createObjectURL(csvFile);

  downloadLink.style.display = "none";

  document.body.appendChild(downloadLink);

  downloadLink.click();
};

const exportTableToCSV = filename => {
  var csv = [];
  var rows = document.querySelectorAll("table tr");

  for (var i = 0; i < rows.length; i++) {
    var row = [],
      cols = rows[i].querySelectorAll("td, th");
    const newCols = Object.values(cols).map(col => {
      if (col.tagName === "TH") {
        return col.firstChild.firstChild.data;
      }
      return col.innerText;
    });

    for (var j = 0; j < newCols.length; j++) row.push(newCols[j]);

    csv.push(row.join(","));
  }

  downloadCSV(csv.join("\n"), filename);
};

const CSVExport = ({ filename }) => {
  return (
    <DownloadButton onClick={() => exportTableToCSV(filename)}>
      Download CSV
    </DownloadButton>
  );
};

CSVExport.propTypes = {
  filename: PropTypes.string.isRequired
};

export default CSVExport;
