import React from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";
import {deck} from "../../../../assets/copy/CopyDeck";
import TitleElement, {TITLE_ELEMENT_TYPE} from "../../../../components/Elements/TitleElement";
import SectionTitleElement from "../../../ClientManager/components/Elements/SectionTitleElement";
import VersionCard from "../../components/Cards/VersionCard";
import {setupSpecVersionedObjects} from "../../controllers/PipelineController";
import styled from "styled-components";
import EmptyElement, {EMPTY_TYPE} from "../../../../components/Empty/EmptyElement";

const SectionTitleRow = styled.div`
  
  margin-top: 20px;
  
  padding-bottom: 10px;
  margin-bottom: 10px;
  
  padding-left: 15px;
`;

class ViewPricingSpecVersionSideBar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedSpecId: null,
    };

    this.selectedCard = this.selectedCard.bind(this);
  }

  componentDidUpdate() {

    if (this.props.subscriptions.length > 0) {

      this.props.pricingSpecs.map(spec => {

        if (spec.id === this.props.subscriptions[0].item &&
          (!this.state.selectedSpecId || this.state.selectedSpecId !== spec.id) ) {

          this.setState({
            selectedSpecId: spec.id
          });
        }

      });
    }
  }

  currentPricingSpec() {

    let versionedSpecs = setupSpecVersionedObjects(this.props.pricingSpecs);

    let currentSpec = null;
    versionedSpecs.map(dataSpec => {

      if (dataSpec.id === this.state.selectedSpecId) {
        currentSpec = dataSpec;
      }
    });

    return currentSpec;
  }

  selectedCard(version, id) {

    let route = "/pipeline-manager/view-pricing-config/" + id + "/" + version;

    this.props.history.push(route);
  }

  mapOutVersions(versions) {

    let specVersions = [];

    versions.map((item, index) => {

      specVersions.push(
        <VersionCard
          key={"spec-" + index}
          id={item.id}
          version={item.version}
          dataFileSpecifications={item.dataFileSpecifications}
          cardSelected={this.selectedCard} />
      )
    });

    return specVersions;

  }

  render() {

    if (!this.state.selectedSpecId) {

      return (<EmptyElement type={EMPTY_TYPE.SELECT}/>);
    }

    let pricingSpec = this.currentPricingSpec();

    return(
      <div className="container">

        <TitleElement
          name={pricingSpec.name}
          type={TITLE_ELEMENT_TYPE.SPEC}/>

        <SectionTitleRow className="row">
          <div className="col text-left">
            <SectionTitleElement title={deck().view_data_spec_sidebar_section_title}/>

          </div>
        </SectionTitleRow>


        <div className="row">

          {this.mapOutVersions(pricingSpec.versions)}

        </div>

      </div>
    );
  }
}

ViewPricingSpecVersionSideBar.propTypes = {
  pricingSpecs: PropTypes.object,
  subscriptions: PropTypes.array,
  history: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    pricingSpecs: state.PipelineManager.pipeline.pricingSpecs,
  }
}

export default connect(
  mapStateToProps,
)(withRouter(ViewPricingSpecVersionSideBar))
