import * as types from '../actions/actionTypes';
import UserGroups from '../utils/UserGroups';

export default function commonReducer(state = { loading: [], errors: [], groups: [] }, action) {

  const { loading, errors } = state;

  switch (action.type) {

    case types.ADD_LOADING_APIS: {

      let loadingArray = Object.assign([], loading);

      if (!loadingArray.includes(action.result)){
        loadingArray.push(action.result);
      }

      return {
        ...state,
        loading: loadingArray
      }
    }

    case types.REMOVE_LOADING_APIS: {

      let loadingArray = Object.assign([], loading);

      if (loadingArray.includes(action.result)){
        loadingArray = loadingArray.filter(loadingApi => loadingApi !== action.result)
      }

      return {
        ...state,
        loading: loadingArray
      }
    }

    /*
    Error Object:
      {
        type: GET_CLIENTS,
        errors: []
      }
     */
    case types.ADD_ERRORS_ON_APIS: {

      let errorsArray = Object.assign([], errors);

      // Remove the Previous error if it excited
      if (errorsArray.some(error => error.type === action.result.type)) {
        errorsArray = errorsArray.filter(error => error.type !== action.result.type);
      }

      // Add the new error
      errorsArray.push(action.result);

      return {
        ...state,
        errors: errorsArray,
      }
    }

    case types.REMOVE_ERRORS_ON_APIS: {

      let errorsArray = Object.assign([], errors);
      if (errorsArray.some(error => error.type === action.result)) {

        errorsArray = errorsArray.filter(error => error.type !== action.result);
      }

      return {
        ...state,
        errors: errorsArray,
      }

    }

    case types.ADD_USER_GROUPS: {
      return { ...state, groups: action.groups }
    }

    default:
      return { ...state };
  }
}

// SELECTORS
const isSputnikAdmin = state => state.groups.includes(UserGroups.ADMIN);
export const canRemoveSubscription = state => isSputnikAdmin(state) || state.groups.includes(UserGroups.SUBSCRIPTION_REMOVE);
export const canCreateSubscription = state => isSputnikAdmin(state) || state.groups.includes(UserGroups.SUBSCRIPTION_ADD);
export const canEditQuickSight = state => isSputnikAdmin(state) || state.groups.includes(UserGroups.QUICKSIGHT_EDIT);
export const canCreatePipeline = state => isSputnikAdmin(state) || state.groups.includes(UserGroups.PIPELINE_ADD);
export const canEditPipeline = state => isSputnikAdmin(state) || state.groups.includes(UserGroups.PIPELINE_EDIT);
export const canCreateDataSpec = state => isSputnikAdmin(state) || state.groups.includes(UserGroups.DATA_SPEC_ADD);
