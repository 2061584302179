import React from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {deck} from "../../../../assets/copy/CopyDeck";
import { Form } from 'react-final-form'
import {getClients, getOfferings, postSubscription} from "../../actions/clientActions";
import styled from "styled-components";
import {toast} from "react-toastify";
import DropDown from "../../../../components/FormComponents/DropDown";
import { withRouter } from "react-router-dom";
import TitleElement, {TITLE_ELEMENT_TYPE} from "../../../../components/Elements/TitleElement";
import {GET_CLIENTS, GET_OFFERINGS} from "../../actions/actionTypes";
import LoadingElement from "../../../../components/Loading/LoadingElement";
import ErrorElement from "../../../../components/Error/ErrorElement";
import EmptyElement, {EMPTY_TYPE} from "../../../../components/Empty/EmptyElement";
import color_palette from "../../../../assets/color/style-constants";

const TopContainer = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  
  background-color: ${color_palette.card_background_color};
  border-radius: 4px;
  box-shadow: -2px 2px 4px 0 ${color_palette.default_box_shadow};
`;

const SubmitContainer = styled.div`
  margin-top: 15px;
`;

const FormContainer = styled.div`

  margin: 15px;
`;

const DropDownContainer = styled.div`
  margin-bottom: 10px;
`;

const required = value => (value ? undefined : deck().create_subscription_required);

let resetForm;

class CreateSubscription extends React.Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onSuccess = this.onSuccess.bind(this);

    this.state = {
      selectedOffering: null,
    };

  }

  componentDidMount() {

    if (!this.props.offerings) {
      this.props.dispatch(getOfferings());

    }

    if (!this.props.clients) {
      this.props.dispatch(getClients());
    }

  }

  componentDidUpdate(prevProps) {

    if (this.props.subscriptions.length > 0) {

      this.props.offerings.map(offering => {

        // TODO: remove the dependence on the first subscription
        if (offering.id === this.props.subscriptions[0].item &&
          (!this.state.selectedOffering || this.state.selectedOffering.id !== offering.id) ) {

          // Set the offering
          this.setState({
            selectedOffering: offering
          });

          // Reset the form
          if (resetForm) {
            resetForm()
          }

          // Scroll to top of form
          window.scrollTo(0, 120)
        }

      });

    }


    if (this.props === prevProps) {
      return
    }

    if (this.props.postedSubscription) {

      if (!prevProps.postedSubscription ||
        prevProps.postedSubscription.product !== this.props.postedSubscription.product) {

        // This is a new Subscription
        this.props.success(true);

      } else {

        // Previous subscription -> do nothing

      }
    }
  }

  currentClient() {

    let currentClient;

    if (!this.props.clients) {
      return;
    }

    this.props.clients.map(client => {

      var splitString = this.props.router.location.pathname.split('/');
      let currentClientId = splitString[3] // TODO: this seems to be unstable; need to validate this with a unit test

      if (currentClientId === client.id) {
        currentClient = client;
      }

    });

    return currentClient;
  }

  static onErrors(errors) {

    let message = deck().general_api_error;

    // Grab the first Error and display it
    // TODO: Update to handle multiple errors
    if (errors &&
        errors.length > 0 &&
        errors[0] &&
        errors[0].errorMessage) {

      message = errors[0].errorMessage;
    }

    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER
    });

  }

  onSuccess() {

    toast.success(deck().create_subscription_success, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 2000,
      pauseOnFocusLoss: false,
      onClose: () => {

        let route = "/client-manager/view-clients";

        this.props.history.push({
          pathname: route,
          state: {
            newSubscription: true,
          }
        });
      }
    });
  }

  handleSubmit(values) {

    let passedValues = {
      clientId: this.currentClient().id,
      offeringId: this.state.selectedOffering.id,
      dropFrequency: values.dropFrequency,
      region: values.region,
    };

    this.props.dispatch(postSubscription(passedValues, this.onSuccess, CreateSubscription.onErrors));
  }


  renderRegionField() {

    if (!this.state.selectedOffering ||
      !this.state.selectedOffering.supportedRegions) {
      return
    }

    return (

      <DropDownContainer className="row">
        <div className="input-group mb-3">

          <DropDown
            name="region"
            placeholder="Select a region..."
            title={deck().create_subscription_region_title}
            titleTooltip={deck().create_subscription_region_title_tooltip}
            validation={required}
            dropDownOptions={this.state.selectedOffering.supportedRegions}/>

        </div>
      </DropDownContainer>


    );
  }

  renderDropFrequencyField() {

    if (!this.state.selectedOffering ||
      !this.state.selectedOffering.dropFrequencies ) {
      return
    }

    return(
      <DropDownContainer className="row">
        <div className="input-group mb-3">

          <DropDown
            name="dropFrequency"
            placeholder="Select a drop frequency..."
            title={deck().create_subscription_drop_frequency_title}
            validation={required}
            dropDownOptions={this.state.selectedOffering.dropFrequencies} />

        </div>
      </DropDownContainer>


    );
  }

  renderSubscriptionOptions() {

    if (this.state.selectedOffering) {

      return (
        <div>
          {this.renderRegionField()}
          {this.renderDropFrequencyField()}

        </div>
      );
    }
  }

  renderSimpleForm() {

    if (!this.state.selectedOffering) {

      return (<EmptyElement type={EMPTY_TYPE.SELECT}/>);
    }

    return(
      <FormContainer>
        <Form
          onSubmit={this.handleSubmit}>

          {({ form, handleSubmit, submitting }) => {
            resetForm = form.reset;

            return (
              <form onSubmit={handleSubmit}>

                {this.renderSubscriptionOptions()}

                <SubmitContainer className="btn-group justify-content-center d-flex" >
                  <button type="submit" className="btn btn-sm btn-outline-secondary" disabled={submitting}>
                    {deck().create_subscription_submit_action}
                  </button>
                </SubmitContainer>

              </form>
            )

          }}
        </Form>
      </FormContainer>
    );

  }

  render() {

    if (this.props.loading.includes(GET_CLIENTS) ||
      this.props.loading.includes(GET_OFFERINGS)) {

      return (<LoadingElement/>);

    } else if (this.props.errors.some(error => error.type === GET_CLIENTS) ||
      this.props.errors.some(error => error.type === GET_OFFERINGS)) {

      let failedCallElements = [];

      this.props.errors.map(error => {
        failedCallElements.push(<ErrorElement errors={error.errors} type={error.type}/>);
      });

      return failedCallElements;
    }

    let clientDetails;
    if (this.currentClient()) {
      clientDetails = (
        <TitleElement
          name={this.currentClient().name}
          supportName={this.currentClient().clientCode}
          type={TITLE_ELEMENT_TYPE.CLIENT}/>
      );
    }

    return (
      <div>

        <TopContainer className="container">

          <div className="row justify-content-between">
            {clientDetails}
          </div>

          {this.renderSimpleForm()}
        </TopContainer>

      </div>
    );
  }
}

CreateSubscription.propTypes = {
  client: PropTypes.object,
  success: PropTypes.func,
  classes: PropTypes.object,
  dispatch: PropTypes.func,
  BankManager: PropTypes.object,
  postedSubscription: PropTypes.object,
  enumerations: PropTypes.object,
  products: PropTypes.object,
  offerings: PropTypes.object,
  containerId: PropTypes.string,
  subscriptions: PropTypes.array,
  router: PropTypes.object,
  clients: PropTypes.array,
  history: PropTypes.object,
  loading: PropTypes.object,
  errors: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    loading: state.common.loading,
    errors: state.common.errors,
    bankReducer: state.BankManager.bankReducer,
    testBankReducer: state.BankManager.testBankReducer,
    postedSubscription: state.ClientManager.client.postedSubscription,
    enumerations: state.ClientManager.client.enumerations,
    products: state.ClientManager.client.products,
    offerings: state.ClientManager.client.offerings,
    clients: state.ClientManager.client.clients,
    router: state.router,
  };
}

export default connect(
  mapStateToProps,
)(withRouter(CreateSubscription));
