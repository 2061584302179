import LoadingCard from "../Cards/LoadingCard";
import React from "react";
import EmptyElement, {EMPTY_TYPE} from "../Empty/EmptyElement";
import ErrorElement from "../Error/ErrorElement";

export function validateLoadingAndErrors(apiCallType, items, errors, refreshData, loading, layoutStretch) {

  // States
  // 1. Error
  // 2. Loading
  // 3. Empty
  // 4. Normal

  if (errors.some(error => error.type === apiCallType)) {

    let failedCallElements = [];

    errors.map(error => {
      failedCallElements.push(<ErrorElement errors={error.errors} type={error.type}/>);
    });

    return ({valid: false, value: (failedCallElements) });
  }

  let loadingCards = [];

  if (loading.includes(apiCallType)) {

    loadingCards.push(
      <LoadingCard key={"loading-1"} layoutStretch={layoutStretch}/>
    );

    loadingCards.push(
      <LoadingCard key={"loading-2"} layoutStretch={layoutStretch}/>
    );

    return ({valid: false, value: (loadingCards)});
  }

  if (!items ||
    items.length < 1) {

    // TODO: Setup Common Empty State
    return ({valid: false, value: (
        <EmptyElement type={EMPTY_TYPE.NO_DATA}/>
      )});
  }

  return ({valid: true, value: null});
}
