import React from 'react';
import PropTypes from "prop-types";
import styled from "styled-components";
import color_palette from "../../../../assets/color/style-constants";

const Container = styled.div`  
  
  height: 20px;
  border-radius: 2px;
  
  background-color: ${props => props.isValid ? color_palette.success_item_background : color_palette.cancel_item_background};  
`;

const TitleLabel = styled.p`

  opacity: 0.75;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  color: ${props => props.isValid ? color_palette.success_item : color_palette.cancel_item};
`;

class FileStatusElement extends React.PureComponent {

  render() {

    return (
      <Container isValid={this.props.status}>

        <TitleLabel isValid={this.props.status}>{this.props.statusText ? this.props.statusText : this.props.status.toString()}</TitleLabel>
      </Container>
    );
  }
}

FileStatusElement.propTypes = {
  status: PropTypes.bool.isRequired,
  statusText: PropTypes.string,
};

export default FileStatusElement;
