import styled from "styled-components";
import color_palette from "../../assets/color/style-constants";

export default styled.input`
  background: transparent;
  color: ${color_palette.white_text};
  font-size: 15px;
  line-height: 1.5;
  max-width: 150px;
  padding: 4px;
  &::-webkit-input-placeholder {
    color: ${color_palette.white_text};
    font-size: 15px;
  }
  border: 1px solid ${color_palette.white_text};
  border-radius: 4px;
  flex: 1 1 auto;
`;
