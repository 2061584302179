import * as types from './actionTypes.js';
import {pipelineManagementAPI} from "../../../utils/APIutils";
import {
  addLoadingAPIs,
  handleStandardCatchError,
  processStandardSuccessCall,
  removeErrorOnAPIs,
  removeLoadingAPIs,
} from "../../../actions/commonActions";
import moment from 'moment';

/* getDataSpecs() */
export function getDataSpecs( ) {

  return function(dispatch) {

    var params = {};
    var body = {};
    var additionalParams = {};

    dispatch(addLoadingAPIs(types.GET_DATA_SPECS));
    dispatch(removeErrorOnAPIs(types.GET_DATA_SPECS));

    return pipelineManagementAPI().dataspecsGet(params, body, additionalParams)
      .then(function (result) {

        let shouldDispatchFullSuccess = processStandardSuccessCall(result, types.GET_DATA_SPECS, dispatch);

        if (shouldDispatchFullSuccess) {
          dispatch(loadGetDataSpecsSuccess(result.data.result));
        }

        dispatch(removeLoadingAPIs(types.GET_DATA_SPECS));
      })
      .catch(function(errors){
        handleStandardCatchError(errors, types.GET_DATA_SPECS, dispatch);
      });
  }
}

export function loadGetDataSpecsSuccess(result) {
  return { type: types.GET_DATA_SPECS, result: result }
}

/* getPricingSpecs() */
export function getPricingSpecs() {

  return function(dispatch) {

    var params = {};
    var body = {};
    var additionalParams = {};

    dispatch(addLoadingAPIs(types.GET_PRICING_SPECS));
    dispatch(removeErrorOnAPIs(types.GET_PRICING_SPECS));

    return pipelineManagementAPI().pricingSpecsGet(params, body, additionalParams)
      .then(function (result) {

        let shouldDispatchFullSuccess = processStandardSuccessCall(result, types.GET_PRICING_SPECS, dispatch);

        if (shouldDispatchFullSuccess) {
          dispatch(loadGetPricingSpecsSuccess(result.data.result));
        }

        dispatch(removeLoadingAPIs(types.GET_PRICING_SPECS));
      })
      .catch(function(errors){
        handleStandardCatchError(errors, types.GET_PRICING_SPECS, dispatch);
      });
  }
}

export function loadGetPricingSpecsSuccess(result) {
  return { type: types.GET_PRICING_SPECS, result: result }
}

/* getEnvironments() */
export function getEnvironments() {

  return function(dispatch) {

    var params = {};
    var body = {};
    var additionalParams = {};

    dispatch(addLoadingAPIs(types.GET_ENVIRONMENTS));
    dispatch(removeErrorOnAPIs(types.GET_ENVIRONMENTS));

    return pipelineManagementAPI().environmentsGet(params, body, additionalParams)
      .then(function (result) {

        let shouldDispatchFullSuccess = processStandardSuccessCall(result, types.GET_ENVIRONMENTS, dispatch);

        if (shouldDispatchFullSuccess) {
          dispatch(loadGetEnvironmentsSuccess(result.data.result));
        }

        dispatch(removeLoadingAPIs(types.GET_ENVIRONMENTS));
      })
      .catch(function(errors) {
        handleStandardCatchError(errors, types.GET_ENVIRONMENTS, dispatch);
      });
  }

}

export function loadGetEnvironmentsSuccess(result) {
  return { type: types.GET_ENVIRONMENTS, result: result}
}

/* getPricingConfigFile() */
export function getPricingConfigFile(values, onErrors) {

  return function (dispatch) {

    var params = {
      fileName: values.fileName,
      environmentId: values.environmentId,
      pipelineId: values.pipelineId,
    };
    var body = {};
    var additionalParams = {};

    dispatch(addLoadingAPIs(types.GET_PRICING_CONFIG_FILE));
    dispatch(removeErrorOnAPIs(types.GET_PRICING_CONFIG_FILE));

    return pipelineManagementAPI().environmentsEnvironmentIdPipelinesPipelineIdPricingConfigsFilesFileNameVersionsGet(params, body, additionalParams)
      .then(function (result) {

        let shouldDispatchFullSuccess = processStandardSuccessCall(result, types.GET_PRICING_CONFIG_FILE, dispatch);

        if (shouldDispatchFullSuccess) {
          dispatch(loadPricingConfigFileSuccess(result.data.result));
        }

        dispatch(removeLoadingAPIs(types.GET_PRICING_CONFIG_FILE));
      })
      .catch(function(errors){

        // TODO: remove the work around once the API has been updated to handle empty case
        // https://jira.nomissolutions.com/jira/browse/SPUTCLT-331
        if (errors.data &&
          errors.data.errors &&
          errors.data.errors.length > 0 &&
          errors.data.errors[0] &&
          errors.data.errors[0].errorMessage &&
          errors.data.errors[0].errorMessage.includes("configuration file not found")) {

          dispatch(removeLoadingAPIs(types.GET_PRICING_CONFIG_FILE));
          dispatch(loadPricingConfigFileSuccess([]));

          return;
        }

        handleStandardCatchError(errors, types.GET_PRICING_CONFIG_FILE, dispatch, onErrors);
      });
  }
}

export function loadPricingConfigFileSuccess(result) {
  return { type: types.GET_PRICING_CONFIG_FILE, result: result }
}

/* postActivePricingConfig() */
export function postActivePricingConfig(values, onSuccess, onErrors) {

  return function (dispatch) {

    var params = {
      environmentId: values.environmentId,
      pipelineId: values.pipelineId,
    };

    var body = values.body;
    var additionalParams = {};

    dispatch(addLoadingAPIs(types.POST_ACTIVE_PRICING_CONFIG_FILE));
    dispatch(removeErrorOnAPIs(types.POST_ACTIVE_PRICING_CONFIG_FILE));

    return pipelineManagementAPI().environmentsEnvironmentIdPipelinesPipelineIdActivePricingConfigPost(params, body, additionalParams)
      .then(function (result) {

        processStandardSuccessCall(result, types.POST_ACTIVE_PRICING_CONFIG_FILE, dispatch, onSuccess, onErrors);

        dispatch(removeLoadingAPIs(types.POST_ACTIVE_PRICING_CONFIG_FILE));
      })
      .catch(function (errors) {
        handleStandardCatchError(errors, types.POST_ACTIVE_PRICING_CONFIG_FILE, dispatch, onErrors);
      });
  }
}

/* postPricingConfigFile() */
export function postPricingConfigFile(values, onSuccess, onErrors) {

  return function (dispatch) {

    var params = {
      environmentId: values.environmentId,
      pipelineId: values.pipelineId,
    };

    var body = {
      fileName: values.fileName,
      fileContent: values.fileContent
    };
    var additionalParams = {};

    dispatch(addLoadingAPIs(types.POST_PRICING_CONFIG_FILE));
    dispatch(removeErrorOnAPIs(types.POST_PRICING_CONFIG_FILE));

    return pipelineManagementAPI().environmentsEnvironmentIdPipelinesPipelineIdPricingConfigsFilesPost(params, body, additionalParams)
      .then(function (result) {

        processStandardSuccessCall(result, types.POST_PRICING_CONFIG_FILE, dispatch, onSuccess, onErrors);

        dispatch(removeLoadingAPIs(types.POST_PRICING_CONFIG_FILE));

      })
      .catch(function(errors) {
        handleStandardCatchError(errors, types.POST_PRICING_CONFIG_FILE, dispatch, onErrors);
      });

  }
}

/* runPipeline() */
export function runPipeline(values, onSuccess, onErrors) {

  return function (dispatch) {

    var params = {
      environmentId: values.environmentId,
      pipelineId: values.pipelineId,
    };
    var body = {
      email: values.email,
    };
    var additionalParams = {};

    dispatch(addLoadingAPIs(types.POST_RUN_PIPELINE));
    dispatch(removeErrorOnAPIs(types.POST_RUN_PIPELINE));

    return pipelineManagementAPI().environmentsEnvironmentIdPipelinesPipelineIdRunRequestPost(params, body, additionalParams)
      .then(function (result) {

        processStandardSuccessCall(result, types.POST_RUN_PIPELINE, dispatch, onSuccess, onErrors);

        dispatch(removeLoadingAPIs(types.POST_RUN_PIPELINE));
      })
      .catch(function(errors) {
        handleStandardCatchError(errors, types.POST_RUN_PIPELINE, dispatch, onErrors);
      })
  }
}

/* putPipeline() */
export function putPipeline(values, onSuccess, onErrors) {

  return function (dispatch) {

    var params = {
      environmentId: values.environmentId,
      pipelineId: values.pipelineId,
    };
    var body = {
      dataSpecVersion: values.dataSpecVersion,
      pipelineStage: values.pipelineStage,
      reportUrl: values.reportUrl
    };
    var additionalParams = {};

    dispatch(addLoadingAPIs(types.PUT_PIPELINE));
    dispatch(removeErrorOnAPIs(types.PUT_PIPELINE));

    return pipelineManagementAPI().environmentsEnvironmentIdPipelinesPipelineIdPut(params, body, additionalParams)
      .then(function (result) {

        processStandardSuccessCall(result, types.PUT_PIPELINE, dispatch, onSuccess, onErrors);

        dispatch(removeLoadingAPIs(types.PUT_PIPELINE));
      })
      .catch(function(errors) {
        handleStandardCatchError(errors, types.PUT_PIPELINE, dispatch, onErrors);
      })

  }
}

/* getPipelineDropFileErrors() */
export function getPipelineDropFileErrors(values, onErrors) {

  return function (dispatch) {

    var params = {
      environmentId: values.environmentId,
      pipelineId: values.pipelineId,
      fileId: values.fileId,
    };
    var body = {};
    var additionalParams = {};

    dispatch(addLoadingAPIs(types.GET_PIPELINE_DROP_FILE_ERRORS));
    dispatch(removeErrorOnAPIs(types.GET_PIPELINE_DROP_FILE_ERRORS));

    return pipelineManagementAPI().environmentsEnvironmentIdPipelinesPipelineIdDataFilesFileIdGet(params, body, additionalParams)
      .then(function (result) {

        let shouldDispatchFullSuccess = processStandardSuccessCall(result, types.GET_PIPELINE_DROP_FILE_ERRORS, dispatch);

        if (shouldDispatchFullSuccess) {

          dispatch(loadPipelineDropFileErrors(result.data.result));
        }

        dispatch(removeLoadingAPIs(types.GET_PIPELINE_DROP_FILE_ERRORS));
      })
      .catch(function(errors) {
        handleStandardCatchError(errors, types.GET_PIPELINE_DROP_FILE_ERRORS, dispatch, onErrors);
      });

  }
}

export function loadPipelineDropFileErrors(result) {
  return { type: types.GET_PIPELINE_DROP_FILE_ERRORS, result: result }
}

/* getPipelineDrops() */
export function getPipelineDrops(values, onErrors) {

  return function (dispatch) {

    var params = {
      environmentId: values.environmentId,
      pipelineId: values.pipelineId,
    };
    var body = {};
    var additionalParams = {};

    dispatch(addLoadingAPIs(types.GET_PIPELINE_DROPS));
    dispatch(removeErrorOnAPIs(types.GET_PIPELINE_DROPS));

    return pipelineManagementAPI().environmentsEnvironmentIdPipelinesPipelineIdDataFilesGet(params, body, additionalParams)
      .then(function (result) {

        let shouldDispatchFullSuccess = processStandardSuccessCall(result, types.GET_PIPELINE_DROPS, dispatch);

        if (shouldDispatchFullSuccess) {

          let drops = result.data.result.reduce((drops, current) => {

            if (!drops[current.dropKey]) {

              drops[current.dropKey] = {
                key: current.dropKey,
                staged: current.staged,
                totalNumOfErrors: current.numErrors ? current.numErrors : 0,
                lastModifiedDate: current.fileModifiedDate,
                files: [current]
              };

            } else {

              let dropObject = Object.assign(drops[current.dropKey], {});
              dropObject.files.push(current);

              if (current.staged === true
                && dropObject.stage !== true) {

                dropObject.stage = true;
              }

              if (moment(dropObject.lastModifiedDate).isBefore(current.fileModifiedDate)) {
                dropObject.lastModifiedDate = current.fileModifiedDate;
              }

              if (current.numErrors) {
                dropObject.totalNumOfErrors += current.numErrors;
              }

              drops[current.dropKey] = Object.assign(dropObject, {});
            }
            return drops;
          }, {});

          dispatch(loadPipelineDrops(drops));

          dispatch(removeLoadingAPIs(types.GET_PIPELINE_DROPS));
        }

      })
      .catch(function(errors) {
        handleStandardCatchError(errors, types.GET_PIPELINE_DROPS, dispatch, onErrors);
      });

  }
}

export function loadPipelineDrops(result) {
  return { type: types.GET_PIPELINE_DROPS, result: result }
}
