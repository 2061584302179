import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { DotLoader } from "react-spinners";
import color_palette from "../../assets/color/style-constants";
import ReactPiwik from "react-piwik";

export const BUTTON_STATE = Object.freeze({
  ACTIVE: "ACTIVE",
  DISABLED: "DISABLED",
  LOADING: "LOADING"
});

const StyledButton = styled.button`
  color: ${color_palette.white_text};
  cursor: pointer;
  background-color: ${({ state }) =>
    state === BUTTON_STATE.DISABLED ? color_palette.light_header : color_palette.highlighted_icon};
  border-radius: 6px;
  padding: 12px 32px;

  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  width: 100%;
`;

const Loader = styled.div`
  margin: 0 auto;
  height: 24px;
  width: fit-content;
`;

const trackableOnClick = (onClick, name, value) => {
  ReactPiwik.push(["trackEvent", "Button Click", name, value]);
  onClick();
};

const Button = ({
  children,
  onClick,
  state,
  className,
  buttonName,
  buttonValue
}) => (
  <StyledButton
    className={className}
    state={state}
    onClick={
      state === BUTTON_STATE.ACTIVE
        ? () => trackableOnClick(onClick, buttonName, buttonValue)
        : undefined
    }
  >
    {state === BUTTON_STATE.LOADING ? (
      <Loader>
        <DotLoader sizeUnit="px" size={16} color="#fff" loading={true} />
      </Loader>
    ) : (
      children
    )}
  </StyledButton>
);

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  buttonName: PropTypes.string,
  buttonValue: PropTypes.string,
  onClick: PropTypes.function,
  state: PropTypes.oneOf(Object.keys(BUTTON_STATE)).isRequired,
  style: PropTypes.string
};

Button.defaultProps = {
  state: BUTTON_STATE.ACTIVE
};

export default Button;
