import React from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom";

import { POST_SPEC_VERSION } from '../../actions/actionTypes';

import { LayoutStretch } from "../../../../utils/UIUtils";
import ListHeader, {
  ACTION_BUTTON_STYLE
} from "../../../ClientManager/components/ListHeader/ListHeader";
import { deck } from "../../../../assets/copy/CopyDeck";
import FileSpecificationCard from "../../components/Cards/FileSpecificationCard";
import { getDataSpecs, getPricingSpecs } from "../../actions/pipelineActions";
import SectionTitleElement from "../../../ClientManager/components/Elements/SectionTitleElement";
import styled from "styled-components";
import {
  initSpecEdit,
  saveSpec,
  removeFileFromSpec,
  saveFile
} from "../../actions/specEditActions";
import { toast } from "react-toastify";
import Button from '../../../../components/Button';
import LoadingElement from "../../../../components/Loading/LoadingElement";
import color_palette from "../../../../assets/color/style-constants";
import NewSpecVersionModal from "./NewSpecVersionModal";
import { canCreateDataSpec } from "../../../../reducers";

const AddNewFileButtonContainer = styled.div`
  text-align: center;
`;

const AddNewFileButton = styled(Button)`
  width: 140px;
  height: 35px;
  border-radius: 2px;
  border: solid 1px ${color_palette.default_border};
  background-color: ${color_palette.support_highlighted_text};
  color: white;
  cursor: pointer;
`;

class ViewSpecVersion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editing_spec_file_name: "",
      editing_spec_file_description: "",
      editing_state: EDITING_STATE.VIEW
    };

    this.setState = this.setState.bind(this);
    this.selectedItemAction = this.selectedItemAction.bind(this);
    this.cancelCreateNewSpec = this.cancelCreateNewSpec.bind(this);
    this.createNewVersion = this.createNewVersion.bind(this);
    this.saveAction = this.saveAction.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.addNewFileAction = this.addNewFileAction.bind(this);
    this.currentSpecVersion = this.currentSpecVersion.bind(this);
  }

  componentDidMount() {
    if (!this.props.dataSpecs) {
      this.props.dispatch(getDataSpecs());
    }

    if (!this.props.pricingSpecs) {
      this.props.dispatch(getPricingSpecs());
    }
  }

  currentSpecVersion() {
    let currentSpecVersion;

    if (!this.props.dataSpecs || !this.props.pricingSpecs) {
      return;
    }

    if (this.isEditing()) {
      return this.props.editingSpec;
    }

    let routeDetails = this.routeDetails();

    if (routeDetails.specType === "view-dataspecs") {
      this.props.dataSpecs.map(spec => {
        if (
          spec.id === routeDetails.specId &&
          spec.version === routeDetails.version
        ) {
          currentSpecVersion = spec;
        }
      });
    } else if (routeDetails.specType === "view-pricing-config") {
      this.props.pricingSpecs.map(spec => {
        if (
          spec.id === routeDetails.specId &&
          spec.version === routeDetails.version
        ) {
          currentSpecVersion = spec;
        }
      });
    }

    return currentSpecVersion;
  }

  routeDetails() {
    var splitString = this.props.router.location.pathname.split("/");

    let specType = splitString[2];
    let specId = splitString[3];
    let version = parseInt(splitString[4]);

    return {
      specType: specType,
      specId: specId,
      version: version
    };
  }

  isEditing() {
    if (!this.props.editingSpec) {
      return false;
    }

    let routeDetails = this.routeDetails();

    // TODO: Verify that the IDs are related to the Data Spec or the Price Spec; currently not unique!
    if (routeDetails.specType === "view-dataspecs") {
      if (
        this.props.editingSpec.id === routeDetails.specId &&
        this.props.editingSpec.version === routeDetails.version
      ) {
        return true;
      }
    } else if (routeDetails.specType === "view-pricing-config") {
      if (
        this.props.editingSpec.id === routeDetails.specId &&
        this.props.editingSpec.version === routeDetails.version
      ) {
        return true;
      }
    }

    return false;
  }

  selectedItemAction(item) {
    let routeDetails = this.routeDetails();
    let route =
      "/pipeline-manager/" +
      routeDetails.specType +
      "/" +
      routeDetails.specId +
      "/" +
      routeDetails.version +
      "/" +
      item.name;

    this.props.history.push(route);
  }

  renderDataFileSpecifications(specifications) {
    if (!specifications) {
      return;
    }

    let specificationItems = [];

    specifications.map((specification, index) => {
      specificationItems.push(
        <FileSpecificationCard
          key={"specification_card_" + index}
          specification={specification}
          cardSelected={this.selectedItemAction}
        />
      );
    });

    return specificationItems;
  }

  saveAction() {
    let type = SPEC_TYPE.DATA_SPEC;

    let routeDetails = this.routeDetails();

    if (routeDetails.specType === "view-pricing-config") {
      type = SPEC_TYPE.PRICING_SPEC;
    }

    if (this.state.editing_spec_file_description === "") {

      toast.error(deck().spec_version_description_was_not_set, {
        position: toast.POSITION.BOTTOM_CENTER
      });

      return;
    }

    let specToPass = Object.assign({}, this.props.editingSpec);
    specToPass.description = this.state.editing_spec_file_description;

    this.props.dispatch(
      saveSpec(
        type,
        specToPass,
        this.onSuccess,
        ViewSpecVersion.onError
      )
    );
  }

  static onError(errors) {
    let message = deck().general_api_error;

    // Grab the first Error and display it
    // TODO: Update to handle multiple errors
    if (errors && errors.length > 0 && errors[0] && errors[0].errorMessage) {
      message = errors[0].errorMessage;
    }

    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }

  onSuccess() {
    let routeDetails = this.routeDetails();
    if (routeDetails.specType === "view-pricing-config") {
      this.props.dispatch(getPricingSpecs());
      let route = "/pipeline-manager/view-pricing-config";
      this.props.history.push(route);
    } else if (routeDetails.specType === "view-dataspecs") {
      this.props.dispatch(getDataSpecs());
      let route = "/pipeline-manager/view-dataspecs";
      this.props.history.push(route);
    }
  }

  addNewFileAction() {
    let routeDetails = this.routeDetails();
    let route =
      "/pipeline-manager/" +
      routeDetails.specType +
      "/" +
      routeDetails.specId +
      "/" +
      routeDetails.version +
      "/new-file";

    this.props.history.push(route);
  }

  createNewVersion() {
    this.setState({
      editing_state: EDITING_STATE.EDITING
    });

    this.props.dispatch(initSpecEdit(this.currentSpecVersion()));
  }

  renderActionButton() {
    const {
      canCreateDataSpec,
      router: {
        location: { pathname }
      }
    } = this.props;
    if (!canCreateDataSpec) {
      return;
    }
    if (
      pathname.length > 2 &&
      pathname.split("/")[2] === "view-pricing-config"
    ) {
      return;
    }

    return {
      title: deck().spec_version_create_new_button,
      action: this.createNewVersion,
      style: ACTION_BUTTON_STYLE.REGULAR
    }
  }

  renderAddNewFileButton() {
    if (this.state.editing_state === EDITING_STATE.EDITING) {
      return (
        <AddNewFileButtonContainer>
          <AddNewFileButton onClick={this.addNewFileAction}>
            {deck().spec_version_add_new_file_button}
          </AddNewFileButton>
        </AddNewFileButtonContainer>
      );
    }
  }

  renderListHeader() {
    const { layoutStretch } = this.props;

    if (layoutStretch === LayoutStretch.PRIMARY_FULL) {
      let versionLabel = deck().spec_version_version_label;
      if (this.currentSpecVersion()) {
        versionLabel =
          versionLabel +
          this.currentSpecVersion().version +
          " - " +
          this.currentSpecVersion().name;
      }

      return (
        <ListHeader
          title={versionLabel}
          actionButton={this.renderActionButton()}
        />
      );
    }
  }

  cancelCreateNewSpec() {
    const { dispatch } = this.props;

    this.setState({
      editing_state: EDITING_STATE.VIEW,
      editing_spec_file_name: "",
      editing_spec_file_description: ""
    });

    dispatch(initSpecEdit({}));
  }


  render() {
    const { dispatch, isSavingSpec } = this.props;
    const { editing_spec_file_name, editing_state } = this.state;
    if (!this.props.dataSpecs || !this.props.pricingSpecs) {
      return <LoadingElement />;
    }

    const currentSpecVersion = this.currentSpecVersion();

    let dataFileSpecifications;
    if (
      currentSpecVersion &&
      currentSpecVersion.dataFileSpecifications
    ) {
      dataFileSpecifications = currentSpecVersion.dataFileSpecifications;
    }

    return (
      <div>
        {this.renderListHeader()}

        <SectionTitleElement title={deck().spec_version_active_label} />

        {this.renderDataFileSpecifications(dataFileSpecifications)}

        <NewSpecVersionModal
          currentSpecVersion={currentSpecVersion}
          editingFileName={editing_spec_file_name}
          isOpen={editing_state === EDITING_STATE.EDITING}
          isSavingSpec={isSavingSpec}
          onCancel={this.cancelCreateNewSpec}
          onSave={this.saveAction}
          removeFileFromSpec={name => dispatch(removeFileFromSpec(name))}
          setEditingFileName={name => this.setState({ editing_spec_file_name: name })}
          setSpecDescription={description => this.setState({ editing_spec_file_description: description })}
          saveFileForSpec={(file, editingSpec) => dispatch(saveFile(file, editingSpec))}
        />
      </div>
    );
  }
}

ViewSpecVersion.propTypes = {
  dispatch: PropTypes.func,
  dataSpecs: PropTypes.object,
  pricingSpecs: PropTypes.object,
  router: PropTypes.object,
  layoutStretch: PropTypes.number,
  history: PropTypes.object,
  editingSpec: PropTypes.object,
  isSavingSpec: PropTypes.bool,
  canCreateDataSpec: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    router: state.router,
    dataSpecs: state.PipelineManager.pipeline.dataSpecs,
    pricingSpecs: state.PipelineManager.pipeline.pricingSpecs,
    editingSpec: state.PipelineManager.specEdit.editingSpec,
    isSavingSpec: state.common.loading.includes(POST_SPEC_VERSION),
    canCreateDataSpec: canCreateDataSpec(state)
  };
}

export default connect(mapStateToProps)(withRouter(ViewSpecVersion));

export const EDITING_STATE = {
  VIEW: "VIEW",
  EDITING: "EDITING",
  DISABLED: "DISABLED"
};

export const SPEC_TYPE = {
  DATA_SPEC: "DATA_SPEC",
  PRICING_SPEC: "PRICING_SPEC"
};
