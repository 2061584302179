import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import { deck } from "../../../../assets/copy/CopyDeck";

import ErrorElement from "../../../../components/Error/ErrorElement";
import LoadingElement from "../../../../components/Loading/LoadingElement";

import {
  getClients,
  getOfferings
} from "../../../ClientManager/actions/clientActions";
import {
  getDataSpecs,
  getEnvironments,
  getPricingSpecs,
  putPipeline,
  runPipeline,
  getPipelineDrops
} from "../../actions/pipelineActions";

import {
  GET_CLIENTS,
  GET_OFFERINGS
} from "../../../ClientManager/actions/actionTypes";
import {
  GET_ENVIRONMENTS,
  GET_PIPELINE_DROPS,
  GET_PRICING_SPECS
} from "../../actions/actionTypes";
import {
  dataSpecFromName,
  pipelineFromId,
  clientIdFromPipelineId,
  clientFromId,
  environmentFromPipelineId
} from "../../controllers/PipelineController";
import PipelineTopBar from "./PipelineTopBar";
import { toast } from "react-toastify";

import PricingConfigs from "./PricingConfigs";
import ViewDrop from "../ViewDrop/ViewDrop";
import { LayoutStretch } from "../../../../utils/UIUtils";
import SectionTitleElement from "../../../ClientManager/components/Elements/SectionTitleElement";
import color_palette from "../../../../assets/color/style-constants";
import { canEditQuickSight, canEditPipeline } from "../../../../reducers";
import PipelineDropTable from "./PipelineDropTable";
import { CSVExport } from "../../../../components/Table";

const SectionContainer = styled.div`
  border-radius: 6px;
  background-color: ${color_palette.white_text};
`;

const Spacer = styled.div`
  width: 100%;
  height: 50px;
`;

class ViewPipelineDetailContainer extends Component {
  constructor(props) {
    super(props);

    this.onUpdatePipelineError = this.onUpdatePipelineError.bind(this);
    this.onUpdatePipelineSuccess = this.onUpdatePipelineSuccess.bind(this);
    this.updatePipeline = this.updatePipeline.bind(this);
  }

  componentDidMount() {
    this.props.refreshData(this.props.currentPipelineDrops, this.props.currentEnvironment);
  }

  componentDidUpdate() {

    const {
      currentEnvironment,
      currentPipelineDrops,
      getPipelineDrops,
      loading,
      errors
    } = this.props;

    if (!currentPipelineDrops &&
      currentEnvironment &&
      !loading.includes(GET_PIPELINE_DROPS) &&
      !errors.some(error => error.type === GET_PIPELINE_DROPS)) {

      let values = {
        environmentId: currentEnvironment.id,
        pipelineId: window.location.pathname.split("/")[3]
      };

      getPipelineDrops(values);
    }
  }

  onUpdatePipelineError(errors = []) {
    const { general_api_error } = deck();

    const message = errors[0] ? errors[0].errorMessage : general_api_error;

    toast.error(message, { position: toast.POSITION.BOTTOM_CENTER });
  }

  onUpdatePipelineSuccess() {
    const { getEnvironments } = this.props;
    const { update_pipeline_success_message } = deck();
    getEnvironments();
    toast.success(update_pipeline_success_message, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 2000,
      pauseOnFocusLoss: false
    });
  }

  updatePipeline(newPipeline) {
    const { currentEnvironment, pipeline, putPipeline } = this.props;

    return putPipeline(
      {
        environmentId: currentEnvironment.id,
        pipelineId: pipeline.id,
        ...newPipeline
      },
      this.onUpdatePipelineSuccess,
      this.onUpdatePipelineError
    );
  }

  render() {
    const {
      canEditPipeline,
      canEditQuickSight,
      client,
      containerId,
      currentPipelineDrop,
      currentPipelineDrops,
      dataSpecId,
      dataSpecs,
      errors,
      hasError,
      history,
      isLoading,
      pipeline,
      pipelineName,
      pricingSpecs,
      runPipeline,
      currentSelectedItem,
      selectedItemAction
    } = this.props;

    const { view_pricing_configurations_title } = deck();

    if (isLoading) {
      return <LoadingElement />;
    }

    if (hasError) {
      return errors.map(({ errors, type }, index) => (
        <ErrorElement key={`${index}-${type}`} errors={errors} type={type} />
      ));
    }

    return (
      <>
        <PipelineTopBar
          canEditPipeline={canEditPipeline}
          canEditQuickSight={canEditQuickSight}
          client={client}
          dataSpecId={dataSpecId}
          dataSpecs={dataSpecs}
          pipeline={pipeline}
          pricingSpecs={pricingSpecs}
          putPipeline={this.updatePipeline}
          runPipeline={runPipeline}
        />
        <Spacer />

        <div className="row">
          <div className="col-6">
            {pipeline && (
              <ViewDrop
                layoutStretch={LayoutStretch.PRIMARY_HALF}
                pipelineDrop={currentPipelineDrop ? currentPipelineDrop : null}
                pipelineId={pipeline.id}
                viewAllDropsSelected={() =>
                  history.push(
                    `/pipeline-manager/pipelines/${pipeline.id}/drops`
                  )
                }
              />
            )}
          </div>

          {pipeline && pipeline.pricingSpec && (
            <div className="col-6">
              <SectionContainer className="container">
                <div className="row">
                  <div className="col">
                    <SectionTitleElement
                      title={view_pricing_configurations_title}
                    />
                  </div>
                </div>

                <div className="row">
                  <PricingConfigs
                    onSelect={({ name }) => {
                      if (currentSelectedItem) {
                        selectedItemAction(null);
                        setTimeout(
                          () => selectedItemAction(name, containerId),
                          400
                        );
                        return;
                      }
                      selectedItemAction(name, containerId);
                    }}
                    pricingSpec={pipeline.pricingSpec}
                    selectedItem={currentSelectedItem}
                  />
                </div>
              </SectionContainer>
            </div>
          )}
        </div>
        <Spacer />
        <div className="row">
          <div style={{ overflowX: 'scroll' }} className="col-12">
                {currentPipelineDrops && Object.keys(currentPipelineDrops).length > 0 ? (<><CSVExport filename={`${pipelineName}_files`}/> <PipelineDropTable drops={currentPipelineDrops} /></>): null}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    common,
    router: { location },
    PipelineManager,
    ClientManager
  } = state;
  const { loading, errors } = common;
  const {
    pipeline: { currentPipelineDrops, dataSpecs, environments, pricingSpecs }
  } = PipelineManager;
  const {
    client: { clients }
  } = ClientManager;

  const isLoading =
    loading.includes(GET_ENVIRONMENTS) ||
    loading.includes(GET_CLIENTS) ||
    loading.includes(GET_OFFERINGS) ||
    loading.includes(GET_PRICING_SPECS);

  const hasError = errors.some(
    ({ type }) =>
      type === GET_CLIENTS ||
      type === GET_ENVIRONMENTS ||
      type === GET_OFFERINGS ||
      type === GET_PRICING_SPECS
  );

  const pipelineId =
    location && location.pathname.length > 2 && location.pathname.split("/")[3];
  const pipeline = pipelineFromId(environments, pipelineId);

  const clientId =
    !isLoading && clientIdFromPipelineId(environments, pipelineId);
  const client = clientId && clientFromId(clients, clientId);

  const dataSpecName =
    pipeline &&
    pipeline.subscription &&
    pipeline.subscription.offering &&
    pipeline.subscription.offering.supportedDataSpec;

  const dataSpec = pipeline && dataSpecs && dataSpecFromName(dataSpecs, dataSpecName, pipeline.dataSpecVersion);
  const dataSpecId = dataSpec ? dataSpec.id : "";

  const currentEnvironment =
    !isLoading && environmentFromPipelineId(environments, pipelineId);

  const pipelineName =
    pipeline &&
    pipeline.subscription &&
    pipeline.subscription.offering &&
    pipeline.subscription.offering.name

  return {
    canEditPipeline: canEditPipeline(state),
    canEditQuickSight: canEditQuickSight(state),
    client,
    currentPipelineDrops,
    currentPipelineDrop: currentPipelineDrops !== undefined
      ? Object.values(currentPipelineDrops)[0]
      : null,
    dataSpecId,
    dataSpecs,
    currentEnvironment,
    errors,
    hasError,
    isLoading,
    loading,
    pipeline,
    pipelineName,
    pricingSpecs
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEnvironments: () => dispatch(getEnvironments()),
  putPipeline: (payload, onSuccess, onError) =>
    dispatch(putPipeline(payload, onSuccess, onError)),
  refreshData: (currentPipelineDrops, currentEnvironment) => {
    dispatch(getClients());
    dispatch(getEnvironments());
    dispatch(getOfferings());
    dispatch(getPricingSpecs());
    dispatch(getDataSpecs());

    if (currentPipelineDrops) {

      let values = {
        environmentId: currentEnvironment.id,
        pipelineId: window.location.pathname.split("/")[3]
      };

      dispatch(getPipelineDrops(values));
    }
  },
  getPipelineDrops: (values, onError) =>
    dispatch(getPipelineDrops(values, onError)),
  runPipeline: (values, onSuccess, onError) =>
    dispatch(runPipeline(values, onSuccess, onError))
});

ViewPipelineDetailContainer.propTypes = {
  client: PropTypes.object,
  containerId: PropTypes.string,
  currentPipelineDrops: PropTypes.array,
  currentPipelineDrop: PropTypes.object,
  dataSpecId: PropTypes.string,
  dataSpecs: PropTypes.object,
  currentEnvironment: PropTypes.object,
  errors: PropTypes.array,
  getEnvironments: PropTypes.func,
  hasError: PropTypes.bool,
  history: PropTypes.object,
  isLoading: PropTypes.bool,
  loading: PropTypes.object,
  pipeline: PropTypes.object,
  pipelineName: PropTypes.string,
  pricingSpecs: PropTypes.object,
  putPipeline: PropTypes.func,
  refreshData: PropTypes.func,
  runPipeline: PropTypes.func,
  selectedItemAction: PropTypes.func,
  currentSelectedItem: PropTypes.object,
  getPipelineDrops: PropTypes.func,
  canEditQuickSight: PropTypes.bool.isRequired,
  canEditPipeline: PropTypes.bool.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ViewPipelineDetailContainer));
