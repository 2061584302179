import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

const StyledIcon = styled(Icon)`
  margin-left: 8px;
`

const AggregatedCell = ({ row: { isExpanded } }) => (
    <div>
      <StyledIcon icon={isExpanded ? IconNames.CARET_UP : IconNames.CARET_DOWN}/>
    </div>
)

AggregatedCell.propTypes = {
  cell: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired
}

export default AggregatedCell
