import { combineReducers } from 'redux';
import bankReducer from "./bankReducer";
import testBankReducer from "./testBankReducer";

const BankManager = combineReducers({
  bankReducer: bankReducer,
  testBankReducer: testBankReducer,
});

export default BankManager;
