/* eslint-disable no-undef */
import React from "react";
import styled from "styled-components";
import { deck } from "../../assets/copy/CopyDeck";
import color_palette from "../../assets/color/style-constants.js";

const StyledFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 14px;
  padding: 2px 8px;
  margin: 0px;
  background-color: ${color_palette.app_background_color};
  width: 100%;
`;

const StyledSpan = styled.div`
  align-self: flex-start;
  margin-right: 4px;
`;

const IconCreditsAnchor = styled.a`
  flex: 1 0 100%;
`;

const BranchAnchor = styled.a``

const UserManualAnchor = styled.a`
  align-self: flex-end;
  color: ${color_palette.white_text};
  cursor: pointer;
  background-color: ${color_palette.user_manual_anchor};
  border-radius: 4px;
  padding: 2px 4px;
  width: auto;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  :visited {
    color: ${color_palette.white_text};
  }
`;

const VerticalFlex = styled.div`
  flex: 1 1 33%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
`;

const Footer = () => (
  <StyledFooter>
    <VerticalFlex>
      <StyledSpan>{deck().footer_copyright}</StyledSpan>
    </VerticalFlex>
    <VerticalFlex>
      <BranchAnchor href={BUILD_URL} target="_blank" rel="noopener noreferrer">
        {`${
          deck().footer_sputnik_version_prefix
        }${PACKAGE_VERSION}-${BRANCH_NAME}.${BUILD_NUMBER}`}
      </BranchAnchor>
    </VerticalFlex>
    <VerticalFlex>
      <UserManualAnchor
        href={deck().footer_user_manual_link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {deck().footer_user_manual}
      </UserManualAnchor>
    </VerticalFlex>
    <IconCreditsAnchor href="/credits">
      {deck().footer_icon_credit_link}
    </IconCreditsAnchor>
  </StyledFooter>
);

export default Footer;
