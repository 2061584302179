import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  useExpanded,
  useGroupBy,
  useFilters,
  useSortBy,
  useTable
} from "react-table";

import {
  SelectFilter,
  StringFilter,
  TableElements,
  SortableHeaderCell,
  AggregatedCell
} from "../../../../components/Table";

const { StyledTableControl, Table, TR, TD, THead, TH } = TableElements;

const FileErrorTable = ({ errors }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Row",
        accessor: "row",
        Filter: StringFilter,
        aggregate: "count",
        Aggregated: AggregatedCell,
        sortType: "alphanumeric"
      },
      {
        Header: "Error Type",
        accessor: "type",
        aggregate: "count",
        Aggregated: AggregatedCell,
        Filter: SelectFilter,
        sortType: "alphanumeric"
      },
      {
        Header: "Error",
        accessor: "error",
        Filter: StringFilter,
        aggregate: "count",
        Aggregated: AggregatedCell,
        sortType: "alphanumeric"
      }
    ],
    []
  );

  const data = errors
    .trim()
    .split(/\n+/)
    .map(cell => {
      const [row, type, ...error] = cell.split(/,+/);
      return {
        row: row.replace("row: ", ""),
        type: type.trim(),
        error: error.join(",").trim()
      };
    });

  const defaultColumn = React.useMemo(
    () => ({
      Filter: StringFilter
    }),
    []
  );

  const filterTypes = useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    { columns, data, defaultColumn, filterTypes },
    useGroupBy,
    useExpanded,
    useFilters,
    useSortBy
  );

  return (
    <Table {...getTableProps()}>
      <THead>
        {headerGroups.map((headerGroup, headerGroupIndex) => (
          <TR key={headerGroupIndex} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, columnIndex) => (
              <TH key={columnIndex} {...column.getHeaderProps()}>
                <SortableHeaderCell column={column} />
                <StyledTableControl>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </StyledTableControl>
              </TH>
            ))}
          </TR>
        ))}
      </THead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          prepareRow(row);
          return (
            <TR key={rowIndex} {...row.getRowProps()}>
              {row.cells.map((cell, cellIndex) => (
                <TD key={cellIndex} {...cell.getCellProps()}>
                  {cell.isAggregated ? (
                    <div {...row.getExpandedToggleProps()}>
                      {cell.render("Aggregated")}
                    </div>
                  ) : cell.isRepeatedValue ? (
                    ""
                  ) : (
                    <>
                      {cell.render("Cell")}{" "}
                      {row.subRows.length > 0
                        ? `(${row.subRows.length} rows)`
                        : ""}
                    </>
                  )}
                </TD>
              ))}
            </TR>
          );
        })}
      </tbody>
    </Table>
  );
};

FileErrorTable.propTypes = {
  errors: PropTypes.string.isRequired
};

export default FileErrorTable;
