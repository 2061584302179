
// TODO: MOVE TO REDUX STATE
export function pipelinesFromEnvironments(envs, clients) {

  if (!envs) {
    return
  }

  let environments = Object.assign([], envs);

  let pipelines = [];

  environments.forEach(environment => {

    if (environment.pipelines) {

      environment.pipelines.map(pipeline => {

        let tempPipeline = Object.assign({}, pipeline);

        if (clients) {
          let tempClients = Object.assign([], clients);
          tempPipeline.client = clientFromId(tempClients, environment.clientId);
        }

        tempPipeline.environmentId = environment.id;
        pipelines.push(tempPipeline);

      })
    }

  });

  return pipelines;
}

export function clientFromId(clients, clientId) {
  if (!clients ||
    !clientId) {

    return
  }

  let clientToPass;
  clients.forEach(client => {

    if (client.id === clientId ||
      client.clientId === clientId ||
      client.clientId === clientId.toString()) {

      clientToPass = client;
    }

  });

  return clientToPass;
}

export function clientIdFromPipelineId(environments = [], pipelineId) {
  const environment = environmentFromPipelineId(environments, pipelineId)

  if (environment) {
    return environment.clientId;
  }
}

export function environmentFromPipelineId(environments = [], pipelineId) {
  return environments.find(
    ({ pipelines }) => !!pipelines.find(({ id }) => id === pipelineId)
  );
}

export function pipelineFromId(environments, pipelineId) {

  let pipelineToPass;

  let pipelines = Object.assign([], pipelinesFromEnvironments(environments));

  if (!pipelines || !pipelineId)  {
    return;
  }

  pipelines.forEach(pipeline => {

    if (pipeline.id === pipelineId) {
      pipelineToPass = pipeline;

    }
  });

  return Object.assign({}, pipelineToPass);

}

export function dataSpecFromName(dataSpecs, name, version) {

  let dataSpecToPass;

  if (!dataSpecs || !name || version === undefined) {
    return;
  }

  dataSpecs.forEach(dataSpec => {

    if (dataSpec.name === name &&
      dataSpec.version === version) {

      dataSpecToPass = dataSpec;
    }
  });

  return dataSpecToPass;
}

export function setupSpecVersionedObjects(specs) {

  let versionedSpecs = [];

  specs.forEach(spec => {

    let versionContained = false;
    versionedSpecs.forEach(versionedSpec => {

      if (spec.name === versionedSpec.name) {

        versionedSpec.versions.push({
          id: spec.id,
          version: spec.version,
          description: spec.description,
          dataFileSpecifications: spec.dataFileSpecifications
        });

        versionContained = true;
      }
    });

    if (!versionContained) {

      versionedSpecs.push({
        id: spec.id,
        name: spec.name,
        versions: [
          {
            id: spec.id,
            version: spec.version,
            description: spec.description,
            dataFileSpecifications: spec.dataFileSpecifications
          },
        ]
      });
    }

  });

  return versionedSpecs;
}
