import React from 'react';
import ReactHighcharts from 'react-highcharts';
import ReactHighstock from 'react-highcharts/ReactHighstock';
import PropTypes from "prop-types";
import {deck} from "../../../../assets/copy/CopyDeck";
import sizeMe from "react-sizeme";

class Graph extends React.Component {

  static renderGraph(title, graphStyle, width, series) {

    const options = {
      title: {
        text: title
      },
      colors: ['#058DC7', '#ED561B', '#64E572'],
      chart: {
        type: graphStyle.type,
        height: graphStyle.height,
        width: width,
      },
      yAxis: [
        {
          labels: {
            align: 'right',
            x: -3
          },
          title: {
            text: deck().graph_percent_error_axis_title,
          },
          height: '40%',
          lineWidth: 2,
          resize: {
            enabled: true
          }
        },
        {
          labels: {
            align: 'right',
            x: -3
          },
          title: {
            text: deck().graph_predicted_actual_axis_title,
          },
          top: '45%',
          height: '65%',
          offset: 0,
          lineWidth: 2,
        }
      ],
      tooltip: {
        split: true
      },
      series: series
    };

    if (graphStyle.style === 'regular') {

      return (
        <div>
          <ReactHighcharts
            config={options}
          />
        </div>
      );

    } else if (graphStyle.style === 'stock') {

      return (
        <div>
          <ReactHighstock
            config={options}
          />
        </div>
      );

    } else {
      return (
        <div>
          <h4>{deck().graph_wrong_data_provided}</h4>
        </div>
      );
    }

  }

  render() {

    var actual = [];
    var predicted = [];

    var upper = [];
    var percentError = [];
    var lower = [];

    const GRAPH_DEFAULT_WIDTH = 600;

    var graphWidth = this.props.size.width ? this.props.size.width : GRAPH_DEFAULT_WIDTH;

    this.props.data.map(value => {

      var timestamp = new Date(value.snapshot_date).getTime();

      actual.push([timestamp, value.actual]);
      predicted.push([timestamp, value.predicted]);


      upper.push([timestamp, value.up]);
      percentError.push([timestamp, value.pe]);
      lower.push([timestamp, value.lb]);
    });

    let PVAseries = [
      {
        name: deck().graph_upper_title,
        data: upper,
      },
      {
        name: deck().graph_percent_error_title,
        data: percentError,
      },
      {
        name: deck().graph_lower_title,
        data: lower,
      },
      {
        name: deck().graph_actual_title,
        data: actual,
        yAxis: 1,
      },
      {
        name: deck().graph_predicted_title,
        data: predicted,
        yAxis: 1,
      },
    ];

    return Graph.renderGraph(this.props.title, this.props.graphStyle, graphWidth, PVAseries);

  }
}

Graph.propTypes = {
  data: PropTypes.array.isRequired,
  graphStyle: PropTypes.object.isRequired,
  title: PropTypes.string,
  size: PropTypes.object,
};

export default sizeMe()(Graph);

export const GRAPH_STYLE = {
  STANDARD_GRAPH: {
    style: 'regular',
    type: 'area',
    height: 600,
  },
  STANDARD_STOCK_GRAPH: {
    style: 'stock',
    type: 'line',
    height: 600,
  }
};
