import React from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import Monitoring from "./views/Monitoring/Monitoring";
import Development from "./views/Development/Development";
import {PropsRoute, wrapHOC} from "../../utils/RouteUtils";
import GenericPage from "../../components/GenericPage/GenericPage";
import {LayoutSide, LayoutStretch} from "../../utils/UIUtils";
import ViewModels from "./views/ViewModels/ViewModels";
import ViewModelsSideBar from "./views/ViewModels/ViewModelsSideBar";

class ModelManager extends React.Component {

  mapRoutes() {

    if (this.props.router &&
      this.props.router.location &&
      this.props.router.location.pathname &&
      this.props.router.location.pathname === '/model-manager') {

      return(
        <div>
          Broken
        </div>
      )
    } else {

      return (
        <switch>

          <PropsRoute
            path={"/model-manager/view-models"}
            exact
            component={GenericPage}
            components={[
              {
                id: 'view-models',
                component: wrapHOC(ViewModels),
                layoutStretch: LayoutStretch.PRIMARY_FULL,
              },
              {
                component: wrapHOC(ViewModelsSideBar),
                layoutStretch: LayoutStretch.SIDE_BAR,
                layoutSide: LayoutSide.RIGHT,
              },
            ]}/>

          <PropsRoute
            path={"/model-manager/monitoring"}
            exact
            component={GenericPage}
            components={[
              {
                id: 'view-model-monitoring',
                component: wrapHOC(Monitoring),
                layoutStretch: LayoutStretch.PRIMARY_FULL,
              }
            ]}/>

          <PropsRoute
            path={"/model-manager/development"}
            exact
            component={GenericPage}
            components={[
              {
                id: 'view-model-development',
                component: wrapHOC(Development),
                layoutStretch: LayoutStretch.PRIMARY_FULL,
              }
            ]}/>

        </switch>
      );
    }
  }

  render() {

    return(
      <div>
        {this.mapRoutes()}
      </div>
    );
  }
}

ModelManager.propTypes = {
  router: PropTypes.router,
};

function mapStateToProps(state) {
  return {
    router: state.router,
  }
}

export default connect(
  mapStateToProps,
)(ModelManager);
