import React from 'react';
import { render } from 'react-dom';
import configureStore, { history }  from "./store/configureStore";
import analytics from './analytics';
import {AppContainer} from "react-hot-loader";
import Root from "./views/Desktop/Root";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import '../node_modules/@blueprintjs/core/lib/css/blueprint.css';
import '../node_modules/@blueprintjs/icons/lib/css/blueprint-icons.css';
import '../node_modules/normalize.css/normalize.css';

const store = configureStore();
const trackableHistory = analytics.connectToHistory(history)

render(
    <AppContainer>
      <Root store={store} history={trackableHistory}/>
    </AppContainer>,
    document.getElementById('app')
);

if (module.hot) {
  module.hot.accept('./views/Desktop/Root', () => {
    const NewRoot = require('./views/Desktop/Root').default;
    render(
      <AppContainer>
        <NewRoot store={store} history={history} />
      </AppContainer>,
      document.getElementById('app')
    );
  });
}
