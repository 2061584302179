import React from 'react';
import PropTypes from "prop-types";
import styled from "styled-components";
import { Tooltip } from "@blueprintjs/core";
import color_palette from "../../../../assets/color/style-constants";


const Container = styled.div`
  margin-bottom: 42px;
`;

const Title = styled.span`
  font-size: 35px;
  font-weight: bold;
  color: ${color_palette.dark_text};
`;

const CreateButton = styled.button`

  font-size: 11px;
  
  margin-left: 30px;

  width: 140px;
  height: 35px;
  border-radius: 2px;
  border: solid 1px ${color_palette.default_border};

  background-color: ${props => props.backgroundColor || color_palette.support_highlighted_text};
  color: white;
`;

class ListHeader extends React.Component {

  render() {
    const { children } = this.props;

    let actionButton;
    if (this.props.actionButton) {
      actionButton = (
        <CreateButton
          type="button"
          className="align-middle"
          backgroundColor={this.props.actionButton.style.backgroundColor}
          onClick={this.props.actionButton.action}>

          {this.props.actionButton.title}
        </CreateButton>
      );
    }

    let title = (<Title>{this.props.title}</Title>);
    if (this.props.titleTooltip) {
      title = (
        <Tooltip
          content={this.props.titleTooltip}
          usePortal={true}>
          <Title>{this.props.title}</Title>
        </Tooltip>
      );
    }

    return (
      <Container className="row">
        <div className="col-sm d-flex align-items-center">
          {title}
          {actionButton}
          {children}
        </div>
      </Container>
    );
  }
}

ListHeader.propTypes = {
  title: PropTypes.string.isRequired,
  titleTooltip: PropTypes.string,
  actionButton: PropTypes.shape({
    title: PropTypes.string,
    action: PropTypes.func,
    style: PropTypes.shape({
      backgroundColor: PropTypes.string,
    }),
  }),
  children: PropTypes.node
};

export default ListHeader;

export const ACTION_BUTTON_STYLE = {
  REGULAR: {
    backgroundColor: color_palette.support_highlighted_text
  },
  SAVE: {
    backgroundColor: "#ff8e73"
  }
};
