import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {toast} from "react-toastify";
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import {deck} from "../../../../assets/copy/CopyDeck";
import {dataSpecFromName} from "../../../PipelineManager/controllers/PipelineController";
import {deleteSubscription, getClients} from "../../actions/clientActions";

import { LabeledContent } from '../../../../components/Typography'
import LoadingCard from "../../../../components/Cards/LoadingCard";
import color_palette from "../../../../assets/color/style-constants";
import {GET_DATA_SPECS} from "../../../PipelineManager/actions/actionTypes";
import { canRemoveSubscription } from "../../../../reducers";

const MainContainer = styled.div`

  padding-right: 8px !important;
  padding-left: 8px !important;

`;

const CardContainer = styled.div`
  border-radius: 6px;
  border: solid 1px #e9eff4;
  margin-bottom: 20px;

  background-color: ${color_palette.card_background_color};
  box-shadow: -2px 2px 4px 0 ${color_palette.default_box_shadow};

  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
`;

const DateLabel = styled.p`
  font-size: 11px;
  color: ${color_palette.light_header};
`;

const ContentLink = styled(Icon)`
  color: ${color_palette.highlighted_icon};
  cursor: pointer;
  margin-left: 8px;
`;

const StyledLabeledContent = styled.div`
  padding: 8px 16px;
`;

const SupportLabel = styled.p`
  font-size: 10px;
  color: ${color_palette.light_header};
  opacity: 0.7;
`;

const RemoveButton = styled.button`

  height: 25px;
  width: 60px;


  border-radius: 2px;
  background-color: ${color_palette.cancel_item};

  font-size: 11px;
  text-align: center;
  color: ${color_palette.white_text};
`;


class SubscriptionCard extends React.Component {

  constructor(props) {
    super(props);

    this.showOffering = this.showOffering.bind(this);
    this.showDataSpec = this.showDataSpec.bind(this);
    this.showPipeline = this.showPipeline.bind(this);
    this.removeSubscription = this.removeSubscription.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
  }

  getDataSpecVersion() {
    const { environment } = this.props;

    if (environment && environment.pipelines && environment.pipelines.length > 0) {
      return environment.pipelines[0].dataSpecVersion;
    }
  }

  getDataSpec() {
    const {
      dataSpecs,
      subscription
    } = this.props;

    const dataSpecVersion = this.getDataSpecVersion();

    if (!dataSpecVersion) {
      return;
    }

    return dataSpecFromName(dataSpecs, subscription.offering.supportedDataSpec, dataSpecVersion);
  }

  showOffering() {

    let route = "/client-manager/view-offerings";

    this.props.history.push(route);
  }

  showDataSpec() {


    const dataSpec = this.getDataSpec();
    const dataSpecVersion = this.getDataSpecVersion();

    if (!dataSpec || !dataSpecVersion) {

      toast.error(deck().subscription_card_error_contact_support, {
        position: toast.POSITION.BOTTOM_CENTER
      });
      return;
    }

    let route = "/pipeline-manager/view-dataspecs/" + dataSpec.id + "/" + dataSpecVersion;

    this.props.history.push(route);
  }

  showPipeline() {

    let pipelineId = "";

    if (this.props.environment &&
      this.props.environment.pipelines &&
      this.props.environment.pipelines.length > 0 &&
      this.props.environment.pipelines[0] &&
      this.props.environment.pipelines[0].id) {

      pipelineId = this.props.environment.pipelines[0].id;
    }

    let route = "/pipeline-manager/pipelines/" + pipelineId;

    this.props.history.push(route);
  }

  removeSubscription() {

    let confirmDialog = confirm(deck().subscription_card_confirm_remove);

    if (confirmDialog === true) {

      let values = {
        clientId: this.props.client.id,
        subscriptionId: this.props.subscription.id,
      };

      this.props.dispatch(deleteSubscription(values, this.onSuccess, SubscriptionCard.onError));

    }

  }

  static onError(errors) {

    let message = deck().general_api_error;

    // Grab the first Error and display it
    // TODO: Update to handle multiple errors
    if (errors &&
      errors.length > 0 &&
      errors[0] &&
      errors[0].errorMessage) {

      message = errors[0].errorMessage;
    }

    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER
    });

  }

  onSuccess() {

    this.props.dispatch(getClients());

    toast.success(deck().subscription_card_remove_success, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 2000,
      pauseOnFocusLoss: false
    });
  }

  render() {

    const { canRemoveSubscription } = this.props;

    let startDate = "";
    if (this.props.subscription.startDate) {
      startDate = moment(this.props.subscription.startDate).format("MMM DD, YYYY");
    }

    let endDate = "";
    if (this.props.subscription.endDate) {
      endDate = moment(this.props.subscription.endDate).format("MMM DD, YYYY");
    }

    if (this.props.loading.includes(GET_DATA_SPECS)) {

      return (
        <LoadingCard key={"loading-1"} layoutStretch={this.props.layoutStretch}/>
      );
    }

    return (

      <MainContainer className="col-12">
        <CardContainer >

          <div className="row">
            <div className="col-8">
              <DateLabel>{startDate} - {endDate}</DateLabel>
            </div>

            {canRemoveSubscription && (
              <div className="col-4">
                <RemoveButton onClick={this.removeSubscription}>{deck().subscription_card_remove_button}</RemoveButton>
              </div>
            )}

          </div>

          <StyledLabeledContent>
            <LabeledContent
                label={deck().subscription_card_offering_title}
                content={
                  <div style={{ display: 'flex' }}>
                    <div>{this.props.subscription.offering.name}</div>
                    <ContentLink icon={IconNames.SHARE} onClick={this.showOffering} />
                  </div>
                }
              />
          </StyledLabeledContent>

          {this.getDataSpec() && (
            <StyledLabeledContent>
              <LabeledContent
                label={deck().subscription_card_data_spec_title}
                content={
                  <div style={{ display: 'flex' }}>
                    <div>{this.props.subscription.offering.supportedDataSpec}</div>
                    <ContentLink icon={IconNames.SHARE} onClick={this.showDataSpec} />
                  </div>
                }
              />
            </StyledLabeledContent>)
          }

          <StyledLabeledContent>
            <LabeledContent
              label={deck().subscription_card_pipeline_title}
              content={
                <div style={{ display: 'flex' }}>
                  <div>{this.props.client.name}</div>
                  <ContentLink icon={IconNames.SHARE} onClick={this.showPipeline} />
                </div>
              }
            />
          </StyledLabeledContent>

          <div className="row">
            <div className="col-6 ">
              <SupportLabel>{deck().subscription_card_region} {this.props.subscription.region}</SupportLabel>
            </div>

            <div className="col-6 text-right" >
              <SupportLabel>{deck().subscription_card_drop_frequency} {this.props.subscription.dropFrequency}</SupportLabel>
            </div>
          </div>

        </CardContainer>

      </MainContainer>

    )

  }
}

SubscriptionCard.propTypes = {
  subscription: PropTypes.shape({
    id: PropTypes.string,
    clientId: PropTypes.number,
    dataSpecVersion: PropTypes.number,
    version: PropTypes.number,
    dropFrequency: PropTypes.string,
    region: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    offering: PropTypes.object,
  }).isRequired,
  client: PropTypes.object,
  environment: PropTypes.object,
  layoutStretch: PropTypes.number,
  history: PropTypes.object,
  dataSpecs: PropTypes.object,
  loading: PropTypes.object,
  dispatch: PropTypes.func,
  canRemoveSubscription: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    loading: state.common.loading,
    dataSpecs: state.PipelineManager.pipeline.dataSpecs,
    canRemoveSubscription: canRemoveSubscription(state)
  }
}

export default connect(
  mapStateToProps
)(withRouter(SubscriptionCard));
