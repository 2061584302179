import React from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {Link, Route} from "react-router-dom";
import CreateBank from "./views/CreateBank/CreateBank";
import BankList from "./views/BankList/BankList";

class BankManager extends React.Component {

  mapRoutes() {

    if (this.props.router &&
      this.props.router.location &&
      this.props.router.location.pathname &&
      this.props.router.location.pathname === "/bank-manager") {

      return (<BankList/>);
    } else {

        // The rest of the possible routes will go here
        return(
          <Route path={'/bank-manager/create-bank'} component={CreateBank}/>
        );
    }

  }

  render() {

    // TODO: Refactor to be able to just pass in the `/bank-manager` instead of hardcoding
    return (
      <div>

        <ul>
          <li>
            <Link to={`/bank-manager/create-bank`}>Create Bank</Link>
          </li>
        </ul>

        {this.mapRoutes()}

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    router: state.router,
  }
}

BankManager.propTypes = {
  router: PropTypes.object,
};

export default connect(
  mapStateToProps,
)(BankManager);
