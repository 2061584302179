import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const SelectFilter = ({
  column: { filterValue = "", preFilteredRows, setFilter, id }
}) => {
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => options.add(row.values[id]));
    return [...options.values()];
  }, [id, preFilteredRows]);
  return (
    <select
      value={filterValue.toString()}
      onChange={e => setFilter(e.target.value || undefined)}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option.toString()}>
          {option.toString()}
        </option>
      ))}
    </select>
  );
}

SelectFilter.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.string,
    preFilteredRows: PropTypes.arrayOf(PropTypes.string),
    setFilter: PropTypes.func,
    id: PropTypes.string
  })
}

export default SelectFilter
