import axios from 'axios';

class ModelingAPI {

  static gatBankMapping() {

    return new Promise((resolve, callback) => {

      axios({
        method: 'get',
        url: MODEL_MANAGEMENT_API_URL + '/api/get_bank_mapping/' // eslint-disable-line no-undef
      })
        .then(function (content) {

          resolve(Object.assign([], content.data));

        })
        .catch(function(error) {
          // TODO: Refactor into a common error handling pattern.
          callback("got an error: " + JSON.stringify(error));
        });

    });
  }

  static getProductionTable(bankName, bankCode, vertical) {

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: MODEL_MANAGEMENT_API_URL + '/api/get_production_table/', // eslint-disable-line no-undef
        data: {
          "bankname": bankName,
          "bankcode": bankCode,
          "vertical": vertical
        }
      })
        .then(function (content) {

          resolve(Object.assign([], content.data));

        })
        .catch(error => {
          reject(error.response);
        });
    });
  }

  static getResultsTable(bankName, bankCode, vertical) {

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: MODEL_MANAGEMENT_API_URL + '/api/get_results_table/', // eslint-disable-line no-undef
        data: {
          "bankname": bankName,
          "bankcode": bankCode,
          "vertical": vertical
        }
      })
        .then(function (content) {

          resolve(Object.assign([], content.data));

        })
        .catch(error => {
          reject(error.response);
        });
    });
  }

  static getMonitoringTable(bankName, bankCode, vertical, waterfallShortName) {

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: MODEL_MANAGEMENT_API_URL + '/api/get_monitoring_table/', // eslint-disable-line no-undef
        data: {
          "bankname": bankName,
          "bankcode": bankCode,
          "vertical": vertical,
          "waterfall": waterfallShortName,
        }
      })
        .then(function (content) {

          resolve(Object.assign([], content.data));

        })
        .catch(error => {
          reject(error.response);
        })
    });
  }

  static getModelPVA(source, bankCode, waterfall, modelId, product, vertical) {

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: MODEL_MANAGEMENT_API_URL + '/api/get_model_pva/', // eslint-disable-line no-undef
        data: {
          "source": source,
          "bankcode": bankCode,
          "waterfall": waterfall,
          "modelid": modelId,
          "product": product,
          "vertical": vertical
        }
      })
        .then(function (content) {
          resolve(Object.assign([], content.data));
        })
        .catch(error => {
          reject(error.response);
        })
    })
  }

  // TODO: move out or rename the `monitorAPI`
  static postQueueModelSummary(bankCode, modelId) {

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: MODEL_MANAGEMENT_API_URL + '/api/queue_model_summary/', // eslint-disable-line no-undef
        data: {
          "bankcode": bankCode,
          "modelid": modelId,
        }
      })
        .then(function (content) {
          resolve(Object.assign([], content.data));
        })
        .catch(error => {
          reject(error.response);
        })
    })
  }

  static getQueueMonitor(jobKey) {

    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: MODEL_MANAGEMENT_API_URL + '/api/queue_monitor/' + jobKey, // eslint-disable-line no-undef
      })
        .then(function (content) {
          resolve(Object.assign([], content.data));
        })
        .catch(error => {
          reject(error.response);
        })
    })

  }
}

export default ModelingAPI;
