/* Languages are encoded using ISO 639-3 */

let copydeck;

export function deck() {
  if (!copydeck) {
    setLanguage('ENG');
  }
  return copydeck;
}

export function setLanguage(language) {
  let deckName = DECKS[language];
  if (deckName) {
    copydeck = require('./' + deckName).default;
  }
}

const DECKS = {
  'ENG': 'deck_eng.js',
};
