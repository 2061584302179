import React from "react";
import PropTypes from "prop-types";

import TableInput from "./TableInput";

const StringFilter = ({
  column: { filterValue = "", preFilteredRows, setFilter }
}) => (
  <TableInput
    type="text"
    value={filterValue}
    onChange={e => setFilter(e.target.value || undefined)}
    placeholder={`Search ${preFilteredRows.length} records...`}
  />
);

StringFilter.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.string,
    preFilteredRows: PropTypes.arrayOf(PropTypes.string),
    setFilter: PropTypes.func
  })
};

export default StringFilter;
