import React from "react";
import PropTypes from "prop-types";
import {deck} from "../../assets/copy/CopyDeck";
import styled from "styled-components";
import { FolderIcon } from "../Icons";
import color_palette from "../../assets/color/style-constants";

const MataDataRow = styled.div`

  padding-bottom: 10px;
  margin-bottom: 10px;
`;

const Icon = styled.img`
  padding-top: 18px;
  padding-left: 15px;

  max-height: 70px;
`;

const FolderIconContainer = styled.div`

  padding-top: 10px;
`;

const MetaDataItem = styled.div`
  padding-top: 18px;
  color: ${color_palette.light_header};
  font-size: 11px;
  font-weight: normal;
`;

const TitleLabel = styled.p`

  font-size: 17px;
  color: ${color_palette.dark_text};
  line-height: 17px;

  margin: 0px 0px 0px 0px;
`;

const SupportLabel = styled.p`

  font-size: 11px;

  color: ${color_palette.light_header};
  line-height: 11px;

  margin: 8px 0px 0px 0px;
`;

class TitleElement extends React.PureComponent {

  render() {

    let icon
    if (this.props.type === TITLE_ELEMENT_TYPE.CLIENT) {

      let imageUrl;
      switch (this.props.name) {
        case "BMO Canada":
          imageUrl = require('../../Modules/ClientManager/assets/img/logo/BMO_Canada.png');
          break;

        case "BMO Harris":
          imageUrl = require('../../Modules/ClientManager/assets/img/logo/BMO_Harris.png');
          break;

        case "BNZ":
          imageUrl = require('../../Modules/ClientManager/assets/img/logo/BNZ.png');
          break;

        case "CIBC":
          imageUrl = require('../../Modules/ClientManager/assets/img/logo/CIBC.png');
          break;

        case "Fifth Third":
          imageUrl = require('../../Modules/ClientManager/assets/img/logo/Fifth_Third.png');
          break;

        case "Iberia Bank":
          imageUrl = require('../../Modules/ClientManager/assets/img/logo/Iberia_Bank.png');
          break;

        case "Marcus":
          imageUrl = require('../../Modules/ClientManager/assets/img/logo/Marcus.png');
          break;

        case "Navy Federal":
          imageUrl = require('../../Modules/ClientManager/assets/img/logo/Navy_Federal.png');
          break;

        case "RBC":
          imageUrl = require('../../Modules/ClientManager/assets/img/logo/RBC.png');
          break;

        case "Sun Trust":
          imageUrl = require('../../Modules/ClientManager/assets/img/logo/SunTrust.png');
          break;

        case "TD":
          imageUrl = require('../../Modules/ClientManager/assets/img/logo/TD.png');
          break;

        case "USAA":
            imageUrl = require('../../Modules/ClientManager/assets/img/logo/USAA.png');
            break;

        case "US Bank":
        case "USBank":
          imageUrl = require('../../Modules/ClientManager/assets/img/logo/USBank.png');
          break;

        case "Wells Fargo":
          imageUrl = require('../../Modules/ClientManager/assets/img/logo/Wells_Fargo.png');
          break;

        case "Westpac":
          imageUrl = require('../../Modules/ClientManager/assets/img/logo/Westpac.png');
          break;

        default:
          imageUrl = require('../../Modules/ClientManager/assets/img/logo/bank.png')
      }

      if (imageUrl) {
        icon = <Icon src={imageUrl} alt="..." className="img-fluid"/>
      }
    } else if (this.props.type === TITLE_ELEMENT_TYPE.SPEC) {

      icon = (
        <FolderIconContainer>
          <FolderIcon height={"50px"} width={"50px"} fill={color_palette.highlighted_icon}/>
        </FolderIconContainer>
      );
    }

    let supportContent = null;
    if (this.props.supportName) {
      supportContent = (
        <SupportLabel>{this.props.type.supportName}{this.props.supportName}</SupportLabel>
      );
    }

    return (
      <MataDataRow className="row">
        <div className="col-6 text-right">
          {icon}
        </div>

        <MetaDataItem className="col-6 text-left">

          <TitleLabel>{this.props.name}</TitleLabel>
          {supportContent}

        </MetaDataItem>
      </MataDataRow>

    );
  }
}

TitleElement.propTypes = {
  name: PropTypes.string.isRequired,
  supportName: PropTypes.string,
  type: PropTypes.object.isRequired,
};

export default TitleElement;

export const TITLE_ELEMENT_TYPE = {
  CLIENT: {
    supportName: deck().client_card_code,
  },
  SPEC: {
    supportName: deck().view_data_spec_card_support_item,
  }
};
