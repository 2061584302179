import React from "react";
import PropTypes from "prop-types";
import { Classes, Dialog } from "@blueprintjs/core";
import styled from "styled-components";

const DialogBody = styled.div`
  overflow: scroll;
`;

class NomisDialog extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { body, footer, isOpen, onCancelAction, style, title } = this.props;

    return (
      <Dialog
        onClose={onCancelAction}
        title={title}
        isOpen={isOpen}
        autoFocus
        canEscapeKeyClose
        canOutsideClickClose
        enforceFocus
        usePortal
        style={style}
      >
        <style type="text/css">{`
          .bp3-portal {
            z-index: 10000;
          }
        `}</style>
        <DialogBody className={Classes.DIALOG_BODY}>{body}</DialogBody>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>{footer}</div>
        </div>
      </Dialog>
    );
  }
}

NomisDialog.propTypes = {
  body: PropTypes.node,
  footer: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  onCancelAction: PropTypes.func.isRequired,
  style: PropTypes.object,
  title: PropTypes.string,
};

export default NomisDialog;
