import * as types from '../actions/actionTypes';

export default function clientReducer(state = [], action) {

  switch (action.type) {

    case types.GET_CLIENTS: {

      return {
        ...state,
        clients: action.result,
      }
    }

    case types.GET_OFFERINGS: {

      return {
        ...state,
        offerings: action.result
      }
    }

    case types.POST_SUBSCRIPTION: {

      return {
        ...state,
        postedSubscription: action.result
      }
    }

    default:
      return { ...state };

  }
}
