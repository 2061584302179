import React from "react";
import PropTypes from "prop-types";

const EmptyIcon = ({ height, width, fill, ...rest }) => (

  <svg width={width} height={height} viewBox="0 0 200 138" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g fill={fill} fillRule="nonzero">
      <path d="M82.453 85.766a3.125 3.125 0 0 0 4.422 0L100 72.64l13.125 13.125a3.127 3.127 0 0 0 4.422-4.422l-13.125-13.125 13.125-13.125a3.127 3.127 0 0 0-4.422-4.422L100 63.797 86.875 50.672a3.127 3.127 0 0 0-4.422 4.422l13.125 13.125-13.125 13.125a3.125 3.125 0 0 0 0 4.422z"/>
      <path d="M198.61 25.531l-37.5-25A3.125 3.125 0 0 0 159.374 0H3.125A3.125 3.125 0 0 0 .797 1.063 3.125 3.125 0 0 0 0 3.124v106.25a3.12 3.12 0 0 0 1.39 2.594l37.5 25c.524.323 1.12.506 1.735.531h156.25c1.726 0 3.125-1.4 3.125-3.125V28.125a3.12 3.12 0 0 0-1.39-2.594zM162.5 8.97L186.547 25H162.5V8.969zM37.5 128.53L13.453 112.5H37.5v16.031zm0-22.281H6.25V8.969L37.5 29.797v76.453zm-24.047-100H156.25V25H41.578L13.453 6.25zm142.797 25v75H43.75v-75h112.5zm-112.5 100V112.5h114.672l28.125 18.75H43.75zm150-2.719l-31.25-20.828V31.25h31.25v97.281z"/>
    </g>
  </svg>
);

EmptyIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
};

EmptyIcon.defaultProps = {
  fill: "#000",
  height: "200",
  width: "200"
};

export default EmptyIcon
