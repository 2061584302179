import React from "react";
import PropTypes from "prop-types";

const CheckIcon = ({ width, height, fill, ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 200 200" {...rest}>
    <g fill={fill} fillRule="nonzero">
      <path d="M100 0C44.771 0 0 44.771 0 100c0 55.227 44.771 100 100 100 55.227 0 100-44.773 100-100C200 44.771 155.227 0 100 0zm0 188.889c-49.093 0-88.889-39.798-88.889-88.889 0-49.093 39.796-88.889 88.889-88.889 49.091 0 88.889 39.796 88.889 88.889 0 49.091-39.798 88.889-88.889 88.889z" />
      <path d="M145.662 60.42c-3.869-2.989-9.48-2.269-12.469 1.598l-43.5 56.295-22.864-30c-2.965-3.889-8.569-4.644-12.458-1.682-3.889 2.962-4.644 8.569-1.682 12.458l29.633 38.884c2.011 2.64 5.238 3.796 8.322 3.365 2.418-.191 4.752-1.327 6.34-3.385L147.26 72.89c2.989-3.869 2.269-9.48-1.598-12.469z" />
    </g>
  </svg>
);

CheckIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
};

CheckIcon.defaultProps = {
  fill: "#97c66b",
  height: "200",
  width: "200"
};

export default CheckIcon;
