import * as types from '../actions/actionTypes';

export default function specEditReducer(state = [], action) {

  switch (action.type) {

    case types.INIT_SPEC_EDIT: {

      // TODO: Swap to an array of items that are being edited instead.
      return {
        ...state,
        editingSpec: action.spec,
      }
    }

    case types.SAVE_FILE: {

      // TODO: Swap to an array of items that are being edited instead.
      return {
        ...state,
        editingSpec: action.spec,
      }
    }

    case types.REMOVE_FILE_FROM_SPEC:
      return {
        ...state,
        editingSpec: action.spec
      }

    default:
      return { ...state };
  }
}
