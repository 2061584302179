import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Text from "./Text";
import color_palette from "../../assets/color/style-constants";

const Content = styled(Text)`
  font-size: 15px;
  padding: 4px;
`;

const Label = styled(Text)`
  font-size: 13px;
  color: ${color_palette.light_header};
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const LabeledContent = ({ content, label }) => (
  <FlexColumn>
    <Label>{label}</Label>
    {typeof content === "string" ? <Content>{content}</Content> : content}
  </FlexColumn>
);

LabeledContent.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired
};

LabeledContent.defaultProps = {
  label: "Label",
  content: "Content"
};

export default LabeledContent;
