
export const GET_ENVIRONMENTS = 'GET_ENVIRONMENTS';

// Editing Specs
export const INIT_SPEC_EDIT = 'INIT_SPEC_EDIT';
export const SAVE_FILE = 'SAVE_FILE';
export const REMOVE_FILE_FROM_SPEC = 'REMOVE_FILE_FROM_SPEC';

// Pricing Config
export const GET_PRICING_CONFIG_FILE = 'GET_PRICING_CONFIG_FILE';
export const POST_PRICING_CONFIG_FILE = 'POST_PRICING_CONFIG_FILE';
export const POST_ACTIVE_PRICING_CONFIG_FILE = 'POST_ACTIVE_PRICING_CONFIG_FILE';
export const POST_RUN_PIPELINE = 'POST_RUN_PIPELINE';

// Specs
export const GET_DATA_SPECS = 'GET_DATA_SPECS';
export const GET_PRICING_SPECS = 'GET_PRICING_SPECS';
export const POST_SPEC_VERSION = 'POST_SPEC_VERSION';

// Pipelines
export const PUT_PIPELINE = 'PUT_PIPELINE';
export const GET_PIPELINE_DROP_FILE_ERRORS = "GET_PIPELINE_DROP_FILE_ERRORS";
export const GET_PIPELINE_DROPS = "GET_PIPELINE_DROPS";
