import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import color_palette from "../../assets/color/style-constants";

const color = color_palette.default_text;
const fontFamily = 'Avenir, sans-serif';
const fontSize = '10px';
const lineHeight = '1.5';

export const StyledText = styled.div`
  color: ${props => props.color || color};
  font-family: ${props => props.fontFamily || fontFamily};
  font-size: ${props => props.fontSize || fontSize};
  line-height: ${props => props.lineHeight || lineHeight}
`;

const Text = ({ children, ...rest }) => (
  <StyledText {...rest}>{children}</StyledText>
);

Text.propTypes = {
  children: PropTypes.string,
  color: PropTypes.string,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string
};

export default Text
