import React from 'react';
import {Route} from "react-router-dom";
import PropTypes from "prop-types";

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
};

export const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route {...rest} render={routeProps => {
      return renderMergedProps(component, routeProps, rest);
    }}/>
  );

};

PropsRoute.propTypes = {
  component: PropTypes.object,
};


export const wrapHOC = (WrappedComponent) => (props) => ( // eslint-disable-line react/display-name
  <WrappedComponent {...props} />
);
