import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

const StyledCellRenderer = styled.div`
  /* flex-basis: 100%; */
`;

const StyledHeaderCell = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 25px;
  padding: 2px;
  width: auto;
`;

const StyledIcon = styled(Icon)`
  margin: 0 4px;
`;

const getSortIcon = ({ isSorted, isSortedDesc }) => {
  if (!isSorted) {
    return IconNames.SORT
  }
  if (isSortedDesc) {
    return IconNames.SORT_DESC
  }
  return IconNames.SORT_ASC
}

const getGroupByIcon = ({ isGrouped }) => {
  if (isGrouped) {
    return IconNames.TH_DISCONNECT
  }
  return IconNames.PIVOT_TABLE
}

const SortableHeaderCell = ({ column }) => (
  <StyledHeaderCell>
    <StyledCellRenderer>
      {column.render("Header")}
    </StyledCellRenderer>
    <StyledIcon
      tabIndex="0"
      {...column.getSortByToggleProps()}
      icon={getSortIcon(column)}
    />
    {column.canGroupBy ? <StyledIcon
      tabIndex="0"
      {...column.getGroupByToggleProps()}
      icon={getGroupByIcon(column)}
    /> : null}
  </StyledHeaderCell>
);

SortableHeaderCell.propTypes = {
  column: PropTypes.object
};

export default SortableHeaderCell;
