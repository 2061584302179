import styled from 'styled-components';

import color_palette from '../../assets/color/style-constants'

const { table_border, table_even_row, table_header, table_header_text } = color_palette;

export const StyledTableButton = styled.button`
  background: transparent;
  border-radius: 4px;
  border: 1px solid ${color_palette.white_text};
  color: ${color_palette.white_text};
  font-size: 10px;
  font-weight: 900;
  height: 80%;
  padding: 2px 4px;
  width: auto;
`;

export const StyledTableControl = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  padding: 2px;
`;

export const Table = styled.table`
  border-collapse: collapse;
  border: 1px solid ${table_border};
  width: 100%;
`;

export const TR = styled.tr`
  :nth-child(even) {
    background: ${table_even_row};
  }
`;

export const TD = styled.td`
  border: 1px solid ${table_border};
  font-size: 13px;
  padding: 3px 2px;
  text-align: left;
`;

export const THead = styled.thead`
  background: ${table_header};
  border-bottom: 5px solid ${table_border};
  text-align: center;
`;

export const TH = styled.th`
  border-left: 2px solid ${table_border};
  color: ${table_header_text};
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  :first-child {
    border-left: none;
  }
  vertical-align: bottom;
  width: auto;
`;
