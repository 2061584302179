/* eslint-disable no-unused-vars */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useExpanded,
  useFilters,
  useGroupBy,
  useSortBy
} from "react-table";
import styled from "styled-components";

import {
  AggregatedCell,
  NumberFilter,
  SelectFilter,
  StringFilter,
  TableElements,
  SortableHeaderCell
} from "../../../../components/Table";
import { fileURLToPath } from "url";
import { parse } from "path";

const { StyledTableControl, Table, TR, TD, THead, TH } = TableElements;

const formatDropData = drops =>
  // eslint-disable-next-line no-unused-vars
  Object.entries(drops).reduce((acc, [_, { files }]) => {
    return acc.concat(
      files.map(
        ({
          archived,
          bucketName,
          contentValidationResult,
          dropKey,
          errors,
          fileName: { name, extension },
          filePath,
          fileSetValidatedDate,
          fileSize,
          fileSpecName,
          id,
          nameValidationResult,
          numErrorLines,
          numErrors,
          numLines,
          staged,
          validationState,
          version,
          ...rest
        }) => ({
          dropKey,
          "Filespec": fileSpecName,
          Filename: `${name}${extension}`,
          "Fileset Validated": fileSetValidatedDate,
          "Validation State": validationState,
          "Filename Validation": nameValidationResult,
          "Content Validation": contentValidationResult,
          staged,
          "File Bytes": fileSize,
          "File Rows": numLines,
          "Total Errors": numErrors,
          "Rows with Errors": numErrorLines,
          "S3 Bucket": bucketName,
          "S3 File Path": filePath,
          ...rest
        })
      )
    );
  }, []);

const getFilterType = name => {
  if (["File Bytes", "File Rows", "Total Errors", "Rows with Errors"].includes(name)) {
    return { Filter: "" };
  }
  if (
    [
      "Validation State",
      "Filename Validation",
      "Content Validation",
      "staged"
    ].includes(name)
  ) {
    return { Filter: SelectFilter };
  }
  return { Filter: StringFilter };
};

const getColumns = drop =>
  Object.keys(drop).map(camelCasedName => {
    const spacedName = camelCasedName.split(/(?=[A-Z])/).join(" ");
    const prettyName = spacedName.charAt(0).toUpperCase() + spacedName.slice(1);
    return {
      Header: prettyName,
      accessor: camelCasedName,
      aggregate: "count",
      Aggregated: AggregatedCell,
      expander: true,
      sortType: "basic",
      ...getFilterType(camelCasedName)
    };
  });

const PipelineDropTable = ({ drops }) => {
  const data = useMemo(() => formatDropData(drops), []);
  const columns = useMemo(() => getColumns(data[0]), []);

  const filterTypes = useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      filterTypes
    },
    useGroupBy,
    useExpanded,
    useFilters,
    useSortBy
  );

  return (
    <Table {...getTableProps()}>
      <THead>
        {headerGroups.map((headerGroup, headerGroupIndex) => (
          <TR key={headerGroupIndex} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, columnIndex) => (
              <TH key={columnIndex} {...column.getHeaderProps()}>
                <SortableHeaderCell column={column} />
                <StyledTableControl>
                  {column.canFilter ? column.render("Filter") : null}
                </StyledTableControl>
              </TH>
            ))}
          </TR>
        ))}
      </THead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          prepareRow(row);
          return (
            <TR key={rowIndex} {...row.getRowProps()}>
              {row.cells.map((cell, cellIndex) => (
                <TD key={cellIndex} {...cell.getCellProps()}>
                  {cell.isAggregated ? (
                    <div {...row.getExpandedToggleProps()}>
                      {cell.render("Aggregated")}
                    </div>
                  ) : cell.isRepeatedValue ? (
                    ""
                  ) : (
                    <>
                      {["File  Bytes", "File  Rows", "Total  Errors", "Rows with  Errors"].includes(cell.column.Header) ? parseInt(cell.value).toLocaleString() : cell.render("Cell")}{" "}
                      {row.subRows.length > 0
                        ? `(${row.subRows.length} rows)`
                        : ""}
                    </>
                  )}
                </TD>
              ))}
            </TR>
          );
        })}
      </tbody>
    </Table>
  );
};

PipelineDropTable.propTypes = {
  drops: PropTypes.arrayOf(PropTypes.object)
};

export default PipelineDropTable;
