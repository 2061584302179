import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { CancelIcon, CheckIcon, EditIcon } from "../Icons";
import color_palette from "../../assets/color/style-constants";

// TODO: Accessibility should not use outline: none
const EditableContent = styled.textarea`
  outline: none; 
  color: ${color_palette.dark_text};
  font-size: 15px;
  line-height: 1.5;
  padding: 4px;
  &::-webkit-input-placeholder {
    font-size: 15px;
  }
  border: 1px solid #aaa;
  border-radius: 4px;
  flex: 1 1 auto;
`;

// TODO: Accessibility should not use outline: none
const Links = styled.div`
  height: auto;
  min-height: 30px;
  outline: none;
  color: ${color_palette.dark_text};
  font-size: 15px;
  line-height: 1.5;
  padding: 4px;
  border: 1px solid #aaa;
  border-radius: 4px;
  flex: 1 1 auto;
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
`;

const buttonStyle = `
  display: block;
  cursor: pointer;
  margin-left: 4px;
`;

const EditButton = styled(EditIcon)`
  ${buttonStyle}
`;

const UpdateButton = styled(CheckIcon)`
  ${buttonStyle}
`;

const CancelButton = styled(CancelIcon)`
  ${buttonStyle}
`;

const createMarkup = content => {
  const stripped = content.replace(
    /<(form|script|object|input)[^>]*>(?:(?!<\/(form|script|object|input)>)[^])*<\/(form|script|object|input)>/g,
    ""
  );
  return {
    __html: stripped
  };
};

const EditableLink = ({ canEdit, content, updateContent }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedContent, setUpdatedContent] = useState(undefined);

  const update = () => {
    if (updatedContent === content) {
      return;
    }
    updateContent(updatedContent);
  };

  return (
    <StyledDiv>
      {!isEditing ? (
        <>
          <Links
            dangerouslySetInnerHTML={createMarkup(
              typeof updatedContent === "string"
                ? updatedContent
                : content || "Add links..."
            )}
          />
          {canEdit && (<EditButton
            onClick={() => setIsEditing(true)}
            height="20px"
            width="20px"
          />)}
        </>
      ) : (
        <>
          <EditableContent
            ref={input => input && input.focus()}
            defaultValue={content}
            placeholder="https://quicksight.aws.com/..."
            onChange={e => setUpdatedContent(e.target.value)}
          />
          <UpdateButton
            height="24px"
            width="24px"
            onClick={() => {
              setIsEditing(false);
              update();
            }}
          />
          <CancelButton
            height="24px"
            width="24px"
            onClick={() => {
              setIsEditing(false);
              setUpdatedContent(undefined);
            }}
          />
        </>
      )}
    </StyledDiv>
  );
};

EditableLink.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  updateContent: PropTypes.func,
  content: PropTypes.string
};

export default EditableLink;
