import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {deck} from "../../../../assets/copy/CopyDeck";
import {LayoutStretch} from "../../../../utils/UIUtils";
import {CSVLink} from "react-csv";
import Button, { BUTTON_STATE } from "../../../../components/Button";
import { Tooltip } from "@blueprintjs/core";
import color_palette from "../../../../assets/color/style-constants";

const CardContainer = styled.div`
  padding-top: 15px;
  padding-bottom: 5px;

  border-radius: 6px;
  border: solid 1px #e9eff4;
  margin-bottom: 20px;

  box-shadow: -2px 2px 4px 0 ${color_palette.default_box_shadow};
`;

const VersionRow = styled.div`
  border-bottom: solid 1px #e9eff4;
`;

const SupportRow = styled.div`
  margin-top: 10px;
`;

const SupportLabel = styled.p`
  font-size: 14px;
  color: ${color_palette.light_header};

  padding-top: 5px;
  margin-left: 15px;
`;

const TitleLabel = styled.p`

  font-size: 14px;
  color: ${color_palette.dark_text};
  text-align: center;

  cursor: pointer;
`;

const DownloadButtonContainer = styled.div`
  margin-right: 15px;
`;

class PricingFileVersionCard extends React.PureComponent {

  constructor(props) {
    super(props);

    this.selectCard = this.selectCard.bind(this);
  }

  selectCard() {
    this.props.cardSelected(this.props.file);
  }

  render() {
    const { canSetActive } = this.props;

    var colSize = "col-12";

    switch (this.props.layoutStretch) {

      case LayoutStretch.PRIMARY_FULL: {
        colSize = "col-12 col-sm-6 col-md-4";
        break;
      }

      case LayoutStretch.PRIMARY_HALF: {
        colSize = "col-12";
        break;
      }

      case LayoutStretch.PRIMARY_ONE_THIRD: {
        colSize = "col-12";
        break;
      }

      case LayoutStretch.PRIMARY_TWO_THIRD: {
        colSize = "col-12";
        break;
      }
    }

    let active;
    if (this.props.active) {
      active = (
        <Tooltip content={deck().file_version_pricing_card_active_version_tooltip}>
          <SupportLabel>{deck().file_version_pricing_card_active_version}</SupportLabel>
        </Tooltip>
      );
    }

    const SetActiveButton = styled(Button)`
      font-size: 13px;
      height: 31px;
      width: 100px;
      padding: 5px 2px;
    `;

    return (
      <div className={colSize}>

        <CardContainer >

          <VersionRow >
            <TitleLabel>{this.props.file.metadata.fileName.name}</TitleLabel>
          </VersionRow>

          <div className="row">
            <div className="col-12 text-right">
              <DownloadButtonContainer>

                <CSVLink
                  className="downloadButton"
                  data={this.props.file.content}
                  filename={this.props.file.metadata.fileName.name + ".csv"}>{deck().file_version_pricing_card_download}</CSVLink>
              </DownloadButtonContainer>
            </div>
          </div>

          <SupportRow className="row">

            <div className="col-6 align-self-center">
              {active}
            </div>

            {!active && canSetActive && (
              <div className="col-6">
                <SetActiveButton
                  buttonName="set_active_button"
                  onClick={this.selectCard}
                  state={this.props.loading
                    ? BUTTON_STATE.LOADING
                    : BUTTON_STATE.ACTIVE
                  }
                >
                  {deck().file_version_pricing_card_set_active}
                </SetActiveButton>
              </div>
            )}

          </SupportRow>


        </CardContainer>
      </div>
    );
  }

}

PricingFileVersionCard.propTypes = {
  canSetActive: PropTypes.bool.isRequired,
  file: PropTypes.shape({
    metadata: PropTypes.object,
    content: PropTypes.string,
  }),
  layoutStretch: PropTypes.number,
  active: PropTypes.bool,
  pipeline: PropTypes.object,
  fileName: PropTypes.object,
  cardSelected: PropTypes.func,
  selected: PropTypes.bool,
  loading: PropTypes.bool,
  errors: PropTypes.object,
};

export default PricingFileVersionCard;
