import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Callout, Collapse, Divider, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { deck } from "../../../../assets/copy/CopyDeck";

import Button, { BUTTON_STATE } from "../../../../components/Button";
import LoadingElement from "../../../../components/Loading/LoadingElement";
import NomisDialog from "../../../../components/Dialog/NomisDialog";
import { Text } from "../../../../components/Typography";

import ViewFileSchema from "../ViewFileSchema/ViewFileSchema";
import {toast} from "react-toastify";
import color_palette from "../../../../assets/color/style-constants";

const ConfirmButton = styled(Button)`
  width: 192px;
  height: 32px;
  border-radius: 2px;
  border: solid 1px rgba(77, 161, 255, 0.7);
  background-color: #4da1ff;
  color: white;
  cursor: pointer;
  padding: 4px 32px;
`;

const CancelButton = styled(Button)`
  width: auto;
  height: 32px;
  border-radius: 2px;
  border: solid 1px rgba(77, 161, 255, 0.7);
  background-color: transparent;
  color: #4da1ff;
  cursor: pointer;
  margin-right: 16px;
  padding: 4px 32px;
`;

const File = styled.div`
  height: 32px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const FileList = styled.div``;

const Header = styled(Text)`
  fontsize: 24px;
  padding: 8px;
`;

const StyledIcon = styled(Icon)`
  color: #323c47;
  margin-right: 8px;
  cursor: pointer;
`;

const StyledText = styled(Text)`
  font-size: 14px;
  font-weight: 400;
`;

const CustomInput = styled.input`

  display: block;
	font-size: 16px;
	font-weight: regular;
	line-height: 1.3;
	padding: .6em 1.4em .5em .8em;
	width: 97%;
	max-width: 100%; 
	box-sizing: border-box;
	
	margin: 0;
	border: ${props => props.error ? "2px solid " + color_palette.cancel_item : "1px solid #aaa"};
	border-radius: 4px;
	appearance: none;
	
	margin-left: 10px;
`;

const saveFileFunction = (callback, editingSpec, values, saveFileForSpec) => {

  if (!editingSpec) {
    return;
  }

  let fileSchema;
  try {

    fileSchema = JSON.parse(values.fileSchemaField);
  } catch (e) {

    if (e instanceof SyntaxError) {

      toast.error(e.message, {
        position: toast.POSITION.BOTTOM_CENTER
      });

    } else {

      toast.error(deck().file_specification_invalid_json, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }

  if (!fileSchema) {
    return;
  }

  let file = {
    name: values.nameField,
    fileNamePattern: {
      expression: values.filePatternField
    },
    fileType: values.fileTypeField,
    fileContentSchema: fileSchema,
  };

  saveFileForSpec(file, editingSpec);
  callback();
};

const renderBody = (
  currentSpecVersion,
  editingFileName,
  removeFileFromSpec,
  setEditingFileName,
  setSpecDescription,
  saveFileForSpec
) => {
  if (!currentSpecVersion) {
    return <LoadingElement />;
  }

  const {
    new_spec_version_modal_add_new_file,
    new_spec_version_modal_header,
    new_spec_version_modal_warning
  } = deck();
  const { dataFileSpecifications, name, version } = currentSpecVersion;
  const isEditingNewFile = editingFileName === "new-file";
  return (
    <div>
      <Callout intent={Intent.WARNING}>
        <StyledText>{`${new_spec_version_modal_warning} ${name} ${version}`}</StyledText>
      </Callout>
      <Header fontSize="24px">Description</Header>

      <CustomInput
        type="text"
        name="dataSpecVersionDescription"
        placeholder="Data Spec Version Description"
        onChange={(event) => setSpecDescription(event.target.value)}/>


      <Header fontSize="24px">{new_spec_version_modal_header}</Header>
      <FileList>

        {dataFileSpecifications.map((specification, index) => {
          const isOpen = specification.name === editingFileName;
          return (
            <div key={`${specification.name}_${index}`}>
              <File
                onClick={() => {

                  if (specification.name === editingFileName) {
                    let callback = () => {};
                    saveFileFunction(callback, specification, specification, saveFileForSpec);
                  }

                  setEditingFileName(
                    specification.name === editingFileName
                      ? ""
                      : specification.name
                  )
                }}
              >
                <StyledText>{specification.name}</StyledText>
                <div>
                  <StyledIcon
                    icon={isOpen ? IconNames.CHEVRON_UP : IconNames.EDIT}
                  />
                  <StyledIcon
                    icon={IconNames.TRASH}
                    onClick={() => removeFileFromSpec(specification.name)}
                  />
                </div>
              </File>
              <Divider />
              <Collapse isOpen={isOpen}>
                <ViewFileSchema
                  onSubmitCallback={() => {
                    setEditingFileName("");
                  }}
                  handleSubmit={(callback, editingSpec, values) => saveFileFunction(callback, editingSpec, values, saveFileForSpec)}
                  specification={specification}
                />
                <Divider />
              </Collapse>
            </div>
          );
        })}


        <div>
          <File
            onClick={() =>
              setEditingFileName(isEditingNewFile ? "" : "new-file")
            }
          >
            <StyledText>{new_spec_version_modal_add_new_file}</StyledText>
            <StyledIcon
              icon={isEditingNewFile ? IconNames.CHEVRON_UP : IconNames.ADD}
            />
          </File>
          <Divider />
          <Collapse isOpen={isEditingNewFile}>
            <ViewFileSchema
              onSubmitCallback={() => {
                setEditingFileName("");
              }}
              handleSubmit={(callback, editingSpec, values) => saveFileFunction(callback, editingSpec, values, saveFileForSpec)}
              specification={{ name: "new-file", fileType: "CSV" }}
            />
            <Divider />
          </Collapse>
        </div>
      </FileList>
    </div>
  );
};

const renderFooter = (onCancel, onSave, isSavingSpec) => {
  const DIALOG_ID="new-spec_version-dialog";
  return (
    <>
      <CancelButton buttonName={`${DIALOG_ID}_cancel`} onClick={onCancel}>Cancel</CancelButton>
      <ConfirmButton
        buttonName={`${DIALOG_ID}_confirm`}
        onClick={onSave}
        state={isSavingSpec ? BUTTON_STATE.LOADING : BUTTON_STATE.ACTIVE}
      >
        Save new version
      </ConfirmButton>
    </>
  );
};

const NewSpecVersionModal = ({
  currentSpecVersion,
  editingFileName,
  isOpen,
  isSavingSpec,
  onCancel,
  onSave,
  removeFileFromSpec,
  setEditingFileName,
  setSpecDescription,
  saveFileForSpec
}) => {
  const { new_spec_version_modal_title } = deck();

  return (
    <NomisDialog
      style={{
        width: "calc(100% - 20px)",
        height: "calc(100vh - 20px)",
        backgroundColor: "#FFF",
        margin: 0
      }}
      isOpen={isOpen}
      title={new_spec_version_modal_title}
      onCancelAction={onCancel}
      body={renderBody(
        currentSpecVersion,
        editingFileName,
        removeFileFromSpec,
        setEditingFileName,
        setSpecDescription,
        saveFileForSpec
      )}
      footer={renderFooter(onCancel, onSave, isSavingSpec)}
    />
  );
};

NewSpecVersionModal.propTypes = {
  currentSpecVersion: PropTypes.object,
  editingFileName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isSavingSpec: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  removeFileFromSpec: PropTypes.func.isRequired,
  setEditingFileName: PropTypes.func.isRequired,
  setSpecDescription: PropTypes.func.isRequired,
  saveFileForSpec: PropTypes.func.isRequired,
};

export default NewSpecVersionModal;
