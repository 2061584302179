import React from 'react';
import PropTypes from 'prop-types';
import ReactPiwik from "react-piwik";
import TopBar from "../../components/TopBar/TopBar";
import {Redirect, Route, Switch} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {MODULES} from "../../Modules/config";
import { deck } from "../../assets/copy/CopyDeck";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import styled from "styled-components";
import Footer from "../../components/Footer/Footer";
import Credits from "../Common/Credits";
import color_palette from "../../assets/color/style-constants";
import { addUserGroups } from "../../actions/commonActions";

import Amplify, { Auth } from "aws-amplify";
import { Authenticator, Greetings } from "aws-amplify-react";

import awsconfig from "../../utils/aws-exports";
import VersionChecker from './VersionChecker';
// eslint-disable-next-line no-undef
JSON.parse(AUTHORIZE_USER) && Amplify.configure(awsconfig);

const MainContainer = styled.div`
  background-color: ${color_palette.desktop_background};
`;

class Desktop extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedModule: this.getSelectedModuleBasedOnPath(),
      authState: null,
      authUser: null
    };
  }

  componentDidUpdate(prevProps) {

    this.updateSelectedModuleBasedOnRoute(prevProps);
  }

  updateSelectedModuleBasedOnRoute(prevProps) {

    // Guard and exit
    if (!this.props.router &&
      !this.props.router.location &&
      !prevProps.router &&
      !prevProps.router.location) {

      return
    }

    if (prevProps.router.location === this.props.router.location) {
      return
    }

    this.setState({ selectedModule: this.getSelectedModuleBasedOnPath() });

  }

  getSelectedModuleBasedOnPath() {
    const { pathname } = this.props.router.location;

    return MODULES.find(({ route }) => pathname.includes(route));
  }

  renderRoutes() {
    return (
      <div>
        <Switch>
          {MODULES.map(({ component, id, route }) => <Route path={route} component={component} key={id} />)}
          <Route path="/credits" component={Credits}/>
          <Route path="/" component={() => <Redirect to="/client-manager/view-clients" />}/>
          <Route component={() => <Redirect to="/client-manager/view-clients" />}/>
        </Switch>
      </div>
    );
  }

  showLoggedIn() {

    let enabledModules = MODULES.filter(module => {
      return module.enabled;
    });

    return(
      <MainContainer >

        <ToastContainer />
        <TopBar
          selectedModule={this.state.selectedModule}
          modules={enabledModules}
          user={this.state.authUser}
        />

        {this.renderRoutes()}

        <Footer />
        <VersionChecker />
      </MainContainer>
    );
  }

  render() {
    const { addUserGroups } = this.props;

    if (!JSON.parse(AUTHORIZE_USER)) {// eslint-disable-line no-undef
      addUserGroups("sputnik-admin")
      return this.showLoggedIn()
    }

    return (
      <Authenticator
        includeGreetings={true}
        signUpConfig={mySignUpConfig}
        hide={[Greetings]}
        onStateChange={authState => {
          Auth.currentAuthenticatedUser()
            .then(authUser => {
              this.setState({
                authState: authState,
                authUser: authUser
              });

              addUserGroups(
                authUser.signInUserSession.accessToken.payload["cognito:groups"]
              );
              ReactPiwik.push(["setUserId", authUser.username]);
            })
            .catch(err => {
              console.log("No current Auth User: " + err); // eslint-disable-line no-console

              this.setState({
                authState: authState,
                authUser: null
              });
            });
        }}
      >
        <>
          {this.state.authState === "signedIn" ? this.showLoggedIn() : <div />}
        </>
      </Authenticator>
    );
  }
}

const { sign_up_header, sign_up_first_name, sign_up_last_name } = deck();

const mySignUpConfig = {
  header: sign_up_header,
  hideAllDefaults: false,
  defaultCountryCode: '1',
  signUpFields: [
    {
      label: sign_up_first_name,
      key: 'given_name',
      required: true,
      displayOrder: 5,
      type: 'string'
    },
    {
      label: sign_up_last_name,
      key: 'family_name',
      required: true,
      displayOrder: 6,
      type: 'string'
    }
  ]
};

Desktop.propTypes = {
  addUserGroups: PropTypes.func.isRequire,
  dispatch: PropTypes.func,
  router: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    router: state.router
  };
}

const mapDispatchToProps = { addUserGroups };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Desktop);
