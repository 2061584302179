import * as types from './actionTypes.js';

export function getEnvironment(onError) {

  return function(dispatch) {

    let apigClient;
    if (window.apigClientFactory && process.env.NODE_ENV === 'production') {

      apigClient = window.apigClientFactory.newClient();
    } else if (window.apigClientFactory_staging && process.env.NODE_ENV === 'staging') {

      apigClient = window.apigClientFactory_staging.newClient();
    } else if (window.apigClientFactory_development) {

      apigClient = window.apigClientFactory_development.newClient();
    } else {

      return;
    }

    var pathParams    = {};
    var pathTemplate = '/environment';
    var method = 'GET';
    var additionalParams = {};
    var body = {};

    return apigClient.environmentGet(pathParams, pathTemplate, method, additionalParams, body)
      .then(function(result) {

        if (result.data &&
          result.data.result &&
          result.data.statusMessage === "success") {

          dispatch(loadEnvironmentSuccess(result.data.result));

        } else {

          console.log('Did not get success'); // eslint-disable-line no-console
        }

      })
      .catch(function(error){

        console.log("Got some error: " + JSON.stringify(error)); // eslint-disable-line no-console

        if (onError) {
          onError(error);
        }

      });
  }
}

export function loadEnvironmentSuccess(result) {
  return { type: types.GET_GLOBAL_ENVIRONMENT, result: result }
}

export function createBank(bankName, vertical) {

  alert('Got here');
  return function(dispatch) {
    dispatch(
      { type: types.CREATE_BANK, bankName: bankName, vertical: vertical }
    )
  }
}
