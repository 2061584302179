import React from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {createBank} from "../../actions/bankActions";

class CreateBank extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      bankName: '',
      bankVertical: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate() {

    // console.log("testBankReducer: " + JSON.stringify(this.props.testBankReducer));
    // console.log("bankReducer: " + JSON.stringify(this.props.bankReducer));
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.dispatch(
      createBank(this.state.bankName, this.state.bankVertical));
  }

  render() {

    return (
      <div>
        <h2>Create New Bank</h2>

        <form onSubmit={this.handleSubmit}>
          <div className="form-group">

            <label htmlFor="bankNameInput">Bank Name</label>
            <input
              type="text"
              className="form-control"
              id="bankNameInput"
              aria-describedby="bankNameInput"
              placeholder="Enter Bank Name"
              name="bankName"
              value={this.state.bankName}
              onChange={this.handleChange} />
          </div>

          <div className="form-group">
            <label htmlFor="verticalInput">Vertical</label>
            <input
              type="text"
              className="form-control"
              id="verticalInput"
              placeholder="Enter a Vertical"
              name="bankVertical"
              value={this.state.bankVertical}
              onChange={this.handleChange}/>
          </div>

          <div className="form-check">
            <input type="checkbox" className="form-check-input" id="bankNameConfirmation"/>
            <label className="form-check-label" htmlFor="bankNameConfirmation">Please verify the information was entered correctly</label>
          </div>

          <button type="submit" className="btn btn-primary">Submit</button>
        </form>

      </div>
    );
  }
}

CreateBank.propTypes = {
  classes: PropTypes.object,
  dispatch: PropTypes.func,
  BankManager: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    bankReducer: state.BankManager.bankReducer,
    testBankReducer: state.BankManager.testBankReducer,
  };
}

export default connect(
  mapStateToProps,
)(CreateBank);
