import React from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";
import {deck} from "../../../../assets/copy/CopyDeck";
import TitleElement, {TITLE_ELEMENT_TYPE} from "../../../../components/Elements/TitleElement";
import SectionTitleElement from "../../../ClientManager/components/Elements/SectionTitleElement";
import {clientFromId, setupSpecVersionedObjects} from "../../controllers/PipelineController";
import VersionCard from "../../components/Cards/VersionCard";
import styled from "styled-components";
import EmptyElement, {EMPTY_TYPE} from "../../../../components/Empty/EmptyElement";

const SectionTitleRow = styled.div`
  
  margin-top: 20px;
  
  padding-bottom: 10px;
  margin-bottom: 10px;
  
  padding-left: 15px;
`;

class ViewDataSpecVersionSideBar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedDataSpecId: null,
    };

    this.selectedCard = this.selectedCard.bind(this);
  }

  componentDidUpdate() {

    if (this.props.subscriptions.length > 0) {

      this.props.dataSpecs.map(dataSpec => {

        if (dataSpec.id === this.props.subscriptions[0].item &&
          (!this.state.selectedDataSpecId || this.state.selectedDataSpecId !== dataSpec.id) ) {

          this.setState({
            selectedDataSpecId: dataSpec.id
          });
        }

      });
    }
  }

  currentDataSpec() {

    let versionedDataSpecs = setupSpecVersionedObjects(this.props.dataSpecs);

    let currentDataSpec = null;
    versionedDataSpecs.map(dataSpec => {

      if (dataSpec.id === this.state.selectedDataSpecId) {

        currentDataSpec = dataSpec;
      }
    });

    return currentDataSpec;
  }

  selectedCard(version, id) {

    let route = "/pipeline-manager/view-dataspecs/" + id + "/" + version;

    this.props.history.push(route);
  }

  mapOutVersions(dataSpec) {

    if (!dataSpec.versions &&
      !this.props.environments &&
      !this.props.clients) {

      return;
    }

    let specVersions = [];

    dataSpec.versions.map((item, index) => {

      let pipelinesUsingVersion = [];

      this.props.environments.map(environment => {

        if (environment.pipelines) {

          environment.pipelines.map(pipeline => {

            if (pipeline.subscription &&
              pipeline.subscription.offering &&
              pipeline.subscription.offering.supportedDataSpec === dataSpec.name &&
              pipeline.dataSpecVersion === item.version) {

              let pipelineToPass = Object.assign({}, pipeline);
              pipelineToPass.client = clientFromId(this.props.clients, environment.clientId);

              pipelinesUsingVersion.push(pipelineToPass);
            }
          })
        }
      });

      specVersions.push(
        <VersionCard
          key={"spec-" + index}
          version={item.version}
          description={item.description}
          id={item.id}
          dataFileSpecifications={item.dataFileSpecifications}
          pipelinesUsingVersion={pipelinesUsingVersion}
          cardSelected={this.selectedCard}
          clients={this.props.clients}/>
      )
    });

    return specVersions;

  }


  render() {

    if (!this.state.selectedDataSpecId) {

      return (<EmptyElement type={EMPTY_TYPE.SELECT}/>);
    }

    let dataSpec = this.currentDataSpec();

    return (
      <div className="container">

        <TitleElement
          name={dataSpec.name}
          type={TITLE_ELEMENT_TYPE.SPEC}/>


        <SectionTitleRow className="row">
          <div className="col text-left">
            <SectionTitleElement title={deck().view_data_spec_sidebar_section_title}/>

          </div>
        </SectionTitleRow>


        <div className="row">

          {this.mapOutVersions(dataSpec)}

        </div>

      </div>
    );
  }
}

ViewDataSpecVersionSideBar.propTypes = {
  dispatch: PropTypes.func,
  components: PropTypes.arrayOf(PropTypes.shape({
    component: PropTypes.object,
    layoutStretch: PropTypes.number,
  })),
  subscriptions: PropTypes.array,
  forceRefresh: PropTypes.func,
  dataSpecs: PropTypes.object,
  environments: PropTypes.object,
  clients: PropTypes.object,
  history: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    dataSpecs: state.PipelineManager.pipeline.dataSpecs,
    environments: state.PipelineManager.pipeline.environments,
    clients: state.ClientManager.client.clients,
  }
}

export default connect(
  mapStateToProps
)(withRouter(ViewDataSpecVersionSideBar));
