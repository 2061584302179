// Set up your root reducer here...
import { combineReducers } from 'redux';
import bankingReducer from "./bankingReducer";
import common, * as commonSelector from "./commonReducer";
import { connectRouter } from 'connected-react-router'
import BankManager from "../Modules/BankManager/reducers/index";
import ClientManager from "../Modules/ClientManager/reducers/index";
import PipelineManager from "../Modules/PipelineManager/reducers/index";
import ModelManager from "../Modules/ModelManager/reducers/index";


// TODO: Dynamically load in the specific Module Reducer

/**
 * This is the main rootReducer, every new Module would have to be added here directly.
 * Preferred way would be to capitalize the individual Modules.
 * Each Module needs to be set up with a main index.js in the reducers directory that implements its
 * own individual combineReducer with all of the required reducers. You can then utilize the
 * individual data by calling state.Module.specificReducer
 */
const rootReducer = (history) => combineReducers({
  bankMap: bankingReducer,
  router: connectRouter(history),
  common: common,
  BankManager: BankManager,
  ClientManager: ClientManager,
  PipelineManager: PipelineManager,
  ModelManager: ModelManager,
});

export const canRemoveSubscription = state => commonSelector.canRemoveSubscription(state.common);
export const canCreateSubscription = state => commonSelector.canCreateSubscription(state.common);
export const canEditQuickSight = state => commonSelector.canEditQuickSight(state.common);
export const canCreatePipeline = state => commonSelector.canCreatePipeline(state.common);
export const canEditPipeline = state => commonSelector.canEditPipeline(state.common);
export const canCreateDataSpec = state => commonSelector.canCreateDataSpec(state.common);

export default rootReducer;
