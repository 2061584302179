import ReactPiwik from "react-piwik";

const url = "https://matomo.sandbox.nomisinternal.com";
// eslint-disable-next-line no-undef
const siteId = MATOMO_SITE_ID || "3";

const analytics = new ReactPiwik({
  url,
  siteId,
  jsFilename: "p.js",
  phpFilename: 'p.php',
  trackErrors: true
});

ReactPiwik.push(
  ["trackPageView"], // track initial pageview
  ["enableHeartBeatTimer"], // track time user spends on a page
  ["enableLinkTracking"], // track outgoing links and downloads
  ["setCookieDomain", "*.localhost"], // available domains are www.sputnik.* & sputnik.* etc.
  ["setDomains", ["*.localhost"]] // array of hostnames to be treated as local
);

export default analytics;
