import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import Desktop from "./Desktop";
import { createGlobalStyle} from "styled-components";

const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Avenir', sans-serif;
  }
`;

export default class Root extends Component {
  render() {
    const { store, history } = this.props;
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <GlobalStyles />
          <Desktop />
        </ConnectedRouter>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
