import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {deck} from "../../../../assets/copy/CopyDeck";
import color_palette from "../../../../assets/color/style-constants";
import LabeledContent from "../../../../components/Typography/LabeledContent";
import moment from 'moment';

const CardContainer = styled.div`
  border-radius: 6px;
  margin-bottom: 20px;
  padding-bottom: 15px;

  ${props => (
  props.selected ?
    'border: solid 4px ' + color_palette.default_border + ';' :
    'border: solid 4px transparent;'
)};

  background-color: ${color_palette.card_background_color};
  box-shadow: -2px 2px 4px 0 ${color_palette.default_box_shadow};

`;

const DropTitle = styled.p`

  font-size: 17px;
  font-weight: normal;
  color: ${color_palette.dark_text};
`;

const ViewDetailsButton = styled.button`
  
  font-size: 15px;
  font-weight: bold;
  color: ${color_palette.support_highlighted_text};
  
  border: none;
  background: none;
  cursor: pointer;
`;

class DropCard extends React.Component {

  constructor(props) {
    super(props);

    this.viewDetails = this.viewDetails.bind(this);
  }

  viewDetails() {

    this.props.cardSelected(this.props.drop.key);
  }
  render() {

    let dropDate = moment(this.props.drop.key, "YYYYMMDD");
    let lastModified = moment(this.props.drop.lastModifiedDate);

    return (
      <div className="col-12">
        <CardContainer>

          <div className="row">

            <div className="col-4 text-center">
              <DropTitle>{dropDate.format("MMM Do YYYY")}</DropTitle>
              <p>{deck().drop_card_key_label}{this.props.drop.key}</p>
              <ViewDetailsButton onClick={this.viewDetails}>{deck().drop_card_view_details}</ViewDetailsButton>
            </div>

            <div className="col-8">

              <div className="row">

                <div className="col">
                  <LabeledContent
                    label={deck().drop_card_num_of_files}
                    content={this.props.drop.files.length}/>
                </div>

                <div className="col">
                  <LabeledContent
                    label={deck().drop_card_staged}
                    content={this.props.drop.staged.toString()}/>
                </div>

                <div className="col">
                  <LabeledContent
                    label={deck().drop_card_total_num_of_errors}
                    content={this.props.drop.totalNumOfErrors}/>
                </div>

                <div className="col">
                  <LabeledContent
                    label={deck().drop_card_last_modified}
                    content={lastModified.format("MMM Do YYYY")}/>
                </div>
              </div>

            </div>

          </div>


        </CardContainer>
      </div>

    );
  }
}

DropCard.propTypes = {
  drop: PropTypes.shape({
    key: PropTypes.string,
    date: PropTypes.string,
    latest: PropTypes.bool,
    staged: PropTypes.bool,
    totalNumOfErrors: PropTypes.number,
    lastModifiedDate: PropTypes.string,
    files: PropTypes.object,
  }).isRequired,
  layoutStretch: PropTypes.number,
  cardSelected: PropTypes.func,
  selected: PropTypes.bool,
  history: PropTypes.object,
};

export default DropCard;
