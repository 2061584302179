import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import styled from "styled-components";
import SubscriptionCard from "../../components/Cards/SubscriptionCard";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CreateSubscription from "../CreateSubscription/CreateSubscription";
import {toast} from "react-toastify";
import {deck} from "../../../../assets/copy/CopyDeck";
import {getClients} from "../../actions/clientActions";
import SectionTitleElement from "../../components/Elements/SectionTitleElement";
import GenericButton from "../../components/Elements/GenericButton";
import { withRouter } from "react-router-dom";
import TitleElement, {TITLE_ELEMENT_TYPE} from "../../../../components/Elements/TitleElement";
import {getDataSpecs, getEnvironments} from "../../../PipelineManager/actions/pipelineActions";
import {validateLoadingAndErrors} from "../../../../components/ListComponent/ListComponent";
import {GET_CLIENTS} from "../../actions/actionTypes";
import EmptyElement, {EMPTY_TYPE} from "../../../../components/Empty/EmptyElement";
import { Tooltip } from "@blueprintjs/core";
import {LayoutStretch} from "../../../../utils/UIUtils";
import { canCreateSubscription } from "../../../../reducers";

const ButtonContainer = styled.div`
  margin-left: 10px;
`;

const SectionTitleRow = styled.div`

  margin-top: 20px;

  padding-bottom: 10px;
  margin-bottom: 10px;

  padding-left: 15px;
`;

class ViewSubscriptionSideBar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {

    if (!this.props.dataSpecs) {
      this.props.dispatch(getDataSpecs());
    }

    if (!this.props.environments) {
      this.props.dispatch(getEnvironments());
    }
  }

  openModal() {

    if (this.currentClient().subscriptions && this.currentClient().subscriptions.length > 0) {

      toast.error(deck().subscription_sidebar_more_than_one_error, {
        position: toast.POSITION.BOTTOM_CENTER
      });

    } else {

      let route = "/client-manager/view-clients/" + this.props.currentSelectedItem.toString() + "/create-subscription";
      this.props.history.push(route);
    }

  }

  closeModal(success) {
    this.setState({modalIsOpen: false});

    if (success === true) {

      toast.success(deck().client_card_created_subscription, {
        position: toast.POSITION.BOTTOM_CENTER
      });

      this.refreshClients();
    }
  }

  refreshClients() {

    this.props.dispatch(getClients());

  }

  renderSubscriptions(subscriptions) {

    let validationState = validateLoadingAndErrors(GET_CLIENTS, subscriptions, this.props.errors, this.refreshData, this.props.loading, this.props.layoutStretch);

    if (!validationState.valid) {
      return validationState.value;
    }

    let environment;
    if (this.props.environments) {
      this.props.environments.forEach(env => {

        if (env.clientId === this.currentClient().id) {
          environment = Object.assign({}, env);
        }
      });
    }

    var subscriptionComponents = [];

    subscriptions.map(subscription => {
      subscriptionComponents.push(
        <SubscriptionCard
          layoutStretch={LayoutStretch.SIDE_BAR}
          key={subscription.id }
          client={this.currentClient()}
          subscription={subscription}
          environment={environment}/>
      );
    });

    return subscriptionComponents;
  }

  currentClient() {
    if (!this.props.currentSelectedItem ||
      !this.props.clients) {

      return null;
    }

    let currentClient;
    this.props.clients.map(client => {

      if (client.id === this.props.currentSelectedItem) {
        currentClient = client;
      }
    });

    return currentClient;
  }

  renderMaterialModal(client) {

    return (
      <Dialog
        open={this.state.modalIsOpen}
        onClose={this.closeModal}
        maxWidth="sm"
        fullWidth={true}>

        <DialogContent>
          <CreateSubscription client={client} success={this.closeModal} />
        </DialogContent>

      </Dialog>

    );
  }

  render() {
    const { canCreateSubscription } = this.props;

    if (!this.currentClient()) {

      return (<EmptyElement type={EMPTY_TYPE.SELECT}/>);
    }

    let client = this.currentClient();

    return (
      <div>

        <TitleElement
          name={client.name}
          supportName={client.clientCode}
          type={TITLE_ELEMENT_TYPE.CLIENT}/>

        <SectionTitleRow className="row">
          <div className="col text-left">
            <Tooltip content={deck().subscription_sidebar_section_title_tooltip} >
              <SectionTitleElement title={deck().subscription_sidebar_section_title}/>
            </Tooltip>
          </div>
        </SectionTitleRow>

        {this.renderSubscriptions(client.subscriptions)}
        {canCreateSubscription && (
          <ButtonContainer>
            <GenericButton
              buttonName="subscription_sidebar_add"
              title={deck().subscription_sidebar_add_button_title}
              action={this.openModal}
              altTitle={deck().subscription_sidebar_add_button_alt}/>
          </ButtonContainer>
        )}


        {this.renderMaterialModal(client)}
      </div>
    );
  }
}

ViewSubscriptionSideBar.propTypes = {
  dispatch: PropTypes.func,
  components: PropTypes.arrayOf(PropTypes.shape({
    component: PropTypes.object,
    layoutStretch: PropTypes.number,
  })),
  layoutStretch: PropTypes.number,
  currentSelectedItem: PropTypes.object,
  forceRefresh: PropTypes.func,
  clients: PropTypes.array,
  dataSpecs: PropTypes.object,
  history: PropTypes.object,
  environments: PropTypes.object,
  loading: PropTypes.object,
  errors: PropTypes.object,
  canCreateSubscription: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    loading: state.common.loading,
    errors: state.common.errors,
    clients: state.ClientManager.client.clients,
    dataSpecs: state.PipelineManager.pipeline.dataSpecs,
    environments: state.PipelineManager.pipeline.environments,
    canCreateSubscription: canCreateSubscription(state)
  }
}
export default connect(
  mapStateToProps
)(withRouter(ViewSubscriptionSideBar));
