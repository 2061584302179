import styled from 'styled-components'
import color_palette from "../../assets/color/style-constants";

const shadowColor = color_palette.default_box_shadow;
const selectionBlue = color_palette.default_border;
const transparent = 'transparent';

const boxShadow = `-2px 2px 4px 0 ${shadowColor}`;

const verticalFlex = `
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StyledCard = styled.div`
  background-color: ${color_palette.card_background_color};

  border: solid 4px ${({ selected }) => selected ? selectionBlue : transparent};
  border-radius: 6px;

  box-shadow: ${boxShadow};

  ${verticalFlex}
`;

export default StyledCard
