import React from 'react';
import PropTypes from "prop-types";

class BankCard extends React.Component {

  render() {

    return (
      <div className="col-md-4">
        <div className="card mb-4 box-shadow">
          <img className="card-img-top"
               src="https://dummyimage.com/250x250/000/fff"
               alt="Card image cap" />

          <div className="card-body">
            <p className="card-text">{this.props.description}</p>

            <div className="d-flex justify-content-between align-items-center">

              <div className="btn-group">
                <button type="button" className="btn btn-sm btn-outline-secondary">{this.props.support_button_left}</button>
                <button type="button" className="btn btn-sm btn-outline-secondary">{this.props.support_button_right}</button>
              </div>

              <small className="text-muted">{this.props.support_message}</small>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

BankCard.propTypes = {
  description: PropTypes.string.isRequired,
  support_button_right: PropTypes.string,
  support_button_left: PropTypes.string,
  support_message: PropTypes.string,
};

export default BankCard;
