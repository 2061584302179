import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {PipelineIcon} from "../../../../components/Icons";
import color_palette from "../../../../assets/color/style-constants";

const MataDataRow = styled.div`
  
  padding-bottom: 0px;
  margin-bottom: 0px;
`;

const IconRow = styled.div`

  height: 60px;
  
  padding-right: 5px !important;
`;

const MetaDataItem = styled.div`
  padding-top: 10px;
  color: ${color_palette.light_header};
  font-size: 11px;
  font-weight: normal;
  
  padding-left: 5px !important;
`;

const TitleLabel = styled.p`

  font-size: 17px;
  color: ${color_palette.support_highlighted_text};
  line-height: 17px;
  
  margin: 0px 0px 0px 0px;
`;

class PipelineTitleElement extends React.PureComponent {

  render() {

    return (
      <MataDataRow className="row">
        <IconRow className="col-5 text-right">
          <PipelineIcon height={"40px"} width={"40px"} fill={"#0078fe"}/>
        </IconRow>

        <MetaDataItem className="col-7 text-left">

          <TitleLabel>{this.props.title}</TitleLabel>

        </MetaDataItem>
      </MataDataRow>
    );
  }
}

PipelineTitleElement.propTypes = {
  title: PropTypes.string,
};

export default PipelineTitleElement;
