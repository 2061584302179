import React from 'react';
import PropTypes from "prop-types";
import styled from "styled-components";
import {LayoutStretch} from "../../../../utils/UIUtils";
import {deck} from "../../../../assets/copy/CopyDeck";
import FileStatusElement from "../Elements/FileStatusElement";
import color_palette from "../../../../assets/color/style-constants";
import moment from 'moment';

const CardContainer = styled.div`
  border-radius: 6px;
  margin-bottom: 20px;

  padding-top: 10px;
  padding-bottom: 10px;

  ${props => (
  props.selected ?
    'border: solid 2px ' + color_palette.default_border + ';' :
    'border: solid 2px #f3f3f3;'
)};

  background-color: ${color_palette.card_background_color};
  box-shadow: -2px 2px 4px 0 ${color_palette.default_box_shadow};

`;

const TitleLabel = styled.p`

  font-size: 11px;
  color: ${color_palette.light_header};
`;

const ValidationStateContainer = styled.div`
  min-height: 20px;
`;


class FileGeneralCard extends React.PureComponent {

  constructor(props) {
    super(props);

    this.selectCard = this.selectCard.bind(this);
  }

  selectCard() {
    // Prevent linking out to the detail page for the empty files
    if (this.props.file.validationState === "waiting"){
      return;
    }

    const { cardSelected, file, selected } = this.props;
    if (!selected) {
      cardSelected(file);
    }
  }

  render() {
    var colSize = "col-12 col-sm-6 col-md-4";

    switch (this.props.layoutStretch) {

      case LayoutStretch.PRIMARY_FULL: {
        colSize = "col-6";
        break;
      }

      case LayoutStretch.PRIMARY_HALF: {
        colSize = "col-12";
        break;
      }

      case LayoutStretch.PRIMARY_ONE_THIRD: {
        colSize = "col-12";
        break;
      }

      case LayoutStretch.PRIMARY_TWO_THIRD: {
        colSize = "col-12";
        break;
      }
    }

    let valid;
    let numErrors;
    if (this.props.file.nameValidationResult !== undefined &&
      this.props.file.contentValidationResult !== undefined) {

      valid = (
        <div className="col-6">
          <TitleLabel>{deck().file_general_name_validation_result}{this.props.file.nameValidationResult.toString()}</TitleLabel>
          <TitleLabel>{deck().file_general_content_validation_result}{this.props.file.contentValidationResult.toString()}</TitleLabel>
        </div>
      );

      if (this.props.file.contentValidationResult === "invalid" &&
        this.props.file.numErrors !== undefined) {
        numErrors = (
          <div className="col-6">
            <TitleLabel>{deck().file_general_number_of_errors} {this.props.file.numErrors.toString()}</TitleLabel>
          </div>
        );
      }
    }

    let fileSetValidatedDate;
    if (this.props.file.fileSetValidatedDate !== undefined &&
      this.props.file.fileSetValidatedDate !== "None") {

      fileSetValidatedDate = (
        <div className="col-6">
          <TitleLabel>{deck().file_general_validation_date}{moment(this.props.file.fileSetValidatedDate).format("MMM Do YYYY - H:mm")}</TitleLabel>
        </div>
      )
    }

    let name;
    if (this.props.file &&
      this.props.file.fileName &&
      this.props.file.fileName.name) {

      name = (
        <div className="col-6">
          <TitleLabel>{deck().file_general_file_name} {this.props.file.fileName.name}</TitleLabel>
        </div>
      );

    } else if (this.props.file.name) {

      name = (
        <div className="col-6">
          <TitleLabel>{deck().file_general_file_name} {this.props.file.name}</TitleLabel>
        </div>
      );
    }

    let fileType;
    if (this.props.file.fileSpecName) {
      fileType = (
        <div className="col-6">
          <TitleLabel>{deck().file_general_file_spec_name} {this.props.file.fileSpecName}</TitleLabel>
        </div>
      );
    } else if (this.props.file.fileType) {

      fileType = (
        <div className="col-6">
          <TitleLabel>{deck().file_general_file_type} {this.props.file.fileType}</TitleLabel>
        </div>
      );
    }

    let active;
    if (this.props.file.validationState) {
      active = (
        <div className="col-6">
          <TitleLabel>{deck().file_general_validation_state} {this.props.file.validationState}</TitleLabel>
        </div>
      );
    } else if (this.props.file.activeVersion) {

      active = (
        <div className="col-6">
          <TitleLabel>{deck().file_general_active_version} {this.props.file.activeVersion}</TitleLabel>
        </div>
      );
    }

    let completeValidationState;

    if (this.props.file.validationState &&
      this.props.file.nameValidationResult !== undefined &&
      this.props.file.contentValidationResult !== undefined) {

      if (this.props.file.validationState === "complete" &&
        this.props.file.nameValidationResult === "valid" &&
        this.props.file.contentValidationResult === "valid") {

        completeValidationState = (
          <ValidationStateContainer className="col-6">
            <FileStatusElement status={true} statusText={deck().file_general_valid}/>
          </ValidationStateContainer>
        );
      } else {

        completeValidationState = (
          <ValidationStateContainer className="col-6 ">
            <FileStatusElement status={false} statusText={deck().file_general_invalid}/>
          </ValidationStateContainer>
        );
      }
    }


    return (
      <div className={colSize}>
        <CardContainer
          onClick={e => {
            e.stopPropagation();
            this.selectCard();
          }}
          selected={this.props.selected}
          className="container"
        >

          <div className="row">

            {completeValidationState}
            {name}
            {valid}
            {fileType}
            {fileSetValidatedDate}
            {active}
            {numErrors}

          </div>

        </CardContainer>

      </div>
    );
  }
}

FileGeneralCard.propTypes = {
  file: PropTypes.shape({
    fileName: PropTypes.shape({
      name: PropTypes.string,
      extension: PropTypes.string,
    }),
    name: PropTypes.string,
    fileSpecName: PropTypes.string,
    activeVersion: PropTypes.string,
    contentValidationResult: PropTypes.string,
    nameValidationResult: PropTypes.string,
    validationState: PropTypes.string,
    isValid: PropTypes.bool,
    fileType: PropTypes.string,
    numErrors: PropTypes.number,
    fileSetValidatedDate: PropTypes.string,
  }).isRequired,
  layoutStretch: PropTypes.number,
  cardSelected: PropTypes.func,
  selected: PropTypes.bool,
};

export default FileGeneralCard;
