import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CopyToClipboard from 'react-copy-html-to-clipboard';
import {toast} from "react-toastify";
import {deck} from "../../../../assets/copy/CopyDeck";
import color_palette from "../../../../assets/color/style-constants";

const CardContainer = styled.div`
  padding-top: 15px;
  padding-bottom: 5px;
  
  border-radius: 6px;
  border: solid 1px #e9eff4;
  margin-bottom: 20px;
  
  background-color: ${color_palette.card_background_color};
  
  box-shadow: -2px 2px 4px 0 ${color_palette.default_box_shadow};
`;

const ErrorItem = styled.div`
  min-width: 80px;
  height: 30px;
  border-radius: 2px;
  background-color: rgba(255, 109, 74, 0.1);
  
  margin: 5px;
  
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  color: ${color_palette.cancel_item};
  
  vertical-align: middle;
  
  padding-top: 7px;

`;

const ErrorMessageLabel = styled.p`
  
  font-size: 17px;
  font-weight: normal;
  color: ${color_palette.dark_text};
  
  margin-top: auto;
  margin-bottom: auto;
  
`;

class FileErrorCard extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <CardContainer className="col-12">

        <div className="row">
          <div className="col-2">

            <ErrorItem>{this.props.error.error}</ErrorItem>
            <ErrorItem>{this.props.error.errorCode}</ErrorItem>

          </div>

          <div className="col-10">


            <CopyToClipboard
              text={this.props.error.errorMessage}
              onCopy={() => {

                toast.info(deck().file_meta_data_text_copied, {
                  autoClose: 1500,
                  position: toast.POSITION.BOTTOM_CENTER
                });

              }}>

              <ErrorMessageLabel>{this.props.error.errorMessage}</ErrorMessageLabel>
            </CopyToClipboard>
          </div>
        </div>

      </CardContainer>
    );
  }
}

FileErrorCard.propTypes = {
  error: PropTypes.shape({
    error: PropTypes.string,
    errorCode: PropTypes.number,
    errorMessage: PropTypes.string,
  }),
};

export default FileErrorCard;
