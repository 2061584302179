
export const MainContentOccupyWidth = {
  ONE_THIRD: 0,
  HALF: 1,
  TWO_THIRD: 2,
  FULL: 3,
};

export const SideBar = {
  RIGHT: 0,
  LEFT: 1,
  BOTH: 2,
};

export const LayoutStretch = {
  PRIMARY_FULL: 0,
  PRIMARY_ONE_THIRD: 1,
  PRIMARY_HALF: 2,
  PRIMARY_TWO_THIRD: 3,
  SIDE_BAR: 4,
};

export const LayoutSide = {
  LEFT: 0,
  RIGHT: 1,
};
