import React from "react";
import PropTypes from "prop-types";
import {
  pipelineFromId,
} from "../../controllers/PipelineController";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";
import {
  getEnvironments,
  getPricingConfigFile,
  postActivePricingConfig,
  postPricingConfigFile
} from "../../actions/pipelineActions";
import {deck} from "../../../../assets/copy/CopyDeck";
import {toast} from "react-toastify";
import TitleElement, {TITLE_ELEMENT_TYPE} from "../../../../components/Elements/TitleElement";
import SectionTitleElement from "../../../ClientManager/components/Elements/SectionTitleElement";
import styled from "styled-components";
import PricingFileVersionCard from "../../components/Cards/PricingFileVersionCard";
import ReactFileReader from 'react-file-reader';
import {
  GET_PRICING_CONFIG_FILE,
  POST_ACTIVE_PRICING_CONFIG_FILE,
  POST_PRICING_CONFIG_FILE
} from "../../actions/actionTypes";

import Button, { BUTTON_STATE } from '../../../../components/Button'
import LoadingElement from "../../../../components/Loading/LoadingElement";
import ErrorElement from "../../../../components/Error/ErrorElement";
import EmptyElement, {EMPTY_TYPE} from "../../../../components/Empty/EmptyElement";
import NomisDialog from "../../../../components/Dialog/NomisDialog";

import { canEditPipeline } from "../../../../reducers";


const AddNewVersionButton = styled.div`
  margin: 0 auto;
  width: 210px;
`;

const CancelButton = styled(Button)`
  border: solid 1px #d9534f;
  background-color: #d9534f;
  margin-left: 16px;
  border-radius: 3px;
`;

class ViewPipelineDetailSideBar extends React.Component {

  constructor(props) {
    super(props);

    this.getAddNewButtonState = this.getAddNewButtonState.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
    this.selectedItemAction = this.selectedItemAction.bind(this);
    this.currentFileName = this.currentFileName.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.reloadPipeline = this.reloadPipeline.bind(this);
    this.setActivePricingConfig = this.setActivePricingConfig.bind(this);
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this);

    this.state = {
      isConfirmDialogOpen: false,
      pulledFile: null,
    }
  }

  componentDidUpdate(prevProps) {

    if ((this.props.subscriptions.length > 0 &&
      prevProps.subscriptions.length === 0) ||
      (prevProps.subscriptions.length > 0 &&
        this.props.subscriptions[0].item !== this.state.pulledFile)) {

      this.refreshData();

    }

    if (this.state.showActiveButtonLoading &&
      this.state.fileToSetActive &&
      !this.props.loading.includes(POST_ACTIVE_PRICING_CONFIG_FILE)) {

      this.setState({
        showActiveButtonLoading: false,
        fileToSetActive: null,
      })
    }
  }

  reloadPipeline() {
    this.props.dispatch(getEnvironments());

    toast.success(deck().pipeline_details_set_active_success, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 2000,
      pauseOnFocusLoss: false
    });
  }

  refreshData() {

    this.setState({
      pulledFile: this.props.subscriptions[0].item,
    });

    let pipeline = pipelineFromId(this.props.environments, this.routeDetails().pipelineId);

    let values = {
      fileName: this.props.subscriptions[0].item,
      environmentId: pipeline.environmentId,
      pipelineId: pipeline.id,
    };

    this.props.dispatch(getPricingConfigFile(values, ViewPipelineDetailSideBar.onError));
  }

  static onError(errors) {

    let message = deck().general_api_error;

    // Grab the first Error and display it
    // TODO: Update to handle multiple errors
    if (errors &&
      errors.length > 0 &&
      errors[0] &&
      errors[0].errorMessage) {

      message = errors[0].errorMessage;
    }

    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER
    });

  }

  currentFileName() {

    if (this.props &&
      this.props.subscriptions &&
      this.props.subscriptions[0] &&
      this.props.subscriptions[0].item) {

      return this.props.subscriptions[0].item;
    }

    return null;
  }


  onSuccess() {

    this.refreshData();

    toast.success(deck().pipeline_details_side_bar_upload_new_version_success, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 2000,
      pauseOnFocusLoss: false
    });
  }

  routeDetails() {

    var splitString = this.props.router.location.pathname.split('/');

    let pipelineId = splitString[3];

    return ({
      pipelineId: pipelineId,
    });
  }


  setActivePricingConfig() {

    let pipeline = pipelineFromId(this.props.environments, this.routeDetails().pipelineId);
    let fileName = "";

    if (this.currentFileName()) {

      fileName = this.currentFileName();
    }

    let values = {
      environmentId: pipeline.environmentId,
      pipelineId: pipeline.id,
      body: {
        fileMetadata: this.state.fileToSetActive.metadata,
        pricingSpecFileName: fileName,
      },
    };

    this.props.dispatch(postActivePricingConfig(values, this.reloadPipeline, ViewPipelineDetailSideBar.onError));

    this.setState({
      showActiveButtonLoading: true,
      isConfirmDialogOpen: false,
    });
  }

  closeConfirmDialog() {

    this.setState({
      fileToSetActive: null,
      isConfirmDialogOpen: false,
    });
  }

  selectedItemAction(file) {

    this.setState({
      fileToSetActive: file,
      isConfirmDialogOpen: !this.state.isConfirmDialogOpen,
    });

  }

  mapOutVersions(versions) {

    let specVersions = [];

    let pipeline = pipelineFromId(this.props.environments, this.routeDetails().pipelineId);

    if (!versions ||
      !this.props.subscriptions ||
      !this.props.subscriptions[0] ||
      !this.props.subscriptions[0].item ||
      !pipeline) {

      return
    }

    var currentFileName = this.currentFileName();

    versions.map((item, index) => {

      let active = false;

      if (pipeline &&
        pipeline.activePricingConfigs &&
        currentFileName &&
        item.metadata) {

        for (var fileName in pipeline.activePricingConfigs) {

          if (fileName === currentFileName &&
            pipeline.activePricingConfigs[fileName].fileName.name === item.metadata.fileName.name) {

            active = true;
          }

        }
      }

      let showLoading = false;
      if (this.state.showActiveButtonLoading &&
        this.state.fileToSetActive &&
        this.state.fileToSetActive === item) {

        showLoading = true
      }

      specVersions.push(
        <PricingFileVersionCard
          key={"file-" + index}
          file={item}
          active={active}
          layoutStretch={this.props.layoutStretch}
          pipeline={pipeline}
          selected={false}
          loading={showLoading}
          errors={this.props.errors}
          cardSelected={this.selectedItemAction}
          canSetActive={this.props.canEditPipeline}
        />
      )
    });

    return specVersions;

  }

  getAddNewButtonState() {
    const { loading } = this.props;
    if (loading.includes(POST_PRICING_CONFIG_FILE)) {
      return BUTTON_STATE.LOADING;
    }

    return BUTTON_STATE.ACTIVE;
  }

  handleFiles(files) {
    var reader = new FileReader();
    let pipeline = pipelineFromId(this.props.environments, this.routeDetails().pipelineId);
    let fileContent = "";
    let fileName = "";

    if (this.currentFileName()) {

      fileName = this.currentFileName();
    }

    reader.onload = function () {
      fileContent = reader.result;

      // TODO: Setup an upload new Version functionality
      let values = {
        fileName: fileName,
        fileContent: fileContent,
        environmentId: pipeline.environmentId,
        pipelineId: pipeline.id,
      };

      this.props.dispatch(postPricingConfigFile(values, this.onSuccess, ViewPipelineDetailSideBar.onError));

    }.bind(this);

    reader.readAsText(files[0]);

  }

  render() {

    const { canEditPipeline } = this.props;
    if (this.props.loading.includes(GET_PRICING_CONFIG_FILE)) {

      return (<LoadingElement/>);

    } else if (this.props.errors.some(error => error.type === GET_PRICING_CONFIG_FILE)) {

      let failedCallElements = [];

      this.props.errors.map(error => {
        failedCallElements.push(<ErrorElement errors={error.errors} type={error.type}/>);
      });

      return failedCallElements;
    }

    if (!this.state.pulledFile) {

      return (<EmptyElement type={EMPTY_TYPE.SELECT}/>);
    }


    let pipeline = pipelineFromId(this.props.environments, this.routeDetails().pipelineId);

    let fileName = "";
    if (this.currentFileName()) {

      fileName = this.currentFileName();
    }

    const DIALOG_ID = "set_active_pricing_config_dialog"

    return (

      <>
        <div className="container">

          <div className="row">
            <div className="col">
              <TitleElement
                name={fileName}
                supportName={pipeline.id}
                type={TITLE_ELEMENT_TYPE.SPEC}/>
            </div>

          </div>

          <div className="row">
            <div className="col text-left">
              <SectionTitleElement title={deck().view_data_spec_sidebar_section_title}/>

            </div>
          </div>

          <div className="row">
            {this.mapOutVersions(this.props.currentPricingConfigFile)}
          </div>

          <div className="row">
            <div className="col text-center">

              <ReactFileReader
                disabled={this.props.loading.includes(POST_PRICING_CONFIG_FILE)}
                fileTypes={[".csv"]}
                handleFiles={this.handleFiles}
              >
                {canEditPipeline && (
                  <AddNewVersionButton>
                    <Button buttonName="add_new_version_button" state={this.getAddNewButtonState()}>
                      {deck().pipeline_details_side_bar_upload_new_version}
                    </Button>
                  </AddNewVersionButton>
                )}
              </ReactFileReader>

            </div>
          </div>

        </div>

        <NomisDialog
          dialogId="set_active_pricing_config_dialog"
          isOpen={this.state.isConfirmDialogOpen}
          title={deck().pipeline_details_confirm_dialog_title}
          onCancelAction={this.closeConfirmDialog}
          body={
            <>
              <p>
                <strong>
                  {deck().pipeline_details_confirm_dialog_p_title}
                </strong>
              </p>
              <p>{deck().pipeline_details_confirm_dialog_p_one}</p>
              <p>{deck().pipeline_details_confirm_dialog_p_two}</p>
            </>
          }
          footer={
            <>
              <CancelButton buttonName={`${DIALOG_ID}_cancel`} onClick={this.closeConfirmDialog} state={BUTTON_STATE.ACTIVE}>{deck().pipeline_details_confirm_dialog_close_button_title}</CancelButton>
              <Button buttonName={`${DIALOG_ID}_confirm`} onClick={this.setActivePricingConfig} state={BUTTON_STATE.ACTIVE}>{deck().pipeline_details_confirm_dialog_confirm_button_title}</Button>
            </>
          }
        />
      </>

    );
  }
}


ViewPipelineDetailSideBar.propTypes = {
  layoutStretch: PropTypes.number,
  dispatch: PropTypes.func,
  components: PropTypes.arrayOf(PropTypes.shape({
    component: PropTypes.object,
    layoutStretch: PropTypes.number,
  })),
  subscriptions: PropTypes.array,
  pricingSpecs: PropTypes.array,
  router: PropTypes.object,
  environments: PropTypes.object,
  clients: PropTypes.object,
  offerings: PropTypes.array,
  forceRefresh: PropTypes.func,
  files: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.object,
  errors: PropTypes.object,

  currentPricingConfigFile: PropTypes.array,
  canEditPipeline: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    loading: state.common.loading,
    errors: state.common.errors,
    router: state.router,
    pricingSpecs: state.PipelineManager.pipeline.pricingSpecs,
    environments: state.PipelineManager.pipeline.environments,
    clients: state.ClientManager.client.clients,
    offerings: state.ClientManager.client.offerings,
    currentPricingConfigFile: state.PipelineManager.pipeline.currentPricingConfigFile,
    canEditPipeline: canEditPipeline(state)
  };
}

export default connect(
  mapStateToProps,
)(withRouter(ViewPipelineDetailSideBar));
