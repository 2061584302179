import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MAPEElement from "../Elements/MAPEElement";
import HRElement from "../Elements/HRElement";
import ModelIdElement from "../Elements/ModelIdElement";
import RiskElement from "../Elements/RiskElement";
import {deck} from "../../../../assets/copy/CopyDeck";
import color_palette from "../../../../assets/color/style-constants";

const CardContainer = styled.div`
  border-radius: 6px;
  margin-bottom: 20px;
  
  ${props => (
  props.selected ?
    'border: solid 4px ' + color_palette.default_border + ';' :
    'border: solid 4px transparent;'
)};
  
  background-color: ${color_palette.card_background_color};
  box-shadow: -2px 2px 4px 0 ${color_palette.default_box_shadow};
  
`;

class ModelMonitoringCard extends React.Component {

  constructor(props) {
    super(props);

    this.selectCard = this.selectCard.bind(this);
  }

  selectCard() {
    this.props.cardSelected(this.props.model);
  }

  render() {

    var colSize = "col-12";

    let riskItem;
    if (this.props.model.monitoring && this.props.model.monitoring.risk_priority) {

      let value = {
        title: deck().model_monitoring_card_risk,
        value: this.props.model.monitoring.risk_priority,
      };
      riskItem = (
        <RiskElement item={value}/>
      );
    }

    let performanceItem;
    if (this.props.model.monitoring && this.props.model.monitoring.model_performance_risk) {

      let value = {
        title: deck().model_monitoring_card_performance,
        value: this.props.model.monitoring.model_performance_risk,
      };
      performanceItem = (
        <RiskElement item={value}/>
      );
    }

    let inferenceItem;
    if (this.props.model.monitoring && this.props.model.monitoring.risk_priority) {

      let value = {
        title: deck().model_monitoring_card_inference,
        value: this.props.model.monitoring.psi_inference,
      };
      inferenceItem = (
        <RiskElement item={value}/>
      );
    }

    let mapeElement;
    if (this.props.model.monitoring) {

      mapeElement = (
        <MAPEElement monitoring={this.props.model.monitoring}/>
      );
    }

    let hr6MonthItem;
    if (this.props.model.monitoring && (this.props.model.monitoring.ratediff_coef !== null)) {

      let value = {
        title: deck().model_monitoring_card_hr_6_mo,
        value: this.props.model.monitoring.ratediff_coef,
      };
      hr6MonthItem = (
        <HRElement item={value}/>
      );
    }

    let hrPValueItem;
    if (this.props.model.monitoring && (this.props.model.monitoring.ratediff_pval !== null)) {

      let value = {
        title: deck().model_monitoring_card_hr_p_val,
        value: this.props.model.monitoring.ratediff_pval,
      };
      hrPValueItem = (
        <HRElement item={value}/>
      );
    }

    let ksItem;
    if (this.props.model.monitoring && (this.props.model.monitoring.ks_dstat !== null)) {

      let value = {
        title: deck().model_monitoring_card_ks,
        value: this.props.model.monitoring.ks_dstat,
      };
      ksItem = (
        <HRElement item={value}/>
      );
    }

    let ksPValueItem;
    if (this.props.model.monitoring && (this.props.model.monitoring.ks_pval !== null)) {

      let value = {
        title: deck().model_monitoring_card_ks_p_val,
        value: this.props.model.monitoring.ks_pval,
      };
      ksPValueItem = (
        <HRElement item={value}/>
      );
    }

    return (

      <div className={colSize}>
        <CardContainer onClick={this.selectCard} selected={this.props.selected}>

          <div className="row">
            <div className="col-3">

              <div className="row">
                <div className="col">
                  <ModelIdElement model={this.props.model}/>
                </div>
              </div>

              <div className="row">
                <div className="col-4">
                  {riskItem}
                </div>

                <div className="col-4">
                  {performanceItem}
                </div>

                <div className="col-4">
                  {inferenceItem}
                </div>
              </div>

            </div>

            <div className="col-6">
              {mapeElement}
            </div>

            <div className="col-3">
              <div className="row">
                <div className="col-6">

                  {hr6MonthItem}
                </div>

                <div className="col-6">
                  {hrPValueItem}
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  {ksItem}
                </div>

                <div className="col-6">
                  {ksPValueItem}
                </div>
              </div>
            </div>
          </div>

        </CardContainer>
      </div>

    );
  }
}

ModelMonitoringCard.propTypes = {
  model: PropTypes.shape({
    id: PropTypes.number,
    monitoring: PropTypes.object,
    production: PropTypes.object,
  }).isRequired,
  cardSelected: PropTypes.func,
  selected: PropTypes.bool,
};

export default ModelMonitoringCard;
