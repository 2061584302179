import React from "react";
import {Field} from "react-final-form";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Tooltip } from "@blueprintjs/core";
import color_palette from "../../assets/color/style-constants";

export const CustomSelect = styled.select`
	display: block;
	font-size: 16px;
	font-weight: regular;
	line-height: 1.3;
	padding: .6em 1.4em .5em .8em;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;

	margin: 0;
	border: ${props => props.error ? "2px solid " + color_palette.cancel_item : "1px solid #aaa"};
	border-radius: 4px;
	appearance: none;
	background-color: ${color_palette.white_text};
	background-image: url(${require('../../assets/img/icons/down_arrow.png')}),
	  linear-gradient(to bottom, #ffffff 0%,#ffffff 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;

	color: ${props => props.visited ? color_palette.dark_text : "#C2C4C7"};
`;

export const TitleLabel = styled.label`

  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${color_palette.dark_text};
`;

const ErrorLabel = styled.span`

  color: ${color_palette.cancel_item};
`;

class DropDown extends  React.Component {

  renderOptions(dropDownOptions) {

    var options = [];

    dropDownOptions.map(option => {

      options.push(<option value={option} key={this.props.name + '-' + option}>{option}</option>)
    });

    return options;
  }

  render() {

    let title = (<TitleLabel htmlFor="inputGroupSelect01">{this.props.title}</TitleLabel>);
    if (this.props.titleTooltip) {
      title = (
        <Tooltip content={this.props.titleTooltip}>
          <TitleLabel htmlFor="inputGroupSelect01">{this.props.title}</TitleLabel>
        </Tooltip>
      );
    }

    return (
      <Field
        name={this.props.name}
        validate={this.props.validation} >

        {({ input, meta }) => (

          <>
            {title}
            <CustomSelect visited={meta.visited} error={(meta.error && meta.touched)} {...input} id="inputGroupSelect01">
              <option selected>{this.props.placeholder}</option>
              {
                this.renderOptions(this.props.dropDownOptions)
              }
            </CustomSelect>

            <div >
              {meta.error && meta.touched && <ErrorLabel>{meta.error}</ErrorLabel>}
            </div>
          </>
        )}
      </Field>
    );
  }
}

DropDown.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  titleTooltip: PropTypes.string,
  placeholder: PropTypes.string,
  validation: PropTypes.func,
  dropDownOptions: PropTypes.array,
};

export default DropDown;
