import * as types from './actionTypes';
import ModelingAPI from "../api/ModelingAPI";

export function getBankMapping() {

  return function(dispatch) {

    return ModelingAPI.gatBankMapping()
      .then(bankMap => {

        dispatch(loadBankMappingSuccess(bankMap));
      })
      .catch(error => {
        throw (error)
      })
  }
}

export function loadBankMappingSuccess(results) {

  return { type: types.GET_BANK_MAPPING, results: results}
}

export function getMonitoringTable(bankName, bankCode, vertical, waterfallShortName, onError) {

  return function(dispatch) {

    return ModelingAPI.getMonitoringTable(bankName, bankCode, vertical, waterfallShortName)
      .then(results => {

        dispatch(loadMonitoringTableSuccess(results))
      })
      .catch(error => {
        if (onError) {
          onError(error);
        }
      })

  }
}

export function loadMonitoringTableSuccess(results) {

  return { type: types.GET_MONITORING_TABLE, results: results}
}

export function getProductionTable(bankName, bankCode, vertical, onError) {

  return function(dispatch) {

    return ModelingAPI.getProductionTable(bankName, bankCode, vertical)
      .then(results => {

        dispatch(loadProductionTableSuccess(results))
      })
      .catch(error => {
        if (onError) {
          onError(error);
        }
      })
  }
}

export function loadProductionTableSuccess(results) {

  return { type: types.GET_PRODUCTION_TABLE, results: results}
}

export function getModelPVA(source, bankCode, waterfall, modelId, product, vertical, onError) {

  return function(dispatch) {

    return ModelingAPI.getModelPVA(source, bankCode, waterfall, modelId, product, vertical)
      .then(results => {

        dispatch(loadModelPVASuccess(results));
      })
      .catch(error => {
        if(onError){
          onError(error);
        }
      })
  }
}

export function loadModelPVASuccess(results) {

  return { type: types.GET_MODEL_PVA, results: results}
}
