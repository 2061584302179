import * as types from './actionTypes.js';
import {pipelineManagementAPI} from "../../../utils/APIutils";
import {SPEC_TYPE} from "../views/ViewSpecVersion/ViewSpecVersion";
import {
  addLoadingAPIs,
  handleStandardCatchError,
  processStandardSuccessCall,
  removeErrorOnAPIs,
  removeLoadingAPIs
} from "../../../actions/commonActions";

export function initSpecEdit(spec) {

  return function(dispatch) {

    dispatch(
      {
        type: types.INIT_SPEC_EDIT,
        spec: spec,
      }
    );

  }
}

export function saveFile(file, spec) {

  let contains = false;
  let newSpec = Object.assign({}, spec);
  newSpec.dataFileSpecifications = spec.dataFileSpecifications.map(dataFile => {

    if (dataFile.name === file.name) {
      dataFile = file;
      contains = true;
    }
    return dataFile;
  });

  if (!contains) {
    newSpec.dataFileSpecifications.push(file);
  }

  return function(dispatch) {
    dispatch(
      {
        type: types.SAVE_FILE,
        spec: newSpec,
      }
    )
  }
}

/* saveSpec() */
export function saveSpec(type, spec, onSuccess, onErrors) {

  return function (dispatch) {

    var params = {
      dataSpecName: spec.name,
    };

    var body = {
      dataFileSpecifications: spec.dataFileSpecifications,
      description: spec.description
    };

    var additionalParams = {};

    dispatch(addLoadingAPIs(types.POST_SPEC_VERSION));
    dispatch(removeErrorOnAPIs(types.POST_SPEC_VERSION));

    if (type === SPEC_TYPE.PRICING_SPEC) {

      return pipelineManagementAPI().pricingSpecsDataSpecNamePost(params, body, additionalParams)
        .then(function (result) {

          processStandardSuccessCall(result, types.POST_ACTIVE_PRICING_CONFIG_FILE, dispatch, onSuccess, onErrors);
          dispatch(removeLoadingAPIs(types.POST_SPEC_VERSION))
        })
        .catch(function(errors){
          handleStandardCatchError(errors, types.POST_ACTIVE_PRICING_CONFIG_FILE, dispatch, onErrors);
          dispatch(removeLoadingAPIs(types.POST_SPEC_VERSION))
        });

    } else if (type ===SPEC_TYPE.DATA_SPEC) {

      return pipelineManagementAPI().dataspecsDataSpecNamePost(params, body, additionalParams)
        .then(function (result) {

          processStandardSuccessCall(result, types.POST_ACTIVE_PRICING_CONFIG_FILE, dispatch, onSuccess, onErrors);
          dispatch(removeLoadingAPIs(types.POST_SPEC_VERSION))
        })
        .catch(function(errors){
          handleStandardCatchError(errors, types.POST_ACTIVE_PRICING_CONFIG_FILE, dispatch, onErrors);
          dispatch(removeLoadingAPIs(types.POST_SPEC_VERSION))
        });
    }
  }

}

export const removeFileFromSpec = fileName => (dispatch, getState) => {
  const state = getState();

  const editingSpec = state.PipelineManager.specEdit.editingSpec;


  const action = {
    type: types.REMOVE_FILE_FROM_SPEC,
    spec: {
      ...editingSpec,
      dataFileSpecifications: editingSpec.dataFileSpecifications.filter(({ name }) => name !== fileName)
    }
  }

  dispatch(action);
}
