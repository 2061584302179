export function clientManagementAPI() {

  let apigClient;

  // TODO: Rename the API_KEY to the specific client management one

  apigClient = window.clientManagementApigClientFactory.newClient({
    apiKey: CLIENT_MANAGEMENT_API_KEY, // eslint-disable-line no-undef
  });

  return apigClient;
}

export function pipelineManagementAPI() {

  let apigClient;

  // TODO: Rename the API_KEY to the specific pipeline management one

  apigClient = window.pipelineManagementApigClientFactory.newClient({
    apiKey: PIPELINE_MANAGEMENT_API_KEY, // eslint-disable-line no-undef
  });

  return apigClient;
}
