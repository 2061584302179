import {deck} from "../assets/copy/CopyDeck";
import BankManager from "./BankManager";
import ModelManager from "./ModelManager";
import ClientManager from "./ClientManager";
import PipelineManager from "./PipelineManager";

export const MODULES = [
  {
    id: 0,
    title: deck().module_bank_manager_title,
    component: BankManager,
    route: '/bank-manager',
    enabled: false,
  },
  {
    id: 1,
    title: deck().module_model_manager_title,
    component: ModelManager,
    route: '/model-manager',
    enabled: false,
    links: [
      {
        title: 'Modeling',
        route: '/model-manager/view-models',
      },
      {
        title: 'Development',
        route: '/model-manager/development',
      }
    ]
  },
  {
    id: 2,
    title: deck().module_client_manager_title,
    component: ClientManager,
    route: '/client-manager/view-clients',
    enabled: true
  },
  {
    id: 3,
    title: deck().module_client_manager_offering_title,
    component: ClientManager,
    route: '/client-manager/view-offerings',
    enabled: true
  },
  {
    id: 4,
    title: deck().module_pipeline_manager_title,
    component: PipelineManager,
    route: '/pipeline-manager',
    enabled: true,
    links: [
      {
        title: 'Data Pipelines',
        route: '/pipeline-manager/pipelines',
      },
      {
        title: 'Data Specs',
        route: '/pipeline-manager/view-dataspecs',
      },
      {
        title: 'Pricing Specs',
        route: '/pipeline-manager/view-pricing-config',
      }
    ]
  },

];
