import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ModelIcon} from "../../../../components/Icons";
import color_palette from "../../../../assets/color/style-constants";

const Container = styled.div`
  padding-top: 10px;
  padding-left: 10px;
`;

const ModelIdLabel = styled.p`
  font-size: 16.5px;
  font-weight: 300;
  color: ${color_palette.dark_text};
  
  margin-bottom: 0px;
`;

const ModelProductLabel = styled.p`
  font-size: 11.5px;
  font-weight: 300;
  font-style: oblique;
  color: ${color_palette.dark_text};
`;

class ModelIdElement extends React.Component {

  render() {

    let product;
    if (this.props.model.monitoring && this.props.model.monitoring.product) {
      product = this.props.model.monitoring.product;
    } else if (this.props.model.production && this.props.model.production.modelproduct) {
      product = this.props.model.production.modelproduct;
    }

    return (
      <Container className="row">
        <div className="col-4 float-right">
          <ModelIcon height={"50px"} width={"50px"} fill={color_palette.highlighted_icon} />
        </div>

        <div className="col-8">
          <ModelIdLabel>{this.props.model.id}</ModelIdLabel>
          <ModelProductLabel>{product}</ModelProductLabel>
        </div>

      </Container>
    );
  }
}

ModelIdElement.propTypes = {
  model: PropTypes.shape({
    id: PropTypes.number,
    monitoring: PropTypes.object,
    production: PropTypes.object,
  }).isRequired,
};

export default ModelIdElement;
