import React from 'react';
import {LayoutStretch} from "../../../../utils/UIUtils";
import ListHeader from "../../../ClientManager/components/ListHeader/ListHeader";
import PropTypes from "prop-types";
import {getEnvironments} from "../../actions/pipelineActions";
import connect from "react-redux/es/connect/connect";
import PipelineCard from "../../components/Cards/PipelineCard";
import {getClients} from "../../../ClientManager/actions/clientActions";
import {deck} from "../../../../assets/copy/CopyDeck";
import {pipelinesFromEnvironments} from "../../controllers/PipelineController";
import {validateLoadingAndErrors} from "../../../../components/ListComponent/ListComponent";
import {GET_CLIENTS} from "../../../ClientManager/actions/actionTypes";
import {GET_ENVIRONMENTS} from "../../actions/actionTypes";

class ViewPipelines extends React.Component {

  constructor(props) {
    super(props);

    this.refreshData = this.refreshData.bind(this);
    this.selectedItemAction = this.selectedItemAction.bind(this);
  }

  selectedItemAction(item) {
    this.props.selectedItemAction(item.id, this.props.containerId);
  }

  componentDidMount() {

    this.refreshData();
  }

  refreshData() {
    this.props.dispatch(getEnvironments());
    this.props.dispatch(getClients());
  }

  renderPipelinesDirect(environments, clients) {

    let getEnvironmentsValidationState = validateLoadingAndErrors(GET_ENVIRONMENTS, environments, this.props.errors, this.refreshData, this.props.loading, this.props.layoutStretch);
    let getClientsValidationState = validateLoadingAndErrors(GET_CLIENTS, clients, this.props.errors, this.refreshData, this.props.loading, this.props.layoutStretch);

    if (!getEnvironmentsValidationState.valid) {

      return getEnvironmentsValidationState.value;
    } else if (!getClientsValidationState.valid) {

      return getClientsValidationState.value;
    }

    var pipelines = pipelinesFromEnvironments(environments, clients);

    var pipelineCards = [];

    pipelines.map(pipeline => {

      let selected = false;
      if (pipeline.id === this.props.currentSelectedItem) {
        selected = true;
      }

      pipelineCards.push(
        <PipelineCard
          key={pipeline.id}
          pipeline={pipeline}
          layoutStretch={this.props.layoutStretch}
          cardSelected={this.selectedItemAction}
          selected={selected}/>
      );
    });

    return pipelineCards;
  }

  render() {

    var listHeader;

    switch (this.props.layoutStretch) {

      case LayoutStretch.PRIMARY_FULL: {
        listHeader = (
          <ListHeader title={deck().pipeline_details_title}/>
        );

        break;
      }
    }

    return (
      <div>

        {listHeader}

        <div className="row">

          {this.renderPipelinesDirect(this.props.environments, this.props.clients)}

        </div>
      </div>
    );
  }
}

ViewPipelines.propTypes = {
  environments: PropTypes.object,
  clients: PropTypes.object,
  dispatch: PropTypes.func,
  layoutStretch: PropTypes.number,
  selectedItemAction: PropTypes.func,
  currentSelectedItem: PropTypes.object,
  containerId: PropTypes.string,
  loading: PropTypes.object,
  errors: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    loading: state.common.loading,
    errors: state.common.errors,
    environments: state.PipelineManager.pipeline.environments,
    clients: state.ClientManager.client.clients,
  }
}

export default connect(
  mapStateToProps,
)(ViewPipelines);
