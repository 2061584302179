import * as types from '../actions/actionTypes';

export default function pipelineReducer(state = [], action) {

  switch (action.type) {

    case types.GET_DATA_SPECS: {

      return {
        ...state,
        dataSpecs: action.result,
      }
    }

    case types.GET_PRICING_SPECS: {

      return {
        ...state,
        pricingSpecs: action.result,
      }
    }

    case types.GET_ENVIRONMENTS: {

      return {
        ...state,
        environments: action.result,
      }
    }

    case types.GET_PRICING_CONFIG_FILE: {

      // NOTE: this is going to be changing on each file selection
      return {
        ...state,
        currentPricingConfigFile: action.result,
      }
    }

    case types.GET_PIPELINE_DROP_FILE_ERRORS: {

      return {
        ...state,
        currentPipelineDropFileErrors: action.result,
      }
    }

    case types.GET_PIPELINE_DROPS: {

      return {
        ...state,
        currentPipelineDrops: action.result,
      }
    }

    default:
      return { ...state };

  }
}
