import React from 'react';
import PropTypes from "prop-types";
import {Field} from "react-final-form";
import styled from "styled-components";
import color_palette from "../../assets/color/style-constants";

const TitleLabel = styled.label`

  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${color_palette.dark_text};
`;

const ErrorLabel = styled.span`
  color: ${color_palette.cancel_item} #FF6D4A;
`;

const CustomInput = styled.input`

  display: block;
	font-size: 16px;
	font-weight: regular;
	line-height: 1.3;
	padding: .6em 1.4em .5em .8em;
	width: 100%;
	max-width: 100%; 
	box-sizing: border-box;
	
	margin: 0;
	border: ${props => props.error ? "2px solid " + color_palette.cancel_item : "1px solid #aaa"};
	border-radius: 4px;
	appearance: none;
`;

class TextField extends React.Component {

  constructor(props){
    super(props);
  }

  render() {

    return(
      <Field
        name={this.props.name}
        validate={this.props.validation}>

        {({ input, meta }) => (

          <div >

            <div>
              <TitleLabel>{this.props.title}</TitleLabel>
            </div>

            <CustomInput
              type="text"
              placeholder={this.props.placeholder}
              error={(meta.error && meta.touched)}
              disabled={this.props.disabled}
              {...input} />

            <div >
              {meta.error && meta.touched && <ErrorLabel>{meta.error}</ErrorLabel>}
            </div>
          </div>
        )}

      </Field>
    );
  }
}

TextField.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  validation: PropTypes.func,
  disabled: PropTypes.bool,
};

export default TextField;
