import React from "react";
import PropTypes from "prop-types";

const ErrorIcon = ({ height, width, fill, ...rest }) => (
  <svg  width={width} height={height} viewBox="0 0 200 126" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M177.488 64.174c34.897 10.737 27.142 61.74-8.948 61.74H31.636c-34.897 0-44.143-48.616-11.334-60.845 1.193-18.195 15.808-32.213 34.003-32.213h3.28c12.23-48.02 83.515-41.757 86.497 8.053h2.088c14.914 0 27.44 9.843 31.318 23.265zM60.866 106.229c-4.772 4.773-11.93-2.386-7.158-7.158 11.334-11.632 30.423-16.106 46.231-16.106 15.808 0 35.195 4.474 46.53 16.106 4.474 4.772-2.685 11.93-7.457 7.158-9.246-9.544-26.247-13.123-39.073-13.123-12.825 0-29.826 3.579-39.073 13.123zm69.795-56.67c-5.37 0-9.545 4.474-9.545 9.544 0 5.37 4.176 9.843 9.545 9.843 5.368 0 9.842-4.474 9.842-9.843 0-5.07-4.474-9.544-9.842-9.544zm-61.443 0c5.369 0 9.544 4.474 9.544 9.544 0 5.37-4.175 9.843-9.544 9.843-5.369 0-9.545-4.474-9.545-9.843 0-5.07 4.176-9.544 9.545-9.544z"
          fill={fill}
          fillRule="evenodd"/>
  </svg>
);

ErrorIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
};

ErrorIcon.defaultProps = {
  fill: "#000",
  height: "200",
  width: "200"
};

export default ErrorIcon
