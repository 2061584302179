import React from "react";
import PropTypes from "prop-types";

const BankIcon = ({ height, width, fill, ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g fill={fill} fillRule="nonzero">
      <path d="M183.843 83.686V70.863h16.04v-31.06L100.392 0 .901 39.804v31.059h16.04v12.823h6.43v83.451h-6.43v12.824H7.332v12.823H.902v6.432h198.98v-6.432h-6.431v-12.823h-9.647v-12.824h-6.431v-83.45h6.47zm-6.431-6.431h-25.687v-6.431h25.687v6.43zM145.333 179.96h-25.686v-12.824h-6.431v-83.45h6.431V70.862h25.686v12.823h6.432v83.451h-6.432v12.824zM55.451 70.863h25.686v12.823h6.432v83.451h-6.432v12.824H55.451v-12.824H49.02v-83.45h6.431V70.862zm51.373 96.274H94v-83.45h12.824v83.45zM87.569 77.255v-6.431h25.686v6.43H87.569zm0 96.274h25.686v6.432H87.569v-6.432zM7.333 64.431V44.157l93.06-37.255 93.058 37.216v20.274H7.333v.04zm16.04 6.432h25.686v6.392H23.373v-6.392zm6.43 12.823h12.824v83.451H29.804v-83.45zm-6.43 89.843h25.686v6.432H23.373v-6.432zm163.686 12.863v6.432H13.725v-6.432H187.06zm-9.647-6.431h-25.687v-6.432h25.687v6.432zm-6.432-12.824h-12.823v-83.45h12.823v83.45z"/>
      <path d="M100.392 51.608c8.863 0 16.04-7.216 16.04-16.04 0-8.823-7.177-16.078-16.04-16.078s-16.04 7.216-16.04 16.04c0 8.823 7.177 16.078 16.04 16.078zm0-25.686c5.294 0 9.647 4.313 9.647 9.647a9.64 9.64 0 0 1-9.647 9.647c-5.294 0-9.647-4.314-9.647-9.647 0-5.334 4.353-9.647 9.647-9.647z"/>
    </g>
  </svg>

);

BankIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
};

BankIcon.defaultProps = {
  fill: "#000",
  height: "200",
  width: "200"
};

export default BankIcon
