import React from "react";
import PropTypes from "prop-types";

const FolderIcon = ({ height, width, fill, ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 200 174" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M187.457 26.226H85.291c-1.597 0-2.965-1.14-3.421-2.509L77.993 9.35C76.397 3.877 71.608 0 65.906 0h-26.91c-6.84 0-12.542 5.701-12.542 12.543v13.91H12.543C5.7 26.454 0 32.156 0 38.998v121.778c0 6.842 5.701 12.543 12.543 12.543h148.46c6.842 0 12.543-5.701 12.543-12.543v-13.91h13.911c6.842 0 12.543-5.702 12.543-12.543V38.997c0-7.07-5.701-12.771-12.543-12.771zm-23.26 134.777c0 1.825-1.597 3.421-3.422 3.421H12.315c-1.825 0-3.421-1.596-3.421-3.42V39.452c0-1.825 1.596-3.421 3.42-3.421h26.91c1.597 0 2.965 1.14 3.421 2.508l3.877 14.368c1.597 5.473 6.386 9.35 12.087 9.35h102.166c1.825 0 3.421 1.596 3.421 3.42v95.325zm26.681-26.453c0 1.824-1.596 3.42-3.42 3.42h-13.912V65.45c0-6.841-5.701-12.542-12.543-12.542H58.61c-1.596 0-2.965-1.14-3.42-2.509L51.31 36.032c-1.596-5.473-6.385-9.35-12.086-9.35h-3.65V12.77c0-1.825 1.597-3.42 3.422-3.42h26.91c1.596 0 2.964 1.14 3.42 2.508l3.877 14.367c1.596 5.473 6.386 9.35 12.087 9.35h102.166c1.825 0 3.421 1.596 3.421 3.42v95.554z"
          fill={fill}
          fillRule="nonzero"/>
  </svg>
);

FolderIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
};

FolderIcon.defaultProps = {
  fill: "#000",
  height: "200",
  width: "200"
};

export default FolderIcon
