import React from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {getDataSpecs, getEnvironments} from "../../actions/pipelineActions";
import {LayoutStretch} from "../../../../utils/UIUtils";
import ListHeader from "../../../ClientManager/components/ListHeader/ListHeader";
import {deck} from "../../../../assets/copy/CopyDeck";
import styled from "styled-components";
import SpecCard from "../../components/Cards/SpecCard";
import {setupSpecVersionedObjects} from "../../controllers/PipelineController";
import {validateLoadingAndErrors} from "../../../../components/ListComponent/ListComponent";
import {GET_DATA_SPECS} from "../../actions/actionTypes";
import { Callout } from "@blueprintjs/core";
import { SIDEBAR_ANIMATION_LENGTH } from '../../../../components/GenericPage/GenericPage';
import {getClients} from "../../../ClientManager/actions/clientActions";

const Container = styled.div`
  padding-bottom: 10px;
`;

const CalloutDiv = styled.div`
  margin-bottom: 15px;
`;

class ViewDataSpecs extends React.Component {

  constructor(props) {
    super(props);

    this.refreshData = this.refreshData.bind(this);
    this.selectedItemAction = this.selectedItemAction.bind(this);
  }

  componentDidMount() {
    this.refreshData();
  }

  refreshData() {
    this.props.dispatch(getDataSpecs());

    if (!this.props.environments) {
      this.props.dispatch(getEnvironments());
    }

    if (!this.props.clients) {
      this.props.dispatch(getClients())
    }

  }

  selectedItemAction(item) {
    const { containerId, currentSelectedItem, selectedItemAction } = this.props
    if (currentSelectedItem) {
      selectedItemAction(null);
      setTimeout(() => selectedItemAction(item.id, containerId), SIDEBAR_ANIMATION_LENGTH);
      return;
    }
    selectedItemAction(item.id, containerId);
  }

  renderDataSpecs(dataSpecs) {

    let validationState = validateLoadingAndErrors(GET_DATA_SPECS, dataSpecs, this.props.errors, this.refreshData, this.props.loading, this.props.layoutStretch);

    if (!validationState.valid) {
      return validationState.value;
    }

    var dataSpecsCards = [];

    let versionedDataSpecs = setupSpecVersionedObjects(dataSpecs);

    versionedDataSpecs.map(dataSpec => {

      let selected = false;
      if (dataSpec.id === this.props.currentSelectedItem) {
        selected = true;
      }

      dataSpecsCards.push(
        <SpecCard
          key={dataSpec.id}
          dataSpec={dataSpec}
          layoutStretch={this.props.layoutStretch}
          cardSelected={this.selectedItemAction}
          selected={selected} />
      );
    });

    return dataSpecsCards;
  }

  render() {
    var listHeader;
    var callout;

    switch (this.props.layoutStretch) {

      case LayoutStretch.PRIMARY_FULL: {
        listHeader = (
          <ListHeader
            title={deck().view_data_specs_title}
            titleTooltip={deck().view_data_specs_title_tooltip}/>
        );

        callout = (
          <CalloutDiv>
            <Callout >
              {deck().view_data_spec_note_one}
            </Callout>
            <Callout >
              {deck().view_data_spec_note_two}
            </Callout>
            <Callout >
              {deck().view_data_spec_note_three}
            </Callout>
            <Callout >
              {deck().view_data_spec_note_four}
            </Callout>
          </CalloutDiv>
        );

        break;
      }
    }

    return (

      <div>

        {listHeader}

        {callout}

        <Container className="row">
          {this.renderDataSpecs(this.props.dataSpecs)}
        </Container>

      </div>
    );
  }
}

ViewDataSpecs.propTypes = {
  dispatch: PropTypes.func,
  dataSpecs: PropTypes.object,
  environments: PropTypes.object,
  clients: PropTypes.array,
  layoutStretch: PropTypes.number,
  currentSelectedItem: PropTypes.object,
  selectedItemAction: PropTypes.func,
  containerId: PropTypes.string,
  loading: PropTypes.object,
  errors: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    loading: state.common.loading,
    errors: state.common.errors,
    dataSpecs: state.PipelineManager.pipeline.dataSpecs,
    environments: state.PipelineManager.pipeline.environments,
    clients: state.ClientManager.client.clients,
  };
}

export default connect(
  mapStateToProps,
)(ViewDataSpecs);
