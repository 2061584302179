import React from "react";
import PropTypes from "prop-types";
import FileGeneralCard from "../../components/Cards/FileGeneralCard";
import { LayoutStretch } from "../../../../utils/UIUtils";

const PricingConfigs = ({ onSelect, pricingSpec, selectedItem }) => (
  <>
    {pricingSpec.dataFileSpecifications.map((file, index) => (
      <FileGeneralCard
        key={`file_general_card_${index}`}
        file={file}
        layoutStretch={LayoutStretch.PRIMARY_HALF}
        cardSelected={onSelect}
        selected={file.name === selectedItem}
      />
    ))}
  </>
);

PricingConfigs.propTypes = {
  onSelect: PropTypes.func.isRequired,
  pricingSpec: PropTypes.object.isRequired,
  selectedItem: PropTypes.object
};

export default PricingConfigs;
