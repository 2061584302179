import React from 'react';
import PropTypes from "prop-types";
import styled from "styled-components";
import {withRouter} from "react-router-dom";
import {deck} from "../../../../assets/copy/CopyDeck";
import SectionTitleElement from "../../../ClientManager/components/Elements/SectionTitleElement";
import FileGeneralCard from "../../components/Cards/FileGeneralCard";
import color_palette from "../../../../assets/color/style-constants";
import {getEnvironments, getPipelineDrops} from "../../actions/pipelineActions";
import {connect} from "react-redux";
import {environmentFromPipelineId} from "../../controllers/PipelineController";
import {GET_PIPELINE_DROPS} from "../../actions/actionTypes";
import LoadingElement from "../../../../components/Loading/LoadingElement";
import RefreshIcon from "../../../../components/Icons/RefreshIcon";

const TopContainer = styled.div`
  border-radius: 6px;
  background-color: ${color_palette.card_background_color};
  min-height: 120px;
`;

const ViewAllButtonContainer = styled.div`

  margin-top: 10px;
  margin-bottom: 20px;
`;

const ViewAllButton = styled.button`
  
  font-size: 15px;
  font-weight: bold;
  color: ${color_palette.support_highlighted_text};
  
  border: none;
  background: none;
  cursor: pointer;
`;

const Spacer = styled.div`
  height: 10px;
`;

const RefreshButton = styled.button`

  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	
	margin-top: 10px;
`;

const EmptyDropLabel = styled.p`
  margin-top: 20px;
  margin-bottom: 20px;
`;

class ViewDrop extends React.Component {

  constructor(props) {
    super(props);

    this.viewAllDrops = this.viewAllDrops.bind(this);
    this.viewFileDetails = this.viewFileDetails.bind(this);
    this.refreshData = this.refreshData.bind(this);
  }

  componentDidMount() {

    if (!this.props.environments) {
      this.props.dispatch(getEnvironments());
    }
  }

  renderFiles(files) {

    if (!files) {
      return
    }

    let fileObjects = [];

    files.forEach((file, index) => {

      fileObjects.push(
        <FileGeneralCard
          key={"file_card_" + index}
          cardSelected={this.viewFileDetails}
          layoutStretch={this.props.layoutStretch}
          file={file}/>
      )
    });

    return fileObjects;
  }

  viewAllDrops() {
    this.props.viewAllDropsSelected();

  }
  viewFileDetails(file) {

    let route = "/pipeline-manager/pipelines/" + this.props.pipelineId + "/drops/" + this.props.pipelineDrop.key + "/files/" + file.id;

    this.props.history.push(route);
  }

  refreshData() {

    if (!this.props.environments || !window.location.pathname.split("/")[3]) {
      return;
    }

    let pipelineId = window.location.pathname.split("/")[3];

    let values = {
      environmentId: environmentFromPipelineId(this.props.environments, pipelineId).id,
      pipelineId: pipelineId
    };

    this.props.dispatch(getPipelineDrops(values));
  }

  render() {

    if (this.props.loading.includes(GET_PIPELINE_DROPS)) {
      return (
        <TopContainer className="container">

          <Spacer/>

          <LoadingElement />
        </TopContainer>
      );
    }

    let filesContainer;
    let viewAllDropsButton;
    if (this.props.pipelineDrop) {

      filesContainer = (
        <div className="row">
          {this.renderFiles(this.props.pipelineDrop.files)}
        </div>
      );

      viewAllDropsButton = (
        <div className="row">
          <ViewAllButtonContainer className="col text-right">
            <ViewAllButton onClick={this.viewAllDrops}>{deck().view_drop_all_files_button}</ViewAllButton>
          </ViewAllButtonContainer>
        </div>
      );

    } else {
      filesContainer = (
        <div className="text-center">
          <EmptyDropLabel>{deck().view_drop_empty}</EmptyDropLabel>
        </div>
      )
    }

    let drop;
    if (this.props.pipelineDrop &&
      this.props.pipelineDrop.key) {

      drop = (<p>{deck().view_drop_id}{this.props.pipelineDrop.key}</p>);
    }

    return (
      <TopContainer className="container">

        <div className="row">

          <div className="col">

            <SectionTitleElement title={deck().view_drop_header_label}/>

            {drop}
          </div>

          <div className="col text-right">

            <RefreshButton onClick={this.refreshData}>
              <RefreshIcon height={"30px"} width={"30px"} fill={color_palette.highlighted_icon}/>
            </RefreshButton>

          </div>
        </div>

        {filesContainer}

        {viewAllDropsButton}

      </TopContainer>
    );
  }
}

ViewDrop.propTypes = {
  layoutStretch: PropTypes.number,
  history: PropTypes.object,
  environments: PropTypes.object,
  dispatch: PropTypes.func,
  loading: PropTypes.object,

  pipelineDrop: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    date: PropTypes.string,
    latest: PropTypes.bool,
    files: PropTypes.array
  }).isRequired,
  pipelineId: PropTypes.string,
  viewAllDropsSelected: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loading: state.common.loading,
    environments: state.PipelineManager.pipeline.environments,
  }
}

export default connect(mapStateToProps)(withRouter(ViewDrop));
