import * as types from './actionTypes.js';
import {clientManagementAPI} from "../../../utils/APIutils";
import {
  addLoadingAPIs,
  handleStandardCatchError, processStandardSuccessCall,
  removeErrorOnAPIs, removeLoadingAPIs,
} from "../../../actions/commonActions";

/* getClients() */
export function getClients() {

  return function(dispatch) {

    var params = {};
    var body = {};
    var additionalParams = {};

    dispatch(addLoadingAPIs(types.GET_CLIENTS));
    dispatch(removeErrorOnAPIs(types.GET_CLIENTS));

    return clientManagementAPI().clientsGet(params, body, additionalParams)
      .then(function(result) {

        let shouldDispatchFullSuccess = processStandardSuccessCall(result, types.GET_CLIENTS, dispatch);

        if (shouldDispatchFullSuccess) {
          dispatch(loadGetClientsSuccess(result.data.result));
        }

        dispatch(removeLoadingAPIs(types.GET_CLIENTS));
      })
      .catch(function(errors){
        handleStandardCatchError(errors, types.GET_CLIENTS, dispatch);
      });
  }
}

export function loadGetClientsSuccess(result) {
  return { type: types.GET_CLIENTS, result: result }
}

/* getOfferings() */
export function getOfferings() {

  return function(dispatch) {

    var params = {};
    var body = {};
    var additionalParams = {};

    dispatch(addLoadingAPIs(types.GET_OFFERINGS));
    dispatch(removeErrorOnAPIs(types.GET_OFFERINGS));

    return clientManagementAPI().offeringsGet(params, body, additionalParams)
      .then(function(result) {

        let shouldDispatchFullSuccess = processStandardSuccessCall(result, types.GET_OFFERINGS, dispatch);

        if (shouldDispatchFullSuccess) {
          dispatch(loadGetOfferingsSuccess(result.data.result));
        }

        dispatch(removeLoadingAPIs(types.GET_OFFERINGS));
      })
      .catch(function(errors) {
        handleStandardCatchError(errors, types.GET_OFFERINGS, dispatch);
      })
  }
}

export function loadGetOfferingsSuccess(result) {
  return { type: types.GET_OFFERINGS, result: result }
}

/* postSubscription() */
export function postSubscription(values, onSuccess, onErrors) {

  return function(dispatch) {

    var params = {
      clientId: values.clientId,
    };
    var body = {
      offeringId: values.offeringId,
      dropFrequency: values.dropFrequency,
      region: values.region,
    };
    var additionalParams = {};

    dispatch(addLoadingAPIs(types.POST_SUBSCRIPTION));
    dispatch(removeErrorOnAPIs(types.POST_SUBSCRIPTION));

    return clientManagementAPI().clientsClientIdSubscriptionsPost(params, body, additionalParams)
      .then(function(result) {

        processStandardSuccessCall(result, types.POST_SUBSCRIPTION, dispatch, onSuccess, onErrors);

        dispatch(removeLoadingAPIs(types.POST_SUBSCRIPTION));
      })
      .catch(function(errors) {

        handleStandardCatchError(errors, types.POST_SUBSCRIPTION, dispatch, onErrors);

      });

  }
}

/* deleteSubscription() */
export function deleteSubscription(values, onSuccess, onErrors) {

  return function(dispatch) {

    var params = {
      clientId: values.clientId,
      subscriptionId: values.subscriptionId,
    };
    var body = {};
    var additionalParams = {};

    dispatch(addLoadingAPIs(types.DELETE_SUBSCRIPTION));
    dispatch(removeErrorOnAPIs(types.DELETE_SUBSCRIPTION));

    return clientManagementAPI().clientsClientIdSubscriptionsSubscriptionIdDelete(params, body, additionalParams)
      .then(function(result) {

        processStandardSuccessCall(result, types.DELETE_SUBSCRIPTION, dispatch, onSuccess, onErrors);

        dispatch(removeLoadingAPIs(types.DELETE_SUBSCRIPTION));
      })
      .catch(function(errors) {

        handleStandardCatchError(errors, types.DELETE_SUBSCRIPTION, dispatch, onErrors);

      });
  }
}
