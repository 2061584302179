import React from "react";
import PropTypes from "prop-types";
import {LayoutStretch} from "../../../../utils/UIUtils";
import styled from "styled-components";
import StatusDetailElement from "../Elements/StatusDetailElement";
import PipelineTitleElement from "../Elements/PipelineTitleElement";
import {deck} from "../../../../assets/copy/CopyDeck";
import {withRouter} from "react-router-dom";
import color_palette from "../../../../assets/color/style-constants";

const CardContainer = styled.div`
  border-radius: 6px;
  border: solid 4px transparent;
  margin-bottom: 20px;
  padding-bottom: 15px;
  background-color: ${color_palette.card_background_color};
  box-shadow: -2px 2px 4px 0 ${color_palette.default_box_shadow};
  cursor: pointer;
`;

const DataSpecLabel = styled.p`
  font-size: 13px;
  color: ${color_palette.light_header};
  line-height: 1.5;

  margin-left: 15px;

`;

const EnvironmentLabel = styled.p`

  font-size: 13px;
  text-align: right;
  color: ${color_palette.light_header};
  line-height: 1.5;

  margin-right: 15px;
`;


const TopRow = styled.div`
  margin-top: 30px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

class PipelineCard extends React.Component {

  constructor(props) {
    super(props);

    this.viewDetails = this.viewDetails.bind(this);
  }

  viewDetails() {

    let route = "/pipeline-manager/pipelines/" + this.props.pipeline.id;

    this.props.history.push(route);
  }

  render() {
    var colSize = "col-12 col-sm-12 col-md-4";

    switch (this.props.layoutStretch) {

      case LayoutStretch.PRIMARY_FULL: {
        colSize = "col-12 col-sm-6 col-md-4";
        break;
      }

      case LayoutStretch.PRIMARY_HALF: {
        colSize = "col-12";
        break;
      }

      case LayoutStretch.PRIMARY_ONE_THIRD: {
        colSize = "col-12";
        break;
      }

      case LayoutStretch.PRIMARY_TWO_THIRD: {
        colSize = "col-12";
        break;
      }
    }

    let offeringName = "";
    if (this.props.pipeline &&
      this.props.pipeline.subscription &&
      this.props.pipeline.subscription.offering &&
      this.props.pipeline.subscription.offering.name) {

      offeringName = this.props.pipeline.subscription.offering.name;
    }

    let clientName = "";
    if (this.props.pipeline &&
      this.props.pipeline.client &&
      this.props.pipeline.client.name) {

      clientName = this.props.pipeline.client.name;
    }

    let pipelineStatus = "";
    if (this.props.pipeline &&
      this.props.pipeline.status) {

      pipelineStatus = this.props.pipeline.status;
    }


    return (
      <div className={colSize}>

        <CardContainer onClick={this.viewDetails}>

          <TopRow>
              <DataSpecLabel>{offeringName}</DataSpecLabel>
              <EnvironmentLabel>{deck().pipeline_card_env_development}</EnvironmentLabel>
          </TopRow>

          <div className="row">
            <div className="col">

              <PipelineTitleElement title={clientName} />
            </div>

          </div>

          <div className="row">

            <div className="col-6">
              <StatusDetailElement title={deck().pipeline_card_owner_title} detail={deck().pipeline_card_owner_detail} />
            </div>

            <div className="col-6">
              <StatusDetailElement title={deck().pipeline_card_status_title} detail={pipelineStatus} />
            </div>
          </div>
          </CardContainer>
      </div>
    );
  }
}

PipelineCard.propTypes = {
  pipeline: PropTypes.shape({
    id: PropTypes.string,
    version: PropTypes.number,
    subscriptionId: PropTypes.string,
    activePricingConfigs: PropTypes.object,
    client: PropTypes.object,
    subscription: PropTypes.object,
    offering: PropTypes.object,
    status: PropTypes.string,
  }).isRequired,
  clientId: PropTypes.string,
  layoutStretch: PropTypes.number,
  cardSelected: PropTypes.func,
  selected: PropTypes.bool,
  history: PropTypes.object,
};

export default withRouter(PipelineCard);
