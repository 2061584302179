import React from 'react';
import PropTypes from "prop-types";
import styled from "styled-components";
import ListHeader from "../../components/ListHeader/ListHeader";
import {getOfferings} from "../../actions/clientActions";
import connect from "react-redux/es/connect/connect";
import OfferingCard from "../../components/Cards/OfferingCard";
import {deck} from "../../../../assets/copy/CopyDeck";
import {LayoutStretch} from "../../../../utils/UIUtils";
import {getDataSpecs} from "../../../PipelineManager/actions/pipelineActions";
import {validateLoadingAndErrors} from "../../../../components/ListComponent/ListComponent";
import {GET_OFFERINGS} from "../../actions/actionTypes";
import { Callout } from "@blueprintjs/core";

const Container = styled.div`
  padding-bottom: 10px;
`;

const CalloutDiv = styled.div`
  margin-bottom: 15px;
`;

class ViewOfferings extends React.Component {

  constructor(props) {
    super(props);

    this.refreshData = this.refreshData.bind(this);
    this.selectedItemAction = this.selectedItemAction.bind(this);
  }

  componentDidMount() {

    this.refreshData();
  }

  refreshData() {
    this.props.dispatch(getOfferings());
    this.props.dispatch(getDataSpecs());
  }

  dataSpecsForOffering(offering) {

    let dataSpecs = [];

    if (!this.props.dataSpecs) {
      return dataSpecs;
    }

    this.props.dataSpecs.map(dataSpec => {

      if (offering.supportedDataSpec === dataSpec.id) {

        dataSpecs.push(dataSpec);
      }
    });

    return dataSpecs;
  }

  renderOfferings(offerings, isClickable) {

    let validationState = validateLoadingAndErrors(GET_OFFERINGS, offerings, this.props.errors, this.refreshData, this.props.loading, this.props.layoutStretch);

    if (!validationState.valid) {
      return validationState.value;
    }

    let offeringCards = [];

    offerings.map(offering => {

      let selected = false;
      if (offering.id === this.props.currentSelectedItem) {
        selected = true;
      }

      offeringCards.push(
        <OfferingCard
          key={offering.id}
          offering={offering}
          isClickable={isClickable}
          layoutStretch={this.props.layoutStretch}
          dataSpecs={this.dataSpecsForOffering(offering)}
          cardSelected={this.selectedItemAction}
          selected={selected}/>
      );
    });

    return offeringCards;
  }

  selectedItemAction(item) {
    this.props.selectedItemAction(item.id, this.props.containerId);
  }

  render() {

    var listHeader;
    var callout;
    let isClickable = false;

    switch (this.props.layoutStretch) {

      case LayoutStretch.PRIMARY_FULL: {
        listHeader = (
          <ListHeader
            title={deck().view_offerings_title}
            titleTooltip={deck().view_offerings_title_tooltip}/>
        );
        callout = (
          <CalloutDiv>
            <Callout >
              {deck().view_offerings_note_one}
            </Callout>
            <Callout >
              {deck().view_offerings_note_two}
            </Callout>
          </CalloutDiv>
        );
        isClickable = true;

        break;
      }
    }

    return (
      <div>

        {listHeader}

        {callout}

        <Container className="row">
          {this.renderOfferings(this.props.offerings, isClickable)}
        </Container>
      </div>

    );
  }
}

ViewOfferings.propTypes = {
  offerings: PropTypes.object,
  dataSpecs: PropTypes.object,
  dispatch: PropTypes.func,
  layoutStretch: PropTypes.number,
  selectedItemAction: PropTypes.func,
  currentSelectedItem: PropTypes.object,
  containerId: PropTypes.string,
  loading: PropTypes.object,
  errors: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    loading: state.common.loading,
    errors: state.common.errors,
    offerings: state.ClientManager.client.offerings,
    dataSpecs: state.PipelineManager.pipeline.dataSpecs,
  };
}

export default connect(
  mapStateToProps,
)(ViewOfferings);
