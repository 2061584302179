import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import color_palette from "../../../../assets/color/style-constants";
import ReactPiwik from 'react-piwik';

const ButtonContainer = styled.div`

  border: 2px solid ${color_palette.default_border};
  border-radius: 6px;

  display: inline-block;
`;

const TitleLabel = styled.p`

  vertical-align: middle !important;
  margin: 5px;
  display:inline-block;
  font-size: 16px;
  color: ${color_palette.default_border};
`;

class GenericButton extends React.PureComponent {

  trackableAction() {
    const { action, buttonName, buttonValue } = this.props;
    ReactPiwik.push(['trackEvent', 'Button Click', buttonName, buttonValue]);
    action();
  }

  render() {

    return (

      <ButtonContainer onClick={() => this.trackableAction()}>
        <TitleLabel >+ {this.props.title}</TitleLabel>
      </ButtonContainer>

    );
  }

}

GenericButton.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.function,
  altTitle: PropTypes.string,
  buttonName: PropTypes.string,
  buttonValue: PropTypes.string
};

export default GenericButton;
