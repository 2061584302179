const deck_eng = {

  // Dashboard
  dashboard_title: "Sputnik",
  dashboard_title_alt: "Sputnik logo",

  // Footer
  footer_copyright: "© Nomis Solutions, Inc. All rights reserved.",
  footer_icon_credit_link: "Icon Credits",
  footer_user_manual: "User Manual",
  footer_user_manual_link: "https://nomissolutions.atlassian.net/wiki/spaces/TAO/pages/969212512/Sputnik+User+Manual",
  footer_sputnik_version_prefix: "v",

  open_drawer_aria: "Open drawer",

  // Drawer Items:
  drawer_section_title_1: "",
  drawer_item_title_1_1: "Dashboard",
  drawer_item_title_1_2: "Orders",
  drawer_item_title_1_3: "Customers",
  drawer_item_title_1_4: "Reports",
  drawer_item_title_1_5: "Integrations",

  drawer_section_title_2: "Saved reports",
  drawer_item_title_2_1: "Current month",
  drawer_item_title_2_2: "Last quarter",
  drawer_item_title_3_3: "Year-end sale",

  // Modules
  module_bank_manager_title: "Banks",
  module_model_manager_title: "Modelling",
  module_client_manager_title: "Clients",
  module_client_manager_offering_title: "Offerings",
  module_pipeline_manager_title: "Pipelines",

  // Client Card
  client_card_version: "v",
  client_card_id: "ID: ",
  client_card_add_subscription: "Add Subscription",
  client_card_created_subscription: "Created Subscription",
  client_card_code: "Code: ",
  client_card_client_id: "Bank ID",
  client_card_subscriptions: "Subscriptions",

  // Subscription Cell
  subscription_cell_region: "Region: ",
  subscription_cell_upload: "Upload",
  subscription_cell_close: "Close",

  // Create Subscription
  create_subscription_title: "Create New Subscription",
  create_subscription_success: "Created Subscription",

  create_subscription_product_title: "Product",
  create_subscription_region_title: "Region",
  create_subscription_region_title_tooltip: "A region defines the primary region of a Client. It does not refer to AWS Regions.",
  create_subscription_client_title: "Client",
  create_subscription_offering_title: "Offering",
  create_subscription_drop_frequency_title: "Drop Frequency",

  create_subscription_aws_region_title: "AWS Region",
  create_subscription_aws_region_placeholder: "Enter AWS Region",

  create_subscription_data_partner_title: "Data Partner",
  create_subscription_data_partner_placeholder: "Enter Data Partner",

  create_subscription_vertical_title: "Vertical",
  create_subscription_vertical_placeholder: "Enter Vertical",

  create_subscription_data_spec_version_title: "Data Spec Version",
  create_subscription_data_spec_version_placeholder: "Enter Data Spec Version",

  create_subscription_data_frequency_title: "Data Frequency",
  create_subscription_data_frequency_placeholder: "Enter Data Frequency",

  create_subscription_start_date_title: "Start Date",
  create_subscription_start_date_placeholder: "Enter Start Date",

  create_subscription_end_date_title: "End Date",
  create_subscription_end_date_placeholder: "Enter End Date",

  create_subscription_verify_title: "Please verify the information was entered correctly",
  create_subscription_submit_action: "Apply Changes",

  create_subscription_required: "Required",

  // Subscription Card
  subscription_card_active: "Active",
  subscription_card_version: "v",
  subscription_card_region: "Region:",
  subscription_card_drop_frequency: "Drop Frequency:",
  subscription_card_error_try_again: "Something went wrong, please try agai",
  subscription_card_error_contact_support: "Something went wrong, please contact support",
  subscription_card_offering_title: "Offering:",
  subscription_card_data_spec_title: "Data Spec:",
  subscription_card_pipeline_title: "Pipeline:",
  subscription_card_remove_button: "Remove",
  subscription_card_remove_success: "Subscription Removed",
  subscription_card_confirm_remove: "Are you sure you want to remove this subscription?",

  // Subscription Sidebar
  subscription_sidebar_section_title: "Subscriptions",
  subscription_sidebar_section_title_tooltip: "A client received a subscription to an offering. ",
  subscription_sidebar_add_button_title: "Add Subscription",
  subscription_sidebar_add_button_alt: "Add Subscription Button",
  subscription_sidebar_more_than_one_error: "Sorry, currently only one subscription per client could be created",

  // View Clients
  view_clients_all_title: "All Clients",
  view_clients_all_title_tooltip: "One of Nomis’s customers e.g. BMO, RBC ",

  // Alerts
  loading_alert: "Loading",

  // Errors
  general_api_error: "Error Occurred",

  // View Offerings:
  view_offerings_title: "Offerings",
  view_offerings_title_tooltip: "Nomis Clients receive subscriptions to an offering. An offering is a combination of Nomis Product (NPO, NDM, NPM), the vertical, data partner being used for the offering, supported data drop frequencies, supported regions for the offering, the data spec being used for the offering, and the pricing spec used for the offering. ",
  view_offerings_create_new: "Create New",
  view_offerings_selection_cards: "Cards",
  view_offerings_selection_table: "Table",
  view_offerings_version: "v",
  view_offerings_note_one: "Note that an offering is a specification. When a client receives a subscription, if the offering allows weekly and monthly drop frequencies, a choice has to be made on what the drop frequency is when adding the subscription.",
  view_offerings_note_two: "Note: The definition of offering is subject to change during next releases of Sputnik. There are some fields for instance data specs or pricing specs might be moved to pipeline configuration.",

  // Offering Card
  offering_card_nomis_product: "Nomis Product: ",
  offering_card_data_partner: "Data Partner: ",
  offering_card_vertical: "Vertical",
  offering_card_supported_data_spec: "Data Spec",
  offering_card_supported_pricing_spec: "Pricing Spec",

  // View Data Specs
  view_data_specs_title: "Data Specs",
  view_data_specs_title_tooltip: "A Data Spec defines the number of files, file name, and more importantly the structure, columns, data types and validation rule for data files that clients deliver for processing. ",
  view_data_spec_card_support_item: "id: ",
  view_data_spec_sidebar_section_title: "Versions",
  view_data_spec_note_one: "A Data Spec consists of a list of file specifications, one for each file the client is to deliver on the drop frequency defined in the subscription. ",
  view_data_spec_note_two: "Multiple versions of a data spec can be created. E.g. Given that there exists a data spec “ICON Home Equity” data spec, one can through the Sputnik UI add a new version for the same data spec to be used for a specific client. The new data spec version will be a copy of the existing data spec with the modifications (which can be edits to existing files or specification for new file).",
  view_data_spec_note_three: "When adding a subscription for a new client the UI will prompt to select the data spec version to be used for the given subscription. If the data spec has more than one version, one of the versions should be selected.",
  view_data_spec_note_four: "Note: A Data Spec is a generic concepts which is used to describe the content and structure of a file used for a particular purpose e.g a Pricing Spec to define the content of a pricing configuration fie or a Data Spec that defines the structure of the files clients deliver for a pipeline.",


  // Data Spec Dropdown
  data_spec_dropdown_unselected: "--",
  data_spec_dropdown_version: "Version",

  // View Pricing Specs
  view_pricing_specs_title: "Pricing Specs",
  view_pricing_specs_title_tooltip: "A Data Spec that defines the file name and content schema of a pricing spec that is uploaded for a pipeline. ",
  view_pricing_specs_note_one: "Note that pricing specs are defined independently from an offering or pipeline.",
  view_pricing_specs_note_two: "There will be multiple pricing specs managed by Sputnik.",
  view_pricing_specs_note_three: "Pricing specs are defined at development time by the development team with the help of the business team.",
  view_pricing_specs_note_four: "An offering explicitly references a pricing spec that is applied for an offering.",

  // Version Card
  version_card_version: "Version: ",
  version_card_number_of_files: "Number of Files: ",
  version_card_action_button: "View",
  version_card_pipelines_using: "Pipelines Currently Utilizing:",

  // View Spec Version
  spec_version_version_label: "Version ",
  spec_version_active_label: "Active",
  spec_version_view_action_button: "View",
  spec_version_edit_action_button: "Edit",
  spec_version_file_type: "File Type: ",
  spec_version_file_name_pattern: "File Name Pattern: ",
  spec_version_create_new_button: "Create new Version",
  spec_version_save_button: "Save Version",
  spec_version_add_new_file_button: "Add New File",
  spec_version_cancel_new_file_button: "Cancel",
  spec_version_download_field_name: "Field Name",
  spec_version_download_description: "Description",
  spec_version_download_type: "Data Type",
  spec_version_download_enum: "Enum",
  spec_version_download_format: "Format",
  spec_version_download_minimum: "Minimum",
  spec_version_download_maximum: "Maximum",
  spec_version_download_pattern: "Pattern",
  spec_version_download_minLength: "Minimum Length",
  spec_version_download_maxLength: "Maximum Length",
  spec_version_download_title: "Download",
  spec_version_description_was_not_set: "Description was not set",

  // View File Specification
  file_specification_view_title: "View file",
  file_specification_invalid_json: "Invalid JSON",
  file_specification_file_name_placeholder: "File name",
  file_specification_file_name_title: "Name",
  file_specification_file_name_pattern_placeholder: "File Name Pattern",
  file_specification_file_name_pattern_title: "File Pattern",
  file_specification_file_type_placeholder: "File Type",
  file_specification_file_type_title: "File Type",
  file_specification_file_schema_title: "File Schema",
  file_specification_file_schema_title_tooltip: "JSON Schema is a standard used to define the structure of a JSON document. 1. Describes data format. 2. Provides clear human- and machine- readable documentation. 3. Used for validating data",


  // Graph
  graph_wrong_data_provided: 'Sorry, looks like the wrong data was provided to the Graph.',
  graph_actual_title: 'Actual',
  graph_predicted_title: 'Predicted',
  graph_upper_title: 'Predicted',
  graph_percent_error_title: 'Predicted',
  graph_lower_title: 'Predicted',
  graph_percent_error_axis_title: 'Percent Error',
  graph_predicted_actual_axis_title: 'Predicated vs Actual',

  // View Models
  view_models_title: "View Models",

  // Model Monitoring Card
  model_monitoring_card_risk: "Risk",
  model_monitoring_card_performance: "Performance",
  model_monitoring_card_inference: "Inference",
  model_monitoring_card_hr_6_mo: "HR (6MO)",
  model_monitoring_card_hr_p_val: "HR P-val",
  model_monitoring_card_ks: "KS",
  model_monitoring_card_ks_p_val: "KS P-val",

  // MAPEElelment
  mape_element_m1: "M1",
  mape_element_m2: "M2",
  mape_element_m3: "M3",
  mape_element_m4: "M4",
  mape_element_m5: "M5",
  mape_element_m6: "M6",
  mape_element_mape3_title: "MAPE3",
  mape_element_mape6_title: "MAPE6",

  // View Pipelines
  view_pipelines_title: "Pipelines",

  // Pipeline Card
  pipeline_card_env_development: "Development",
  pipeline_card_env_production: "Production",
  pipeline_card_owner_title: "Owner",
  pipeline_card_owner_detail: "Nomis Admin",
  pipeline_card_status_title: "Status",

  // View Pipeline Sidebar
  pipeline_sidebar_client_id: "Client ID",
  pipeline_sidebar_client_code: "Client Code",
  pipeline_sidebar_drop_frequency: "Drop Frequency",
  pipeline_sidebar_start_date: "Start Date",
  pipeline_sidebar_end_date: "End Date",
  pipeline_sidebar_region: "Region",
  pipeline_sidebar_pipeline_id: "Pipeline ID",

  // Pipeline Status Element
  pipeline_status_initialized_name: "Initialized",
  pipeline_status_configured_name: "Configured",

  // Spec Overview Element
  spec_overview_data_title: "Data Spec",
  spec_overview_pricing_title: "Pricing Spec",
  spec_overview_version: "Version: v",
  spec_overview_name: "Name: ",
  spec_overview_number_of_files: "Number of Files: ",
  spec_overview_view_details_button: "View",

  // Pipeline Details
  pipeline_details_status: "Status",
  pipeline_details_stage: "Stage",
  pipeline_details_bank_id: "Bank ID",
  pipeline_details_product: "Product",
  pipeline_details_vertical: "Vertical",
  pipeline_details_data_partner: "Data Partner",
  pipeline_details_region: "Region",
  pipeline_details_frequency: "Frequency",
  pipeline_details_data_spec: "Data Spec",
  pipeline_details_data_spec_version: "Data Spec Version",
  pipeline_details_pricing_spec: "Pricing Spec",
  pipeline_details_quicksight_report_url: "QuickSight Reports",

  // Pipeline Details Side Bar
  pipeline_details_set_active_success: "Successfully set active file",
  pipeline_details_side_bar_upload_new_version: "Upload new version",
  pipeline_details_side_bar_upload_new_version_success: "Uploaded New Version",
  pipeline_details_confirm_dialog_title: "Set Active Pricing Configuration",
  pipeline_details_confirm_dialog_p_title: "Confirm that you want to set an Active Pricing Configuration",
  pipeline_details_confirm_dialog_p_one: "A pricing configuration that is applied for a data pipeline in development.",
  pipeline_details_confirm_dialog_p_two: "When a pricing configuration is set to be active, the file is copied into the corresponding dim_config folder in the S3 bucket for a client. The data pipeline will then use the active file for it’s next development run.",
  pipeline_details_confirm_dialog_close_button_title: "Close",
  pipeline_details_confirm_dialog_confirm_button_title: "Confirm",

  // File General Card
  file_general_file_name: "File Name:",
  file_general_active_version: "Active Version:",
  file_general_validation_state: "Validation State:",
  file_general_file_type: "File Type:",
  file_general_file_spec_name: "File Spec Name: ",
  file_general_name_validation_result: "Valid Name: ",
  file_general_content_validation_result: "Valid Content: ",
  file_general_validation_date: "Staged Date: ",
  file_general_number_of_errors: "Number of Errors: ",
  file_general_valid: "Valid",
  file_general_invalid: "Not valid",

  // Pricing File Version Card
  file_version_pricing_card_set_active: "Set Active",
  file_version_pricing_card_active_version: "Active Version",
  file_version_pricing_card_active_version_tooltip: "A pricing configuration that is applied for a data pipeline in development. When a pricing configuration is set to be active, the file is copied into the corresponding dim_config folder in the S3 bucket for a client. The data pipeline will then use the active file for it’s next development run.",
  file_version_pricing_card_download: "Download",

  // Large Pipeline Status
  pipeline_status_large_title: "Configuration Status",
  pipeline_status_data_spec_version: "Data Spec Version:",

  // Errors
  error_title_label: "Ooops!",
  error_support_title_label: "Looks like something went wrong!",
  error_type_label: "API call type: ",
  error_1000: "Unknown error has occurred",

  // Empty Element
  empty_select_type_title: "Please select something",
  empty_no_data_type_title: "No items",

  // Update Pipeline
  update_pipeline_title: "Update Pipeline",
  update_pipeline_data_spec_placeholder: "Select a data spec version...",
  update_pipeline_data_spec_title: "Data Spec Version",
  update_pipeline_start_date_placeholder: "Enter Start Date",
  update_pipeline_end_date_placeholder: "Enter End Date",
  update_pipeline_success_message: "Succesfully updated pipeline",

  // View File Meta Data
  file_meta_data_view_title: "File Details",
  file_meta_data_error_headers: "Errors",
  file_meta_data_text_copied: "Error copied to clipboard",
  file_meta_data_download_csv: "Download CSV",
  file_meta_data_file_name: "File Name",
  file_meta_data_file_path: "File Path",
  file_meta_data_file_size: "File Size",
  file_meta_data_last_modified: "Last Modified",
  file_meta_data_drop_key: "Drop Key",
  file_meta_data_file_spec_name: "File Spec Name",
  file_meta_data_validation_job_id: "Validation Job ID",
  file_meta_data_name_validation_result: "Name Validation Result",
  file_meta_data_content_validation_result: "Content Validation Result",
  file_meta_data_validation_state: "Validation State",
  file_meta_data_staged: "Staged",
  file_meta_data_num_errors: "Number of Errors",
  file_meta_data_num_lines: "Number of Lines",
  file_meta_data_num_error_lines: "Number of Error Lines",
  file_meta_data_table_group_by: "COUNT",
  file_meta_data_table_clear_group_by: "CLEAR COUNT",

  credits_title: "Icon Credits",
  credits_description: "This page contains the credits for the icons used in the application.",

  // View Drop
  view_drop_header_label: "Files",
  view_drop_all_files_button: "View All File Drops",
  view_drop_id: "Dropkey: ",
  view_drop_empty: "There are no files for this pipeline",

  // View Drops List
  view_drops_list_title: " - Pipeline Drops",
  view_drops_list_previous_label: "Previous",

  // View Pricing Configs
  view_pricing_configurations_title: "Pricing Configurations",

  // Drop Card
  drop_card_view_details: "View Details",
  drop_card_key_label: "Key: ",
  drop_card_num_of_files: "Num of Files",
  drop_card_staged: "Staged",
  drop_card_total_num_of_errors: "Num of Errors",
  drop_card_last_modified: "Last Modified",

  // EditableUrl
  update_url_error_quicksight_url: "Please add an AWS QuickSight URL",

  // New Spec Version Modal
  new_spec_version_modal_title: "Create New DataSpec Version",
  new_spec_version_modal_header: "Editable Files",
  new_spec_version_modal_warning: "This will create a copy of",
  new_spec_version_modal_add_new_file: "Add new file",

  // Sign-Up
  sign_up_header: "Sputnik Sign Up",
  sign_up_first_name: "First Name",
  sign_up_last_name: "Last Name",

  // Version Checker
  version_checker_message: "Looks like there's a new version available.",
  version_checker_reload_button: "Reload",

};
export default deck_eng;
