import * as types from '../actions/actionTypes';

export default function bankReducer(state = [], action) {

  switch (action.type) {

    case types.GET_GLOBAL_ENVIRONMENT: {

      return {
        ...state,
        environment: action.result, // TODO: move out the data element to earlier in the pipeline, this should be handled at the error stage
      }
    }

    case types.CREATE_BANK:

      return {
        ...state,
        bankName: action.bankName,
        vertical: action.vertical,
      };


    default:
      return { ...state };

  }
}
