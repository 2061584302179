import React from "react";
import PropTypes from "prop-types";
import {LayoutStretch} from "../../../../utils/UIUtils";
import styled from "styled-components";
import TitleElement, {TITLE_ELEMENT_TYPE} from "../../../../components/Elements/TitleElement";
import color_palette from "../../../../assets/color/style-constants";

const CardContainer = styled.div`
  border-radius: 6px;
  margin-bottom: 20px;
  padding-bottom: 15px;

  ${props => (
  props.selected ?
    'border: solid 4px ' + color_palette.default_border + ';' :
    'border: solid 4px transparent;'
)};

  background-color: ${color_palette.card_background_color};
  box-shadow: -2px 2px 4px 0 ${color_palette.default_box_shadow};

`;

const Row = styled.div`
  margin-right: 0px !important;
  margin-left: 0px !important;
  height: 50px;
`;

const VersionItem = styled.div`
  padding-top: 8px;
  border: solid 1px #e9eff4;
  border-radius: 6px;
`;

const VersionDescriptionLabel = styled.div`
  font-size: 11px;
  letter-spacing: 0.4px;
  text-align: center;
  color: ${color_palette.dark_text};
`;

const VersionLabel = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${color_palette.support_highlighted_text};
`;

class SpecCard extends React.Component {

  constructor(props) {
    super(props);

    this.selectCard = this.selectCard.bind(this);
  }

  selectCard() {
    const { cardSelected, dataSpec, selected } = this.props;
    if (!selected) {
      cardSelected(dataSpec);
    }
  }

  render() {

    var colSize = "col-12 col-sm-6 col-md-4";

    switch (this.props.layoutStretch) {

      case LayoutStretch.PRIMARY_FULL: {
        colSize = "col-12 col-sm-6 col-md-4";
        break;
      }

      case LayoutStretch.PRIMARY_HALF: {
        colSize = "col-12";
        break;
      }

      case LayoutStretch.PRIMARY_ONE_THIRD: {
        colSize = "col-12";
        break;
      }

      case LayoutStretch.PRIMARY_TWO_THIRD: {
        colSize = "col-12";
        break;
      }
    }

    return(
      <div className={colSize}>
        <CardContainer
          onClick={e => {
            e.stopPropagation();
            this.selectCard();
          }}
          selected={this.props.selected}
        >

          <TitleElement
            name={this.props.dataSpec.name}
            type={TITLE_ELEMENT_TYPE.SPEC} />

          <Row className="row justify-content-center">

            <VersionItem className="col-4 text-center">
              <VersionDescriptionLabel>Versions</VersionDescriptionLabel>
              <VersionLabel>{this.props.dataSpec.versions.length}</VersionLabel>
            </VersionItem>
          </Row>

        </CardContainer>
      </div>

    );
  }
}

SpecCard.propTypes = {
  dataSpec: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      versions: PropTypes.arrayOf(PropTypes.shape({
        version: PropTypes.number,
        dataFileSpecifications: PropTypes.array,
      })),
  }).isRequired,
  layoutStretch: PropTypes.number,
  cardSelected: PropTypes.func,
  selected: PropTypes.bool,
};

export default SpecCard;
