import React from 'react';
import PropTypes from "prop-types";

class Monitoring extends React.Component {

  render() {

    return (
      <div>
        <h2>Monitoring Page</h2>

        <p>Testing</p>
      </div>
    );
  }
}

Monitoring.propTypes = {
  dummyProp: PropTypes.object,
};

export default Monitoring;
