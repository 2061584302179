import React from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {LayoutStretch} from "../../../../utils/UIUtils";
import ListHeader from "../../../ClientManager/components/ListHeader/ListHeader";
import {deck} from "../../../../assets/copy/CopyDeck";
import styled from "styled-components";
import SpecCard from "../../components/Cards/SpecCard";
import {setupSpecVersionedObjects} from "../../controllers/PipelineController";
import {getPricingSpecs} from "../../actions/pipelineActions";
import {validateLoadingAndErrors} from "../../../../components/ListComponent/ListComponent";
import {GET_PRICING_SPECS} from "../../actions/actionTypes";
import { Callout } from "@blueprintjs/core";
import { SIDEBAR_ANIMATION_LENGTH } from '../../../../components/GenericPage/GenericPage';

const Container = styled.div`
  padding-bottom: 10px;
`;

const CalloutDiv = styled.div`
  margin-bottom: 15px;
`;

class ViewPricingSpecs extends React.Component {

  constructor(props) {
    super(props);

    this.refreshData = this.refreshData.bind(this);
    this.selectedItemAction = this.selectedItemAction.bind(this);
  }

  componentDidMount() {
    this.refreshData();
  }

  refreshData() {
    this.props.dispatch(getPricingSpecs());
  }

  selectedItemAction(item) {
    const { containerId, currentSelectedItem, selectedItemAction } = this.props
    if (currentSelectedItem) {
      selectedItemAction(null);
      setTimeout(() => selectedItemAction(item.id, containerId), SIDEBAR_ANIMATION_LENGTH);
      return;
    }
    selectedItemAction(item.id, containerId);
  }

  renderPricingSpecs(pricingSpecs) {

    let validationState = validateLoadingAndErrors(GET_PRICING_SPECS, pricingSpecs, this.props.errors, this.refreshData, this.props.loading, this.props.layoutStretch);

    if (!validationState.valid) {
      return validationState.value;
    }

    var pricingSpecsCards = [];

    let versionedPricingSpecs = setupSpecVersionedObjects(pricingSpecs);

    versionedPricingSpecs.map(pricingSpec => {

      let selected = false;
      if (pricingSpec.id === this.props.currentSelectedItem) {
        selected = true;
      }

      pricingSpecsCards.push(
        <SpecCard
          key={pricingSpec.id}
          dataSpec={pricingSpec}
          layoutStretch={this.props.layoutStretch}
          cardSelected={this.selectedItemAction}
          selected={selected} />
      );
    });

    return pricingSpecsCards;
  }

  render() {

    var listHeader;
    var callout;

    switch (this.props.layoutStretch) {

      case LayoutStretch.PRIMARY_FULL: {
        listHeader = (
          <ListHeader
            title={deck().view_pricing_specs_title}
            titleTooltip={deck().view_pricing_specs_title_tooltip}/>
        );

        callout = (
          <CalloutDiv>
            <Callout >
              {deck().view_pricing_specs_note_one}
            </Callout>
            <Callout >
              {deck().view_pricing_specs_note_two}
            </Callout>
            <Callout >
              {deck().view_pricing_specs_note_three}
            </Callout>
            <Callout >
              {deck().view_pricing_specs_note_four}
            </Callout>
          </CalloutDiv>
        );

        break;
      }
    }

    return(
      <div>

        {listHeader}

        {callout}

        <Container className="row">
          {this.renderPricingSpecs(this.props.pricingSpecs)}
        </Container>
      </div>
    );
  }
}

ViewPricingSpecs.propTypes = {
  dispatch: PropTypes.func,
  pricingSpecs: PropTypes.object,
  layoutStretch: PropTypes.number,
  currentSelectedItem: PropTypes.object,
  selectedItemAction: PropTypes.func,
  containerId: PropTypes.string,
  loading: PropTypes.object,
  errors: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    pricingSpecs: state.PipelineManager.pipeline.pricingSpecs,
    loading: state.common.loading,
    errors: state.common.errors,
  };
}

export default connect(
  mapStateToProps,
)(ViewPricingSpecs)
