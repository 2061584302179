import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {LayoutStretch} from "../../utils/UIUtils";
import color_palette from "../../assets/color/style-constants";

const MainContainer = styled.div`

  padding-right: 8px !important;
  padding-left: 8px !important;

`;

const CardContainer = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  
  background-color: ${color_palette.card_background_color};
  box-shadow: -2px 2px 4px 0 ${color_palette.default_box_shadow};
  border-radius: 6px;
  
  ${props => (
  props.border ?
    'border: solid 1px #e9eff4;' :
    'border: solid 1x transparent;'
  )};
  
`;

const Loader = styled.div`

  @keyframes placeHolderShimmer{
      0%{
          background-position: -468px 0
      }
      100%{
          background-position: 468px 0
      }
  }

  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 45px;
  position: relative;
`;

class LoadingCard extends React.Component {

  render() {

    var colSize = "col-12 col-sm-6 col-md-4";

    switch (this.props.layoutStretch) {

      case LayoutStretch.PRIMARY_FULL: {
        colSize = "col-12 col-sm-6 col-md-4";
        break;
      }

      case LayoutStretch.PRIMARY_HALF: {
        colSize = "col-12";
        break;
      }

      case LayoutStretch.PRIMARY_ONE_THIRD: {
        colSize = "col-12";
        break;
      }

      case LayoutStretch.PRIMARY_TWO_THIRD: {
        colSize = "col-12";
        break;
      }

      case LayoutStretch.SIDE_BAR: {
        colSize = "col-12";
        break;
      }
    }

    return(

      <MainContainer className={colSize}>

        <CardContainer border={this.props.border}>
          <Loader/>
          <br/>

          <Loader/>
          <br/>

        </CardContainer>
      </MainContainer>
    );
  }
}

LoadingCard.propTypes = {
  layoutStretch: PropTypes.number,
  border: PropTypes.bool,
};

export default LoadingCard;
