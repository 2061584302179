import * as types from '../actions/actionTypes';

export default function modelReducer(state = [], action) {

  switch (action.type) {

    case types.GET_BANK_MAPPING: {

      return {
        ...state,
        bankMap: action.results,
      }
    }

    case types.GET_MONITORING_TABLE: {

      return {
        ...state,
        monitoringModels: action.results,
      }
    }

    case types.GET_PRODUCTION_TABLE:  {

      return {
        ...state,
        productionModels: action.results,
      }
    }

    case types.GET_MODEL_PVA:  {

      return {
        ...state,
        modelPVA: action.results,
      }
    }

    default:
      return { ...state };

  }
}
