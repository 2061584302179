import React from 'react';
import PropTypes from "prop-types";
import BankCard from "../../components/BankCard/BankCard";
import {getEnvironment} from "../../actions/bankActions";
import {connect} from "react-redux";
import LoadingElement from "../../../../components/Loading/LoadingElement";

class BankList extends React.Component {

  constructor(props) {
    super(props);

    this.printEnvironment = this.printEnvironment.bind(this);
  }

  componentDidMount() {

    this.props.dispatch(getEnvironment());
  }

  static onErrors(errors) {

    console.log("Got some errors" + JSON.stringify(errors)); // eslint-disable-line no-console
  }

  printEnvironment() {

    if (this.props.environment) {

      return (
        <div>
          <p>Service: {this.props.environment.service}</p>
          <p>Stage: {this.props.environment.stage}</p>
        </div>
      );
    } else {
      return (<LoadingElement/>);
    }
  }

  render() {

    return (
      <div>
        <h2>List of Active Banks</h2>

        {this.printEnvironment()}

        <div className="album py-5 bg-light">
          <div className="container">

            <div className="row">

              <BankCard
                description={'Some Bank Number One'}
                support_button_left={'NDM'}
                support_button_right={'NPM'}
                support_message={'Stuff'}/>

              <BankCard
                description={'Some Bank Number Two'}
                support_button_left={'Left'}
                support_button_right={'Right'}
                support_message={'Stuff'}/>

              <BankCard
                description={'This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.'}
                support_button_left={'Left'}
                support_button_right={'Right'}
                support_message={'Stuff'}/>

              <BankCard
                description={'This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.'}
                support_button_left={'Left'}
                support_button_right={'Right'}
                support_message={'Stuff'}/>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

BankList.propTypes = {
  classes: PropTypes.object,
  dispatch: PropTypes.func,
  environment: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    environment: state.BankManager.bankReducer.environment,
  }
}

export default connect(
  mapStateToProps
)(BankList);
