import React from 'react';
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import styled from "styled-components";
import {connect} from "react-redux";
import {LayoutStretch} from "../../../../utils/UIUtils";
import ListHeader from "../../../ClientManager/components/ListHeader/ListHeader";
import {deck} from "../../../../assets/copy/CopyDeck";
import FileErrorCard from "../../components/Cards/FileErrorCard";
import {getEnvironments, getPipelineDropFileErrors, loadPipelineDropFileErrors} from "../../actions/pipelineActions";
import {toast} from "react-toastify";
import {GET_ENVIRONMENTS, GET_PIPELINE_DROP_FILE_ERRORS} from "../../actions/actionTypes";
import LoadingElement from "../../../../components/Loading/LoadingElement";
import ErrorElement from "../../../../components/Error/ErrorElement";
import color_palette from "../../../../assets/color/style-constants";
import {environmentFromPipelineId} from "../../controllers/PipelineController";
import sizeMe from "react-sizeme";
import {CSVLink} from "react-csv";
import LabeledContent from "../../../../components/Typography/LabeledContent";
import moment from "moment";

import FileErrorTable from './FileErrorTable';

const FileErrorsContainer = styled.div`

  margin-top: 25px;
  background-color: ${color_palette.card_background_color};
`;

const FileMetaDataContainer = styled.div`

  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  background-color: ${color_palette.card_background_color};

`;

const StyledCSVLink = styled(CSVLink)`
  background-color: ${color_palette.blue_action_button};
  border-radius: 4px;
  color: ${color_palette.white_text};
  cursor: pointer;
  font-size: 16px;
  line-height: 1.5;
  padding: 8px 16px;
  position: absolute;
  text-align: center;
  width: auto;
  :visited {
    color: ${color_palette.white_text};
  }
`;

class ViewFileMetaData extends React.Component {

  constructor(props) {
    super(props);

    this.refreshData = this.refreshData.bind(this);
  }

  componentDidMount() {
    if (!this.props.environments) {
      this.props.dispatch(getEnvironments());
    }

    if (this.props.environments &&
      !this.props.currentPipelineDropFileErrors) {

      this.refreshData();
    }
  }

  componentDidUpdate() {

    if (this.props.environments &&
      !this.props.loading.includes(GET_PIPELINE_DROP_FILE_ERRORS) &&
      !this.props.currentPipelineDropFileErrors) {

      this.refreshData();
    }
  }

  componentWillUnmount() {

    // Make sure not to cache the File Errors
    this.props.dispatch(loadPipelineDropFileErrors(null));
  }

  refreshData() {

    let route = this.routeDetails();

    let values = {
      environmentId: environmentFromPipelineId(this.props.environments, route.pipelineId).id,
      pipelineId: route.pipelineId,
      fileId: route.fileId,
    };

    this.props.dispatch(getPipelineDropFileErrors(values, ViewFileMetaData.onError));
  }

  routeDetails() {

    var splitString = this.props.router.location.pathname.split('/');

    let pipelineId = splitString[3];
    let dropId = splitString[5];
    let fileId = splitString[7];

    return ({
      pipelineId: pipelineId,
      dropId: dropId,
      fileId: fileId,
    });

  }

  static onError(errors) {

    let message = deck().general_api_error;

    // Grab the first Error and display it
    // TODO: Update to handle multiple errors
    if (errors &&
      errors.length > 0 &&
      errors[0] &&
      errors[0].errorMessage) {

      message = errors[0].errorMessage;
    }

    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER
    });

  }

  renderErrors(errors) {

    if (!errors) {
      return;
    }

    let errorItems = [];

    errors.map((error, index) => {

      errorItems.push(
        <FileErrorCard
          error={error}
          key={"error_item_" + index} />
      );
    });

    return errorItems;
  }

  topMetaContainer() {

    if (!this.props.currentPipelineDropFileErrors) {
      return
    }


    let download;
    let fileName;

    if (this.props.currentPipelineDropFileErrors.fileName &&
      this.props.currentPipelineDropFileErrors.fileName.name) {

      fileName = (
        <div className="col-lg-3 col-md-6">
          <LabeledContent
            label={deck().file_meta_data_file_name}
            content={this.props.currentPipelineDropFileErrors.fileName.name}/>
        </div>
      );

      if (this.props.currentPipelineDropFileErrors.errors) {
        download = (
          <div className="col-lg-3 col-md-6">
            <StyledCSVLink
              data={this.props.currentPipelineDropFileErrors.errors}
              filename={this.props.currentPipelineDropFileErrors.fileName.name + "_errors.csv"}>
              {deck().file_meta_data_download_csv }
            </StyledCSVLink>
          </div>
        );
      }
    }

    let filePath;
    if (this.props.currentPipelineDropFileErrors.filePath) {
      filePath = (
        <div className="col-lg-3 col-md-6">
          <LabeledContent
            label={deck().file_meta_data_file_path}
            content={this.props.currentPipelineDropFileErrors.filePath}/>
        </div>
      );
    }

    let fileSize;
    if (this.props.currentPipelineDropFileErrors.fileSize !== undefined) {
      fileSize = (
        <div className="col-lg-3 col-md-6">
          <LabeledContent
            label={deck().file_meta_data_file_size}
            content={this.props.currentPipelineDropFileErrors.fileSize}/>
        </div>
      );
    }

    let fileModifiedDate;
    if (this.props.currentPipelineDropFileErrors.fileModifiedDate) {
      fileModifiedDate = (
        <div className="col-lg-3 col-md-6">
          <LabeledContent
            label={deck().file_meta_data_last_modified}
            content={moment(this.props.currentPipelineDropFileErrors.fileModifiedDate).format("MMM Do YYYY")}/>
        </div>
      );
    }

    let dropKey;
    if (this.props.currentPipelineDropFileErrors.dropKey) {
      dropKey = (
        <div className="col-lg-3 col-md-6">
          <LabeledContent
            label={deck().file_meta_data_drop_key}
            content={this.props.currentPipelineDropFileErrors.dropKey}/>
        </div>
      );
    }

    let fileSpecName;
    if (this.props.currentPipelineDropFileErrors.fileSpecName) {
      fileSpecName = (
        <div className="col-lg-3 col-md-6">
          <LabeledContent
            label={deck().file_meta_data_file_spec_name}
            content={this.props.currentPipelineDropFileErrors.fileSpecName}/>
        </div>
      );
    }

    let validationJobId;
    if (this.props.currentPipelineDropFileErrors.validationJobId) {
      validationJobId = (
        <div className="col-lg-3 col-md-6">
          <LabeledContent
            label={deck().file_meta_data_validation_job_id}
            content={this.props.currentPipelineDropFileErrors.validationJobId}/>
        </div>
      );
    }

    let nameValidationResult;
    if (this.props.currentPipelineDropFileErrors.nameValidationResult) {
      nameValidationResult = (
        <div className="col-lg-3 col-md-6">
          <LabeledContent
            label={deck().file_meta_data_name_validation_result}
            content={this.props.currentPipelineDropFileErrors.nameValidationResult.toString()}/>
        </div>
      );
    }

    let contentValidationResult;
    if (this.props.currentPipelineDropFileErrors.contentValidationResult) {
      contentValidationResult = (
        <div className="col-lg-3 col-md-6">
          <LabeledContent
            label={deck().file_meta_data_content_validation_result}
            content={this.props.currentPipelineDropFileErrors.contentValidationResult.toString()}/>
        </div>
      );
    }

    let validationState;
    if (this.props.currentPipelineDropFileErrors.validationState) {
      validationState = (
        <div className="col-lg-3 col-md-6">
          <LabeledContent
            label={deck().file_meta_data_validation_state}
            content={this.props.currentPipelineDropFileErrors.validationState}/>
        </div>
      );
    }

    let staged;
    if (this.props.currentPipelineDropFileErrors.staged) {
      staged = (
        <div className="col-lg-3 col-md-6">
          <LabeledContent
            label={deck().file_meta_data_staged}
            content={this.props.currentPipelineDropFileErrors.staged}/>
        </div>
      );
    }

    let numErrors;
    if (this.props.currentPipelineDropFileErrors.numErrors !== undefined) {
      numErrors = (
        <div className="col-lg-3 col-md-6">
          <LabeledContent
            label={deck().file_meta_data_num_errors}
            content={this.props.currentPipelineDropFileErrors.numErrors}/>
        </div>
      );
    }

    let numLines;
    if (this.props.currentPipelineDropFileErrors.numLines !== undefined) {
      numLines = (
        <div className="col-lg-3 col-md-6">
          <LabeledContent
            label={deck().file_meta_data_num_lines}
            content={this.props.currentPipelineDropFileErrors.numLines}/>
        </div>
      );
    }

    let numErrorLines;
    if (this.props.currentPipelineDropFileErrors.numErrorLines !== undefined) {
      numErrorLines = (
        <div className="col-lg-3 col-md-6">
          <LabeledContent
            label={deck().file_meta_data_num_error_lines}
            content={this.props.currentPipelineDropFileErrors.numErrorLines}/>
        </div>
      );
    }



    return (
      <FileMetaDataContainer className="row">

        {fileName}
        {filePath}
        {fileSize}
        {fileModifiedDate}
        {dropKey}
        {fileSpecName}
        {validationJobId}
        {nameValidationResult}
        {contentValidationResult}
        {validationState}
        {staged}
        {numErrors}
        {numLines}
        {numErrorLines}
        {download}

      </FileMetaDataContainer>
    );
  }



  render() {

    if (this.props.loading.includes(GET_ENVIRONMENTS) ||
      this.props.loading.includes(GET_PIPELINE_DROP_FILE_ERRORS)) {

      return (<LoadingElement/>);

    } else if (this.props.errors.some(error => error.type === GET_PIPELINE_DROP_FILE_ERRORS)) {

      let failedCallElements = [];

      this.props.errors.map(error => {
        failedCallElements.push(<ErrorElement errors={error.errors} type={error.type}/>);
      });

      return failedCallElements;
    }

    var listHeader;

    switch (this.props.layoutStretch) {

      case LayoutStretch.PRIMARY_FULL: {
        listHeader = (
          <ListHeader
            title={deck().file_meta_data_view_title} />
        );

        break;
      }
    }

    let errors;
    if (this.props.currentPipelineDropFileErrors &&
      this.props.currentPipelineDropFileErrors.errors) {


      errors = (
        <FileErrorsContainer className="row">
          <FileErrorTable errors={this.props.currentPipelineDropFileErrors.errors} />
        </FileErrorsContainer>
      );
    }

    return (
      <div className="container">

        {listHeader}

        {this.topMetaContainer()}

        {errors}

      </div>
    );
  }
}

ViewFileMetaData.propTypes = {
  router: PropTypes.object,
  layoutStretch: PropTypes.number,
  dispatch: PropTypes.func,
  loading: PropTypes.object,
  errors: PropTypes.object,
  size: PropTypes.object,

  environments: PropTypes.object,
  currentPipelineDropFileErrors: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    loading: state.common.loading,
    errors: state.common.errors,
    router: state.router,
    currentPipelineDropFileErrors: state.PipelineManager.pipeline.currentPipelineDropFileErrors,
    environments: state.PipelineManager.pipeline.environments,
  };
}

export default sizeMe()(connect(
  mapStateToProps,
)(withRouter(ViewFileMetaData)));
