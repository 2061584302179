import React from "react";
import PropTypes from "prop-types";
import {deck} from "../../assets/copy/CopyDeck";
import { Link, withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import styled from "styled-components";
import color_palette from "../../assets/color/style-constants";

import { Auth } from 'aws-amplify';

const Navbar = styled.nav`
  background-color: ${color_palette.card_background_color} !important;
  border-bottom: 1px solid rgba(160, 160, 160, .15);

  margin-top: -20px;
`;

const LogoContainer = styled.div`
  margin-left: 30px;
`;

const LogoImage = styled.img`
  height: 27px;
`;

const Icon = styled.img`
  max-height: 25px;
`;

const UsernameLabel = styled.span`
  margin-left: 10px;
`;

const UsernameContainer = styled.div`
  border-left: 1px solid rgba(0, 0, 0, 0.5);
  margin-left: 10px;
  padding-left: 10px;
`;

const LogoutButton = styled.span`

  cursor: pointer;
`;

class TopBar extends React.Component {

  constructor(props) {
    super(props);

  }

  mapOutDropDownLinks(links) {

    let dropdownLinks = [];

    links.map(link => {

      dropdownLinks.push(
        <Link key={link.title} className="dropdown-item" to={link.route} >{link.title}</Link>
      );
    });

    return dropdownLinks;
  }

  logout() {
    Auth.signOut()
      .then(data => console.log(data))
      .catch(err => console.log(err));
  }

  render() {

    let moduleButtons = [];

    this.props.modules.map(module => {

      let dropdown = "";
      if (module.links) {
        dropdown = "dropdown ";
      }

      let active = "";
      if (this.props.selectedModule &&
        module.id === this.props.selectedModule.id) {

        active = "active "
      }

      if (module.links) {

        moduleButtons.push(
          <li className={"nav-item " + dropdown + active} key={module.id}>

            <a className="nav-link dropdown-toggle" href="#" id={module.id + "Dropdown"} role="button" data-toggle="dropdown"
               aria-haspopup="true" aria-expanded="false">

              {module.title}
            </a>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
              {this.mapOutDropDownLinks(module.links)}
            </div>

          </li>
        );

      } else {

        moduleButtons.push(
          <li className={"nav-item " + dropdown + active} key={module.id}>
            <Link className="nav-link" to={module.route} >{module.title}</Link>
          </li>
        );
      }

    });

    let username = "";
    if (this.props.user &&
      this.props.user.attributes &&
      this.props.user.attributes.given_name &&
      this.props.user.attributes.family_name) {

      username = this.props.user.attributes.given_name + " " + this.props.user.attributes.family_name;
    }

    return (

      <Navbar className="navbar navbar-expand-lg navbar-light sticky-top">

        <LogoContainer>
          <Link to='/' className="navbar-brand">
            <LogoImage src={require('../../assets/img/logo/sputnik_logo.png')} alt={deck().dashboard_title_alt} id="navbar_logo"/>
          </Link>
        </LogoContainer>


        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"/>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">

          <div className="navbar-nav mr-auto mt-2 mt-lg-0" />

          <ul className="navbar-nav my-2 my-lg-0">
            {moduleButtons}
          </ul>

          <ul className="navbar-nav my-2 my-lg-0">

            {this.props.user && (<UsernameContainer >

              <a className="nav-link "
                href="#"
                id="userDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">

                <div>
                  <Icon src={require('../../assets/img/icons/empty_profile_icon.png')} alt="..." className="img-fluid"/>
                  <UsernameLabel>{username}</UsernameLabel>

                </div>

              </a>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">

                <LogoutButton className="dropdown-item" onClick={this.logout}>Log out</LogoutButton>

              </div>

            </UsernameContainer>)}


            </ul>

        </div>

      </Navbar>

    );
  }
}

TopBar.propTypes = {
  modules: PropTypes.array.isRequired,
  selectedModule: PropTypes.object,
  dispatch: PropTypes.func,
  user: PropTypes.object
};


export default withRouter(connect(

)(TopBar));
