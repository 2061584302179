import React from 'react';

class ViewModelsSideBar extends React.Component {

  render() {
    return (
      <div>
        <p>Side Bar TBD</p>
      </div>
    );
  }
}

export default ViewModelsSideBar;
