import React from "react";
import { DotLoader } from 'react-spinners';
import styled from "styled-components";
import color_palette from "../../assets/color/style-constants";

const SpinnerContainer = styled.div`
  margin: 40px;
`;

class LoadingElement extends React.PureComponent {

  render() {

    return(
      <SpinnerContainer>

        <DotLoader
          sizeUnit={"px"}
          size={30}
          color={color_palette.highlighted_icon}
          loading={true}
          margin={"20px"}
        />
      </SpinnerContainer>
    );
  }
}

export default LoadingElement;
