import React from "react";
import PropTypes from "prop-types";
import color_palette from "../../assets/color/style-constants";

const CancelIcon = ({ height, width, fill, ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 200 200" {...rest}>
    <g fill={fill} fillRule="nonzero">
      <path d="M100 0C44.771 0 0 44.771 0 100c0 55.227 44.771 100 100 100 55.227 0 100-44.773 100-100C200 44.771 155.227 0 100 0zm0 188.889c-49.093 0-88.889-39.798-88.889-88.889 0-49.093 39.796-88.889 88.889-88.889 49.091 0 88.889 39.796 88.889 88.889 0 49.091-39.798 88.889-88.889 88.889z" />
      <path d="M112.571 100l22.785-22.784c3.457-3.458 3.457-9.114 0-12.572-3.458-3.457-9.114-3.457-12.572 0L100 87.43 77.216 64.644c-3.458-3.457-9.114-3.457-12.572 0-3.457 3.458-3.457 9.114 0 12.572L87.43 100l-22.785 22.784c-3.457 3.458-3.457 9.114 0 12.572 3.458 3.457 9.114 3.457 12.572 0L100 112.57l22.784 22.785c3.458 3.457 9.114 3.457 12.572 0 3.457-3.458 3.457-9.114 0-12.572L112.57 100z" />
    </g>
  </svg>
);

CancelIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
};

CancelIcon.defaultProps = {
  fill: color_palette.cancel_item,
  height: "200",
  width: "200"
};

export default CancelIcon
