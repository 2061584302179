import * as types from '../actions/actionTypes';

export default function bankingReducer(state = [], action) {
  switch (action.type) {

    case types.BANK_MAPPING:
      return action.bankMap;

    default:
      return state;
  }
}
