import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import color_palette from "../../../../assets/color/style-constants";

const Container = styled.div`
  border-radius: 2px;
  background-color: rgba(68, 119, 216, 0.1);
`;

const TitleLabel = styled.p`
  font-size: 10px;
  color: ${color_palette.default_text};
  
  margin-bottom: 0px;
  margin-top: 5px;
  
  text-align: center;
`;

const ValueLabel = styled.p`
  font-size: 12px;
  color: ${color_palette.dark_text};
  
  margin-bottom: 0px;
  
  text-align: center;
`;

class HRElement extends React.Component{

  render() {
    return (
      <Container>
        <TitleLabel>{this.props.item.title}</TitleLabel>
        <ValueLabel>{this.props.item.value}</ValueLabel>
      </Container>
    );
  }
}

HRElement.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

export default HRElement;
