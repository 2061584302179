import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {deck} from "../../assets/copy/CopyDeck";
import { SelectSomethingIcon, EmptyIcon } from "../Icons";
import color_palette from "../../assets/color/style-constants";

const Container = styled.div`

  margin-bottom: 20px;
`;

const IconRow = styled.div`

  height: 90px;
`;

const Title = styled.p`
  font-size: 17px;
  color: ${color_palette.dark_text};
  
  margin-bottom: 0px;
`;

class EmptyElement extends React.PureComponent {

  render() {

    let icon = (<EmptyIcon height={"80px"} fill={color_palette.highlighted_icon}/>);
    switch (this.props.type) {
      case EMPTY_TYPE.SELECT: {
        icon = (<SelectSomethingIcon height={"80px"} fill={color_palette.highlighted_icon}/>);
        break;
      }
    }

    return (
      <Container className="container">

        <div className="row">
          <IconRow className="col-12 text-center">
            {icon}
          </IconRow>
        </div>

        <div className="row">
          <div className="col-12 text-center">
            <Title>{this.props.type.title}</Title>

          </div>
        </div>

      </Container>
    );
  }
}

EmptyElement.propTypes = {
  type: PropTypes.object.isRequired,
};

export default EmptyElement;

export const EMPTY_TYPE = {
  SELECT: {
    title: deck().empty_select_type_title,
  },
  NO_DATA: {
    title: deck().empty_no_data_type_title,
  }
};
