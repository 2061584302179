import * as types from '../actions/actionTypes';

export default function testBankReducer(state = [], action) {

  switch (action.type) {

    case types.CREATE_BANK:

      return {
        ...state,
        testBankName: action.bankName,
        testVertical: action.vertical,
      };

    default:
      return { ...state };
  }
}
