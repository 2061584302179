import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Text } from "../Typography";
import color_palette from "../../assets/color/style-constants";

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const FlexColumn = styled(FlexRow)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 6px;
`;

const IconContainer = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 2px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 8px;
`;

const Label = styled(Text)`
  color: ${color_palette.light_header};
  font-size: 15px;
  font-weight: normal;
  line-height: normal;
  cursor: default;
`;

const Status = styled(Text)`
  color: ${color_palette.dark_text};
  font-size: 15px;
  font-weigth: bold;
  line-height: normal;
  cursor: default;
`;

const StatusIndicator = ({ backgroundColor, icon, label, status }) => (
  <FlexRow>
    <IconContainer backgroundColor={backgroundColor}>{icon}</IconContainer>
    <FlexColumn>
      <Label>{label}</Label>
      {typeof status === "string" ? <Status>{status}</Status> : status}
    </FlexColumn>
  </FlexRow>
);

StatusIndicator.propTypes = {
  backgroundColor: PropTypes.string,
  icon: PropTypes.node.isRequired,
  label: PropTypes.string,
  status: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired
};

StatusIndicator.defaultProps = {
  backgroundColor: "tomato",
  label: "Label",
  status: "Status"
};

export default StatusIndicator;
