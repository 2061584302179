import React from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {PropsRoute, wrapHOC} from "../../utils/RouteUtils";
import GenericPage from "../../components/GenericPage/GenericPage";
import ViewDataSpecs from "./views/ViewDataSpecs/ViewDataSpecs";
import {LayoutSide, LayoutStretch} from "../../utils/UIUtils";
import ViewDataSpecVersionSideBar from "./views/ViewDataSpecs/ViewDataSpecVersionSideBar";
import ViewPricingSpecs from "./views/ViewPricingSpecs/ViewPricingSpecs";
import ViewPricingSpecVersionSideBar from "./views/ViewPricingSpecs/ViewPricingSpecVersionSideBar";
import ViewSpecVersion from "./views/ViewSpecVersion/ViewSpecVersion";
import ViewFileSchema from "./views/ViewFileSchema/ViewFileSchema";
import ViewPipelines from "./views/ViewPipelines/ViewPipelines";
import ViewFileMetaData from "./views/ViewFileMetaData/ViewFileMetaData";
import ViewDrops from "./views/ViewDrops/ViewDrops";
import ViewPipelineDetailContainer from './views/ViewPipelineDetail/ViewPipelineDetailContainer';
import ViewPipelineDetailSideBar from './views/ViewPipelineDetail/ViewPipelineDetailSideBar'
import ViewDropPage from "./views/ViewDrop/ViewDropPage";

class PipelineManager extends React.Component {

  static mapRoutes() {

    return (
      <switch>
        <PropsRoute
          path={"/pipeline-manager/view-dataspecs"}
          exact
          component={GenericPage}
          components={[
            {
              id: "view_dataspecs_main",
              component: wrapHOC(ViewDataSpecs),
              layoutStretch: LayoutStretch.PRIMARY_FULL,
            },
            {
              id: "view_dataspecs_sidebar",
              component: wrapHOC(ViewDataSpecVersionSideBar),
              layoutStretch: LayoutStretch.SIDE_BAR,
              layoutSide: LayoutSide.RIGHT,
              subscribedTo: ["view_dataspecs_main"],
            },
          ]}
        />

        <PropsRoute
          path={"/pipeline-manager/view-pricing-config"}
          exact
          component={GenericPage}
          components={[
            {
              id: "view_pricingspecs_main",
              component: wrapHOC(ViewPricingSpecs),
              layoutStretch: LayoutStretch.PRIMARY_FULL,
            },
            {
              id: "view_pricingspecs_sidebar",
              component: wrapHOC(ViewPricingSpecVersionSideBar),
              layoutStretch: LayoutStretch.SIDE_BAR,
              layoutSide: LayoutSide.RIGHT,
              subscribedTo: ["view_pricingspecs_main"],
            },
          ]}
        />

        <PropsRoute
          path={'/pipeline-manager/view-dataspecs/:dataSpecId/:versionId'}
          exact
          component={GenericPage}
          components={[
            {
              id: 'view_data_spec_version',
              component: wrapHOC(ViewSpecVersion),
              layoutStretch: LayoutStretch.PRIMARY_FULL,
            }
          ]}

        />

        <PropsRoute
          path={'/pipeline-manager/view-dataspecs/:dataSpecId/:versionId/:fileName'}
          exact
          component={GenericPage}
          components={[
            {
              id: 'view_data_spec_version',
              component: wrapHOC(ViewFileSchema),
              layoutStretch: LayoutStretch.PRIMARY_FULL,
            }
          ]}

        />

        <PropsRoute
          path={'/pipeline-manager/view-pricing-config/:pricingSpec/:versionId'}
          exact
          component={GenericPage}
          components={[
            {
              id: 'view_pricing_spec_version',
              component: wrapHOC(ViewSpecVersion),
              layoutStretch: LayoutStretch.PRIMARY_FULL,
            }
          ]}

        />

        <PropsRoute
          path={'/pipeline-manager/view-pricing-config/:dataSpecId/:versionId/:fileName'}
          exact
          component={GenericPage}
          components={[
            {
              id: 'view_data_spec_version',
              component: wrapHOC(ViewFileSchema),
              layoutStretch: LayoutStretch.PRIMARY_FULL,
            }
          ]}

        />

        <PropsRoute
          path={'/pipeline-manager/pipelines'}
          exact
          component={GenericPage}
          components={[
            {
              id: 'view_pipelines',
              component: wrapHOC(ViewPipelines),
              layoutStretch: LayoutStretch.PRIMARY_FULL,
            }
          ]}

        />

        <PropsRoute
          path={'/pipeline-manager/pipelines/:pipelineId'}
          exact
          component={GenericPage}
          components={[
            {
              id: 'view_pipeline_detail',
              component: wrapHOC(ViewPipelineDetailContainer),
              layoutStretch: LayoutStretch.PRIMARY_FULL,
            },
            {
              id: "view_pipeline_detail_sidebar",
              component: wrapHOC(ViewPipelineDetailSideBar),
              layoutStretch: LayoutStretch.SIDE_BAR,
              layoutSide: LayoutSide.RIGHT,
              subscribedTo: ["view_pipeline_detail"],
            }
          ]}

        />

        <PropsRoute
          path={"/pipeline-manager/pipelines/:pipelineId/drops"}
          exact
          component={GenericPage}
          components={[
            {
              id: 'view_pipeline_drops',
              component: wrapHOC(ViewDrops),
              layoutStretch: LayoutStretch.PRIMARY_FULL,
            }
          ]}
        />

        <PropsRoute
          path={"/pipeline-manager/pipelines/:pipelineId/drops/:dropId"}
          exact
          component={GenericPage}
          components={[
            {
              id: 'view_pipeline_drop',
              component: wrapHOC(ViewDropPage),
              layoutStretch: LayoutStretch.PRIMARY_FULL,
            }
          ]}
        />

        <PropsRoute
          path={"/pipeline-manager/pipelines/:pipelineId/drops/:dropId/files/:fileName"}
          exact
          component={GenericPage}
          components={[
            {
              id: 'view_pipeline_drop_file',
              component: wrapHOC(ViewFileMetaData),
              layoutStretch: LayoutStretch.PRIMARY_FULL,
            }
          ]}
        />

      </switch>
    );
  }

  render() {

      return (
        <div>
          {PipelineManager.mapRoutes()}
        </div>
      );
  }
}

PipelineManager.propTypes = {
  router: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    router: state.router,
  }
}

export default connect(
  mapStateToProps,
)(PipelineManager);
