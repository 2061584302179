import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import color_palette from "../../../../assets/color/style-constants";

const TitleLabel = styled.p`
  font-size: 16px;
  color: ${color_palette.dark_text};
  
  margin-bottom: 0px;
  
`;

const TitleContainer = styled.div`

  display: inline-block;
`;

const TitleHR = styled.hr`
  border: 2px solid ${color_palette.highlighted_icon};
  border-radius: 2px;
  
  margin-top: 5px;
`;

class SectionTitleElement extends React.PureComponent {

  render() {

    return (

      <TitleContainer>
        <TitleLabel>{this.props.title}</TitleLabel>
        <TitleHR />
      </TitleContainer>

    );
  }

}

SectionTitleElement.propTypes = {
  title: PropTypes.string.isRequired
};

export default SectionTitleElement;
