import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import color_palette from "../../../../assets/color/style-constants";

const TitleLabel = styled.p`
  
  font-size: 11px;
  text-align: center;
  color: ${color_palette.dark_text};
  
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
`;

const DetailLabel = styled.p`
  
  font-size: 16px;
  text-align: center;
  color: ${color_palette.dark_text};
`;

class StatusDetailElement extends React.PureComponent {

  render() {
    return (
      <div>
        <TitleLabel>{this.props.title}</TitleLabel>
        <DetailLabel>{this.props.detail}</DetailLabel>
      </div>
    );
  }
}

StatusDetailElement.propTypes = {
  title: PropTypes.string,
  detail: PropTypes.string,
};

export default StatusDetailElement;
