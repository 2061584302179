import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {deck} from "../../../../assets/copy/CopyDeck";
import color_palette from "../../../../assets/color/style-constants";
import {withRouter} from "react-router-dom";
import {Icon} from "@blueprintjs/core";
import { IconNames } from '@blueprintjs/icons';
import Text from "../../../../components/Typography/Text";

const CardContainer = styled.div`
  padding-top: 15px;
  padding-bottom: 5px;
  
  border-radius: 6px;
  border: solid 1px #e9eff4;
  margin-bottom: 20px;
  
  box-shadow: -2px 2px 4px 0 ${color_palette.default_box_shadow};
`;

const ActionButton = styled.button`
  border-radius: 2px;
  border: solid 1px ${color_palette.blue_action_button};
  background-color: ${color_palette.blue_action_button};
  
  width: 60px;
  height: 30px;
  
  font-size: 13px;
  text-align: center;
  color: ${color_palette.white_text};
`;

const VersionLabel = styled.p`
  font-size: 15px;
  font-weight: bold;
  color: ${color_palette.dark_text};
`;

const VersionRow = styled.div`
  border-bottom: solid 1px #e9eff4;
`;

const SupportRow = styled.div`
  margin-top: 10px;
`;

const SupportLabel = styled.p`
  font-size: 14px;
  color: ${color_palette.light_header};
  
  padding-top: 5px;
`;

const ContentLink = styled(Icon)`
  color: ${color_palette.highlighted_icon};
  cursor: pointer;
  margin-left: 8px;
`;

const Content = styled(Text)`
  font-size: 15px;
  padding: 4px;
`;

class VersionCard extends React.Component {

  constructor(props) {
    super(props);

    this.selectCard = this.selectCard.bind(this);
  }

  selectCard() {
    this.props.cardSelected(this.props.version, this.props.id);
  }

  showPipeline(pipelineId) {

    let route = "/pipeline-manager/pipelines/" + pipelineId;

    this.props.history.push(route);
  }

  mapOutPipelinesUsingVersions(pipelines) {

    if (!pipelines) {
      return;
    }

    pipelines.sort(function(a, b){
      if(a.client.name < b.client.name) { return -1; }
      if(a.client.name > b.client.name) { return 1; }
      return 0;
    });

    let pipelinesUsedInVersion = [];

    pipelines.map((pipeline, index) => {

      pipelinesUsedInVersion.push(
        <Content key={"pipeline-link-" + index}>
          <div style={{display: 'flex'}}>
            <div>{pipeline.client.name}</div>
            <ContentLink icon={IconNames.SHARE} onClick={() => this.showPipeline(pipeline.id)} />
          </div>
        </Content>
      );
    });

    return pipelinesUsedInVersion;

  }

  render() {

    let pipelinesUsingVersion;
    if (this.props.pipelinesUsingVersion &&
      this.props.pipelinesUsingVersion.length > 0) {

      pipelinesUsingVersion = (
        <div className="col-12 align-self-center">
          <SupportLabel>{deck().version_card_pipelines_using}</SupportLabel>
          {this.mapOutPipelinesUsingVersions(this.props.pipelinesUsingVersion)}

        </div>
      );
    }

    let description;
    if (this.props.description) {
      description = (
        <div className="col-12 align-self-center">

          <SupportLabel>{this.props.description}</SupportLabel>
        </div>
      );
    }

    return(
      <CardContainer className="col-12">

        <VersionRow className="row">

          <div className="col-8 align-self-center">

            <VersionLabel>{deck().version_card_version}{this.props.version}</VersionLabel>
          </div>

        </VersionRow>

        <SupportRow className="row">

          <div className="col-8 align-self-center">

            <SupportLabel>{deck().version_card_number_of_files}{this.props.dataFileSpecifications.length}</SupportLabel>
          </div>


          <div className="col-4">

            <ActionButton onClick={this.selectCard} >{deck().version_card_action_button}</ActionButton>
          </div>

          {description}

          {pipelinesUsingVersion}

        </SupportRow>

      </CardContainer>
    );
  }
}

VersionCard.propTypes = {
  version: PropTypes.number,
  description: PropTypes.string,
  id: PropTypes.string,
  dataFileSpecifications: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    fileNamePattern: PropTypes.object,
    fileType: PropTypes.string,
    fileContentSchema: PropTypes.object,
  })),
  history: PropTypes.object,
  pipelinesUsingVersion: PropTypes.array,
  cardSelected: PropTypes.func,
  selected: PropTypes.bool,
  clients: PropTypes.object,
};

export default withRouter(VersionCard);
