import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import ClientCard from "../../components/Cards/ClientCard";
import {getClients} from "../../actions/clientActions";
import {deck} from "../../../../assets/copy/CopyDeck";
import styled from "styled-components";
import {LayoutStretch} from "../../../../utils/UIUtils";
import ListHeader from "../../components/ListHeader/ListHeader";
import {GET_CLIENTS} from "../../actions/actionTypes";
import {validateLoadingAndErrors} from "../../../../components/ListComponent/ListComponent";
import {getEnvironments} from "../../../PipelineManager/actions/pipelineActions";
import { SIDEBAR_ANIMATION_LENGTH } from '../../../../components/GenericPage/GenericPage';

const Container = styled.div`

  padding-top: 10px;
  padding-bottom: 10px;
`;

class ViewClients extends React.Component {

  constructor(props) {
    super(props);

    this.refreshData = this.refreshData.bind(this);
    this.selectedItemAction = this.selectedItemAction.bind(this);
  }

  componentDidMount() {

    this.refreshData();

    if (!this.props.environments) {
      this.props.dispatch(getEnvironments())
    }

  }

  componentDidUpdate() {
    // Scroll to top if coming from a newly created subscription
    if (this.props.router &&
      this.props.router.location &&
      this.props.router.location.state &&
      this.props.router.location.state.newSubscription === true) {

      window.scrollTo(0,0);
    }
  }

  refreshData() {
    this.props.dispatch(getClients());
  }

  selectedItemAction(item) {
    const { currentSelectedItem, selectedItemAction } = this.props

    if (currentSelectedItem) {
      selectedItemAction(null);
      setTimeout(() => selectedItemAction(item.id), SIDEBAR_ANIMATION_LENGTH);
      return;
    }
    selectedItemAction(item.id);
  }

  renderClients(clients) {

    let validationState = validateLoadingAndErrors(GET_CLIENTS, clients, this.props.errors, this.refreshData, this.props.loading, this.props.layoutStretch);

    if (!validationState.valid) {
      return validationState.value;
    }

    let clientCards = [];
    clients.map((client, index) => {

      let selected = false;
      if (client.id === this.props.currentSelectedItem) {
        selected = true;
      }

      clientCards.push(
        <ClientCard
          key={client.code + "_" + index}
          client={client}
          forceRefresh={this.refreshData}
          cardSelected={this.selectedItemAction}
          selected={selected} />
      );
    });

    return clientCards;

  }

  render() {

    var listHeader;

    switch (this.props.layoutStretch) {

      case LayoutStretch.PRIMARY_FULL: {
        listHeader = (
          <ListHeader
            title={deck().view_clients_all_title}
            titleTooltip={deck().view_clients_all_title_tooltip}/>
        );

        break;
      }
    }

    return (
      <div onClick={() => this.props.selectedItemAction(null)}>

        {listHeader}

        <Container className="row">
          {this.renderClients(this.props.clients)}
        </Container>

      </div>
    );
  }
}

ViewClients.propTypes = {
  classes: PropTypes.object,
  dispatch: PropTypes.func,
  clients: PropTypes.array,
  layoutStretch: PropTypes.number,
  selectedItemAction: PropTypes.func,
  currentSelectedItem: PropTypes.object,
  router: PropTypes.object,
  loading: PropTypes.object,
  errors: PropTypes.object,
  environments: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    router: state.router,
    loading: state.common.loading,
    errors: state.common.errors,
    clients: state.ClientManager.client.clients,
    environments: state.PipelineManager.pipeline.environments,
  }
}

export default connect(
  mapStateToProps
)(ViewClients);
