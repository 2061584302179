import React from 'react';
import {deck} from "../../assets/copy/CopyDeck";
import ListHeader from "../../Modules/ClientManager/components/ListHeader/ListHeader";
import styled from "styled-components";
import color_palette from "../../assets/color/style-constants";

const Container = styled.div`
  margin-top: 50px
`;

const DescriptionLabel = styled.p`

  font-size: 20px;
  color: ${color_palette.dark_text};
`;

const ItemLabel = styled.p`
  font-size: 16px;
  color: ${color_palette.dark_text};
`;

class Credits extends React.Component {

  renderIconCredits() {

    let icons = [
      {
        name: "settings_icon",
        copyrightText: "Created by Alice Des from the Noun Project"
      },
      {
        name: "cog_icon",
        copyrightText: "Created by ProSymbols from the Noun Project",
      },
      {
        name: "bank_icon",
        copyrightText: "Bank by ProSymbols from the Noun Project",
      },
      {
        name: "edit_icon",
        copyrightText: "edit by Nguyen Van Sao from the Noun Project"
      },
      {
        name: "files_icon",
        copyrightText: "File by projecthayat from the Noun Project",
      },
      {
        name: "pipeline_icon",
        copyrightText: "pipeline by Arthur Shlain from the Noun Project"
      },
      {
        name: "folder_icon",
        copyrightText: "Folder by wira wianda from the Noun Project"
      },
      {
        name: "empty_icon",
        copyrightText: "Empty by priyanka from the Noun Project"
      },
      {
        name: "select_something_icon",
        copyrightText: "select by Lil Squid from the Noun Project"
      },
      {
        name: "error_icon",
        copyrightText: "Error by Briyan Design from the Noun Project"
      },
      {
        name: "modeling_icon",
        copyrightText: "modeling by Template from the Noun Project"
      }
    ];

    let iconItems = [];
    icons.forEach(icon => {

      iconItems.push(
        <li>
          <ItemLabel>{icon.name} - {icon.copyrightText}</ItemLabel>
        </li>
      )
    });

    return iconItems;
  }

  render() {

    return (
      <Container className="container">
        <div className="row">
          <div className="col">

            <ListHeader title={deck().credits_title}/>

            <DescriptionLabel>{deck().credits_description}</DescriptionLabel>

            <ul>
              {this.renderIconCredits()}
            </ul>

          </div>

        </div>
      </Container>
    );
  }
}

export default Credits;
