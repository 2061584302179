import React from "react";
import PropTypes from "prop-types";

const EqualizerIcon = ({ fill, height, width }) => (
  <svg width={width} height={height} viewBox="0 0 194 191">
    <g fill={fill} fillRule="nonzero">
      <path d="M35.612 97.551V6.531c0-3.062-2.449-5.715-5.714-5.715-3.265 0-5.714 2.45-5.714 5.715v91.224C10.51 100.408.102 112.45.102 126.94s10.408 26.53 24.082 29.183v29.184c0 3.061 2.449 5.714 5.714 5.714 3.265 0 5.714-2.449 5.714-5.714v-29.184c13.674-2.653 24.082-14.693 24.082-29.183s-10.408-26.53-24.082-29.388zm-5.714 47.755c-10.204 0-18.367-8.367-18.367-18.367s8.367-18.368 18.367-18.368 18.367 8.368 18.367 18.368c0 10-8.163 18.367-18.367 18.367zM102.755 35.714V6.531c0-3.062-2.449-5.715-5.714-5.715-3.265 0-5.714 2.45-5.714 5.715v29.183c-13.674 2.653-24.082 14.694-24.082 29.184s10.408 26.53 24.082 29.184v91.224c0 3.061 2.449 5.714 5.714 5.714 3.265 0 5.714-2.449 5.714-5.714v-91.02c13.674-2.653 24.082-14.694 24.082-29.184s-10.408-26.735-24.082-29.388zM97.041 83.47c-10.204 0-18.368-8.367-18.368-18.367s8.368-18.367 18.368-18.367c10 0 18.367 8.367 18.367 18.367s-8.163 18.367-18.367 18.367zM193.98 126.939c0-14.49-10.409-26.53-24.082-29.184V6.531c0-3.062-2.449-5.715-5.714-5.715-3.266 0-5.715 2.45-5.715 5.715v91.224c-13.673 2.653-24.081 14.694-24.081 29.184s10.408 26.53 24.081 29.183v29.184c0 3.061 2.45 5.714 5.715 5.714 3.265 0 5.714-2.449 5.714-5.714v-29.184c13.673-2.653 24.082-14.693 24.082-29.183zm-29.796 18.367c-10.204 0-18.368-8.367-18.368-18.367s8.368-18.368 18.368-18.368c10.204 0 18.367 8.368 18.367 18.368 0 10-8.163 18.367-18.367 18.367z" />
    </g>
  </svg>
);

EqualizerIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
};

EqualizerIcon.defaultProps = {
  fill: "#000",
  height: "179",
  width: "179"
};

export default EqualizerIcon;
