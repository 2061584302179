import React from "react";
import PropTypes from "prop-types";

const PipelineIcon = ({ fill, height, width }) => (
  <svg width={width} height={height} viewBox="0 0 179 179">
    <path
      fill={fill}
      fillRule="nonzero"
      d="M174.028 0h-19.89a4.971 4.971 0 0 0-4.971 4.972v4.972h-19.89c-35.64 0-64.638 28.996-64.638 64.64v29.833c0 8.226-6.69 14.916-14.917 14.916H29.833v-4.972a4.971 4.971 0 0 0-4.972-4.972H4.972A4.971 4.971 0 0 0 0 114.36v59.667A4.971 4.971 0 0 0 4.972 179h19.89a4.971 4.971 0 0 0 4.971-4.972v-4.972h19.89c35.64 0 64.638-28.996 64.638-64.64V74.584c0-8.226 6.69-14.916 14.917-14.916h19.889v4.972a4.971 4.971 0 0 0 4.972 4.972h19.889A4.971 4.971 0 0 0 179 64.64V4.972A4.971 4.971 0 0 0 174.028 0zM20 124v45H10v-50h10v5zm130-73.214h-20c-13.786 0-25 11.134-25 24.821v29.786C105 135.503 80.328 160 50 160H30v-29.786h20c13.786 0 25-11.134 25-24.821V75.607C75 45.497 99.672 21 130 21h20v29.786zM169 60h-10V10h10v50z"
    />
  </svg>
);

PipelineIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
};

PipelineIcon.defaultProps = {
  fill: "#000",
  height: "179",
  width: "179"
};

export default PipelineIcon;
