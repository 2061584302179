import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {LayoutStretch} from "../../../../utils/UIUtils";
import {deck} from "../../../../assets/copy/CopyDeck";
import {withRouter} from "react-router-dom";

import Card from '../../../../components/Cards/Card'
import { Text } from '../../../../components/Typography'
import { OfferingIcon } from "../../../../components/Icons";
import color_palette from "../../../../assets/color/style-constants";

const StyledCard = styled(Card)`
  margin-bottom: 20px;
`;

const TopRow = styled.div`
  border-bottom: solid 1px #e9eff4;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100px;
  width: 100%;
`;

const overflowEllipsis = `
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const LabelContainer = styled.div`
  flex: 0 0 58%;
  padding: 0 15px;
`;

const TitleLabel = styled(Text)`
  font-size: 14px;
  ${overflowEllipsis}
`;

const SubtitleLabel = styled(Text)`
  color: ${color_palette.light_header};
  ${overflowEllipsis}
`;

const BottomRow = styled(TopRow)`
  border: none;
  justify-content: space-around;
  padding-top: 10px;
  height: 90px;
`;

const BottomSectionClickableContainer = styled.span`
  border: none;
  height: 100%;
  border-left: solid 1px #e9eff4;
  flex: 0 1 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding-top  7px;
  
  cursor: ${props => props.isClickable ? 'pointer;' : 'default;'}
  
  :hover {
    ${props => props.isClickable ? ("background-color: #e9eff4;") : ("")}
  }
  
`;

const BottomSectionContainer = styled.div`
  flex: 0 1 100%;
  height: 100%;
  padding: 7px;
`;

const BottomSectionTitleLabel = styled(Text)`
  opacity: 0.7;
  font-size: 12px;
  text-align: center;
  color: ${color_palette.light_header};
`;

const BottomSectionNameLabel = styled(Text)`
  text-align: center;
  ${overflowEllipsis};
`;

class OfferingCard extends React.Component {

  constructor(props) {
    super(props);

    this.state = { selected: false };

    this.selectCard = this.selectCard.bind(this);
    this.showDataSpec = this.showDataSpec.bind(this);
    this.showPricingSpec = this.showPricingSpec.bind(this);
  }

  selectCard() {
    this.props.cardSelected(this.props.offering);
  }

  showDataSpec() {

    if (!this.props.isClickable) {
      return
    }

    let route = "/pipeline-manager/view-dataspecs";

    this.props.history.push(route);
  }

  showPricingSpec() {

    if (!this.props.isClickable) {
      return
    }

    let route = "/pipeline-manager/view-pricing-config";

    this.props.history.push(route);
  }

  render() {

    var colSize = "col-12 col-sm-6 col-md-4";

    switch (this.props.layoutStretch) {

      case LayoutStretch.PRIMARY_FULL: {
        colSize = "col-12 col-sm-6 col-md-4";
        break;
      }

      case LayoutStretch.PRIMARY_HALF: {
        colSize = "col-12";
        break;
      }

      case LayoutStretch.PRIMARY_ONE_THIRD: {
        colSize = "col-12";
        break;
      }

      case LayoutStretch.PRIMARY_TWO_THIRD: {
        colSize = "col-12";
        break;
      }
    }

    return (

      <div className={colSize}>
        <StyledCard onClick={this.selectCard} selected={this.props.selected}>

          <TopRow>
            <OfferingIcon height="53" fill={"#077bfe"} />

            <LabelContainer>
              <TitleLabel>{this.props.offering.name}</TitleLabel>
              <SubtitleLabel>{deck().offering_card_nomis_product}{this.props.offering.nomisProduct}</SubtitleLabel>
              <SubtitleLabel>{deck().offering_card_data_partner}{this.props.offering.dataPartner}</SubtitleLabel>
            </LabelContainer>
          </TopRow>

          <BottomRow>

            <BottomSectionContainer>
              <BottomSectionTitleLabel>{deck().offering_card_vertical}</BottomSectionTitleLabel>
              <BottomSectionNameLabel>{this.props.offering.vertical}</BottomSectionNameLabel>
            </BottomSectionContainer>

            <BottomSectionClickableContainer onClick={this.showDataSpec} isClickable={this.props.isClickable}>
              <BottomSectionTitleLabel>{deck().offering_card_supported_data_spec}</BottomSectionTitleLabel>
              <BottomSectionNameLabel>{this.props.offering.supportedDataSpec}</BottomSectionNameLabel>
            </BottomSectionClickableContainer>

            <BottomSectionClickableContainer onClick={this.showPricingSpec} isClickable={this.props.isClickable}>
              <BottomSectionTitleLabel>{deck().offering_card_supported_pricing_spec}</BottomSectionTitleLabel>
              <BottomSectionNameLabel>{this.props.offering.supportedPricingSpec}</BottomSectionNameLabel>
            </BottomSectionClickableContainer>

          </BottomRow>

        </StyledCard>
      </div>

    );
  }
}

OfferingCard.propTypes = {
  offering: PropTypes.shape({
    id: PropTypes.number,
    dataPartner: PropTypes.string,
    dropFrequencies: PropTypes.array,
    name: PropTypes.string,
    nomisProduct: PropTypes.string,
    supportedRegions: PropTypes.array,
    version: PropTypes.number,
    vertical: PropTypes.string,
    supportedDataSpec: PropTypes.string,
    supportedPricingSpec: PropTypes.string,
  }).isRequired,
  isClickable: PropTypes.bool,
  layoutStretch: PropTypes.number,
  dataSpecs: PropTypes.array,
  cardSelected: PropTypes.func,
  selected: PropTypes.bool,
  history: PropTypes.object,
};

export default withRouter(OfferingCard);
