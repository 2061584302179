import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CustomSelect } from "../../../../components/FormComponents/DropDown";
import { deck } from "../../../../assets/copy/CopyDeck";
import color_palette from "../../../../assets/color/style-constants";

const VersionSelect = styled(CustomSelect)`
  padding: 2px 28px 2px 6px;
  margin: 2px 0;
  font-size: 15px;
  color: ${color_palette.dark_text};
  width: 100%;
`;

const { data_spec_dropdown_unselected, data_spec_dropdown_version } = deck()

const DataSpecVersionDropDown = ({
  dataSpecs = [],
  dataSpecVersion,
  onChange,
  supportedDataSpec
}) => (
  <VersionSelect
    onChange={({ target: { value } }) => onChange({ dataSpecVersion: value })}
    value={dataSpecVersion || ""}
  >
    <option disabled value="">{data_spec_dropdown_unselected}</option>
    {dataSpecs
      .filter(({ name }) => supportedDataSpec === name)
      .map(({ version }) => (
        <option key={version} value={version}>{`${data_spec_dropdown_version} ${version}`}</option>
      ))}
  </VersionSelect>
);

DataSpecVersionDropDown.propTypes = {
  dataSpecs: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataSpecVersion: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  supportedDataSpec: PropTypes.string.isRequired
};

export default DataSpecVersionDropDown;
