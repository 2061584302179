import React from "react";
import PropTypes from "prop-types";

const OfferingIcon = ({ fill, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 200 165"
    xmlns="http://www.w3.org/2000/svg">
    <g fill={"#fff"} fillRule="nonzero">
      <path d="M94.431 25.822l-22.14-14.43L10.39 34.006l22.135 14.426zM101.591 30.078L39.85 52.624l61.749 28.98 61.889-28.902zM96.942 86.547L33.44 56.744 16.376 82.711l63.817 30.818z"/>
      <path d="M98.36 96.493l-14.218 22.903A3.228 3.228 0 0 1 80 120.598L35.507 99.11v26.72l62.802 30.105.051-59.443zM107.653 25.422l62.68 22.911 20.285-18.186-63.191-23.054zM106.18 86.587l16.66 28.255 64.336-32.09-17.09-26.012z"/>
      <path d="M121.591 122.296a3.22 3.22 0 0 1-2.78-1.587l-13.998-23.74-.053 58.978 63.264-30.12V99.51l-44.995 22.445c-.462.23-.953.34-1.438.34z"/>
      <path d="M199.942 28.349a3.22 3.22 0 0 0-2.062-2.42L127.787.356a3.217 3.217 0 0 0-3.298.666l-23.242 21.542-26.785-17.46a3.237 3.237 0 0 0-2.869-.326L2.12 30.16a3.227 3.227 0 0 0-.653 5.733l26.577 17.32-19.082 29.03a3.22 3.22 0 0 0-.413 2.63 3.216 3.216 0 0 0 1.707 2.045l18.797 9.078v31.866c0 1.242.711 2.374 1.831 2.91l69.25 33.195c.11.053.228.08.344.12.08.029.158.062.238.082.266.069.537.113.81.113.272 0 .543-.044.807-.113.083-.022.158-.056.24-.082.114-.04.23-.065.34-.118l69.723-33.191a3.228 3.228 0 0 0 1.837-2.914v-31.57l18.831-9.394a3.228 3.228 0 0 0 1.676-2.044 3.215 3.215 0 0 0-.42-2.612l-19.42-29.557 23.787-21.325a3.228 3.228 0 0 0 1.015-3.013zm-98.344 53.255l-61.75-28.98 61.743-22.548L163.487 52.7l-61.89 28.904zM72.29 11.391l22.14 14.431-61.907 22.611L10.39 34.007 72.29 11.39zM33.44 56.744l63.502 29.8-16.75 26.983L16.375 82.71 33.44 56.744zm2.067 42.37L80 120.6a3.225 3.225 0 0 0 4.142-1.202L98.36 96.496l-.053 59.442-62.8-30.105v-26.72zm132.517 26.713l-63.264 30.12.053-58.978 13.998 23.74a3.22 3.22 0 0 0 4.218 1.247l44.995-22.445v26.316zm-45.184-10.985l-16.66-28.255 63.907-29.845 17.089 26.011-64.336 32.09zm47.493-66.509l-62.68-22.91 19.774-18.33 63.189 23.054-20.283 18.186z"
            fill={fill}
            fillRule="nonzero"/>
    </g>
  </svg>
);

OfferingIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
};

OfferingIcon.defaultProps = {
  fill: "#000",
  height: "200",
  width: "169"
};

export default OfferingIcon;
