import React from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {getBankMapping, getModelPVA, getMonitoringTable, getProductionTable} from "../../actions/modelActions";
import Graph, {GRAPH_STYLE} from "../../components/Graph/Graph";
import styled from "styled-components";
import {LayoutStretch} from "../../../../utils/UIUtils";
import ListHeader from "../../../ClientManager/components/ListHeader/ListHeader";
import {deck} from "../../../../assets/copy/CopyDeck";
import {toast} from "react-toastify";
import ModelMonitoringCard from "../../components/Cards/ModelMonitoringCard";
import LoadingElement from "../../../../components/Loading/LoadingElement";
import color_palette from "../../../../assets/color/style-constants";

const GraphContainer = styled.div`
  padding: 15px;
  padding-bottom: 10px;
  
  background-color: ${color_palette.white_text};
`;

const Row = styled.div`
  margin-bottom: 15px; 
`;

class ViewModels extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedModel: null,
    };

    this.selectedItemAction = this.selectedItemAction.bind(this);
  }

  componentDidMount() {

    this.props.dispatch(getBankMapping());

    // TODO: Remove the Suntrust hard coded values:
    let bankName = "Suntrust";
    let bankCode = 1004;
    let vertical = "deposits";
    let waterfall = "trans";

    this.props.dispatch(getMonitoringTable(bankName, bankCode, vertical, waterfall, this.onErrors));

    this.props.dispatch(getProductionTable(bankName, bankCode, vertical, this.onErrors));

  }

  onErrors(errors) {

    if (errors && errors.data && errors.data.message) {

      toast.error(errors.data.message, {
        position: toast.POSITION.BOTTOM_CENTER
      });

    } else {

      // TODO: Handle the wrong error
    }
  }

  selectedItemAction(item) {

    this.setState({
      selectedModel: item,
    });
    window.scrollTo(0, 0);


    // TODO: Remove the Suntrust hard coded values:
    let bankCode = 1004;
    let waterfall = "trans";
    let vertical = "deposits";
    let source = "mm";

    let modelId = item.id;
    let product = item.production.modelproduct.trim();

    this.props.dispatch(getModelPVA(source, bankCode, waterfall, modelId, product, vertical, this.onErrors));
  }

  renderGraph(){

    if (!this.state.selectedModel) {

      return (
        <div>
          <p>Please select a model</p>
        </div>
      );
    } else if (!this.props.modelPVA) {

      return (<LoadingElement/>);
    }

    return(
      <Graph data={this.props.modelPVA} graphStyle={GRAPH_STYLE.STANDARD_STOCK_GRAPH}/>
    );
  }

  aggregateModels() {

    let models = [];

    if (!this.props.monitoringModels || !this.props.productionModels) {
      return models;
    }

    // Populate all of the Monitoring Models
    this.props.monitoringModels.forEach(monitoringModel => {

      models.push({
        id: monitoringModel.modelid,
        monitoring: monitoringModel,
        production: null,
      })
    });

    // Verify against the Production Models
    this.props.productionModels.forEach(prodModel => {


      let includes = false;
      models = models.map(model => {

        if (model.id === prodModel.id) {
          model.production = prodModel;
          includes = true
        }

        return model;
      });

      if (!includes) {
        models.push({
          id: prodModel.id,
          monitoring: null,
          production: prodModel,
        })
      }
    });

    return models;
  }

  renderModels(models) {

    var modelItems = [];

    if (!models) {
      return;
    }

    models.map((model, index) => {

      let selected = false;
      if (this.state.selectedModel && model.id === this.state.selectedModel.id) {
        selected = true;
      }

      modelItems.push(
        <ModelMonitoringCard
          key={index + "-" + model.id}
          model={model}
          cardSelected={this.selectedItemAction}
          selected={selected} />
      );
    });

    return modelItems;
  }

  renderModelsTable() {

    let models = this.aggregateModels();

    if (!models || models.length < 1) {
      return(
        <div>
          <p>{deck().loading_alert}</p>
        </div>
      );
    }

    return (
      <div className="row">
        {this.renderModels(models)}
      </div>
    );
  }

  render() {

    var listHeader;

    switch (this.props.layoutStretch) {

      case LayoutStretch.PRIMARY_FULL: {
        listHeader = (
          <ListHeader
            title={deck().view_models_title} />
        );

        break;
      }
    }

    return (
      <div>

        {listHeader}

        <Row className="row">
          <div className="col-12">

            <GraphContainer>
              {this.renderGraph()}
            </GraphContainer>

          </div>
        </Row>

        <hr />

        <Row className="row">
          <div className="col-12">

            {this.renderModelsTable()}

          </div>
        </Row>
      </div>
    );
  }
}

ViewModels.propTypes = {
  dispatch: PropTypes.func,
  bankMap: PropTypes.object,
  layoutStretch: PropTypes.number,
  monitoringModels: PropTypes.array,
  productionModels: PropTypes.array,
  modelPVA: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    bankMap: state.ModelManager.model.bankMap,
    monitoringModels: state.ModelManager.model.monitoringModels,
    productionModels: state.ModelManager.model.productionModels,
    modelPVA: state.ModelManager.model.modelPVA,
  }
}

export default (connect(
  mapStateToProps,
)(ViewModels));
