import basicColor from "./colors.scss";

const color_palette = {
  app_background_color:           basicColor.off_white,
  card_background_color:          basicColor.white,
  support_highlighted_text:       basicColor.nomis_blue,
  highlighted_icon:               basicColor.nomis_blue,
  desktop_background:             basicColor.off_white,
  default_text:                   basicColor.nomis_grey,
  light_header:                   basicColor.nomis_light_grey,
  blue_action_button:             basicColor.nomis_blue,
  white_text:                     basicColor.white,
  cancel_item:                    basicColor.red,
  dark_text:                      basicColor.dark_grey,
  black_text:                     basicColor.black,
  success_item:                   basicColor.light_green,
  user_manual_anchor:             basicColor.nomis_pink,

  default_box_shadow:             "rgba(128, 152, 213, 0.08)",
  default_border:                 "rgba(77, 161, 255, 0.8)",
  cancel_item_background:         "rgba(255, 109, 74, 0.1)",
  success_item_background:        "rgba(184, 212, 148, 0.1)",

  table_border:                   basicColor.white,
  table_header:                   basicColor.nomis_blue,
  table_header_text:              basicColor.white,
  table_even_row:                 "rgba(77, 161, 255, 0.2)",
  version_check_background:       basicColor.nomis_pink,

};
export default color_palette;
