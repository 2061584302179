import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import timer from "battery-friendly-timer";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import color_palette from "../../assets/color/style-constants";
import { deck } from "../../assets/copy/CopyDeck";

import Button, { BUTTON_STATE } from "../../components/Button";

const StyledVersionChecker = styled.div`
  align-items: center;
  background-color: ${color_palette.version_check_background};
  border-color: ${color_palette.white_text};
  bottom: 0px;
  color: ${color_palette.white_text};
  display: flex;
  font-size: 16px;
  height: 48px;
  justify-content: flex-start;
  line-height: 1.5;
  padding: 16px;
  position: sticky;
  width: 100vw;
`;

const StyledReloadButton = styled(Button)`
  height: 32px;
  background-color: ${color_palette.version_check_background};
  padding: 2px 16px;
  flex: none;
  width: auto;
  margin-left: 16px;
`;

const StyledCloseIcon = styled(Icon)`
  cursor: pointer;
  margin-left: auto;
`;

class VersionChecker extends Component {
  constructor(props) {
    super(props);
    this.previousHash = null;
    this.state = {
      codeHasChanged: false,
    };

    this.clearAlert = this.clearAlert.bind(this);
    this.fetchSource = this.fetchSource.bind(this);
    this.reload = this.reload.bind(this);
  }

  componentDidMount() {
    const { tryDelay, forceDelay } = this.props;
    this.fetchSource();
    this.interval = timer.setInterval(this.fetchSource, tryDelay, forceDelay);
  }

  componentWillUnmount() {
    timer.clearInterval(this.interval);
  }

  fetchSource() {
    return fetch(`${window.location.origin}/index.html`)
      .then(response => {
        if (response.status !== 200) {
          throw new Error("offline");
        }
        return response.text();
      })
      .then(html => {
        const hash = this.hash(html);
        if (!this.previousHash) {
          this.previousHash = hash;
          return;
        }
        if (this.previousHash !== hash) {
          this.previousHash = hash;
          this.setState({ codeHasChanged: true });
        }
      })
      .catch(error => console.log(error));
  }

  hash(str) {
    const len = str.length;
    let hash = 0;
    if (len === 0) return hash;
    let i;
    for (i = 0; i < len; i++) {
      hash = (hash << 5) - hash + str.charCodeAt(i);
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  clearAlert() {
    this.setState({ codeHasChanged: false });
  }

  reload() {
    location.reload(true);
  }
  render() {
    const { codeHasChanged } = this.state;

    if (!codeHasChanged) {
      return null;
    }
    const { version_checker_message, version_checker_reload_button } = deck();
    return (
      <StyledVersionChecker>
        {version_checker_message}
        <div>
          <StyledReloadButton
            buttonName="version-reload-button"
            onClick={this.reload}
            state={BUTTON_STATE.ACTIVE}
          >
            {version_checker_reload_button}
          </StyledReloadButton>
        </div>
        <StyledCloseIcon tabIndex="0" icon={IconNames.CROSS} onClick={this.clearAlert} />
      </StyledVersionChecker>
    );
  }
}

VersionChecker.propTypes = {
  tryDelay: PropTypes.number.isRequired,
  forceDelay: PropTypes.number.isRequired
};

VersionChecker.defaultProps = {
  tryDelay: process.env.NODE_ENV === 'development' ? 10000 : 1000 * 60, // 10 seconds in development or 1 minute otherwise
  forceDelay: 1000 * 5 * 60 // 5 minutes
};

export default VersionChecker;
