import React from "react";
import PropTypes from "prop-types";

const SelectSomethingIcon = ({ height, width, fill, ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 200 150" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g fill={fill} fillRule="nonzero">
      <path d="M0 0v150h200V0H0zm196 146H4V4h192v142z"/>
      <path d="M70 35.5H30v40h40v-40zm-4 36H34v-32h32v32zM170 35.5h-40v40h40v-40zm-4 36h-32v-32h32v32zM155 85.5a5 5 0 1 0-10.001.001A5 5 0 0 0 155 85.5zm-7 0c0-1.103.897-2 2-2s2 .897 2 2-.897 2-2 2-2-.897-2-2z"/>
      <circle cx="100" cy="85.5" r="5"/>
      <path d="M55 85.5a5 5 0 1 0-10.001.001A5 5 0 0 0 55 85.5zm-7 0c0-1.103.897-2 2-2s2 .897 2 2-.897 2-2 2-2-.897-2-2zM35 101.5h30v4H35zM40 110.5h20v4H40zM85 101.5h30v4H85zM90 110.5h20v4H90zM135 101.5h30v4h-30zM140 110.5h20v4h-20zM120 35.5H80v40h40v-40zM96.659 63.949l-7.384-7.384 2.833-2.834 4.551 4.551 11.23-11.23 2.835 2.833-14.065 14.064z"/>
    </g>
  </svg>
);

SelectSomethingIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
};

SelectSomethingIcon.defaultProps = {
  fill: "#000",
  height: "200",
  width: "200"
};

export default SelectSomethingIcon
