import {ADD_ERRORS_ON_APIS, ADD_LOADING_APIS, ADD_USER_GROUPS, REMOVE_ERRORS_ON_APIS, REMOVE_LOADING_APIS} from "./actionTypes";
import {deck} from "../assets/copy/CopyDeck";

export function addUserGroups(groups = []) {
  return {
    type: ADD_USER_GROUPS,
    groups
  };
}

export function addLoadingAPIs(result) {
  return { type: ADD_LOADING_APIS, result: result }
}

export function removeLoadingAPIs(result) {
  return { type: REMOVE_LOADING_APIS, result: result }
}

export function addErrorsOnAPIs(result) {
  return { type: ADD_ERRORS_ON_APIS, result: result }
}

export function removeErrorOnAPIs(result) {
  return { type: REMOVE_ERRORS_ON_APIS, result: result }
}

export function handleStandardCatchError(errors, type, dispatch, onErrors) {

  dispatch(removeLoadingAPIs(type));

  if (errors &&
    errors.data &&
    errors.data.errors) {

    dispatch(addErrorsOnAPIs({ type: type, errors: errors.data.errors }));

    if (onErrors) {
      onErrors(errors.data.errors);
    }

  } else {

    let error = {
      error: "GeneralError",
      errorCode: 1000,
      errorMessage: deck().error_1000,
    };

    dispatch(addErrorsOnAPIs({ type: type, errors: [error] }));
    if (onErrors) {
      onErrors([error]);
    }
  }
}

export function processStandardSuccessCall(result, type, dispatch, onSuccess, onErrors) {

  // dispatch(removeLoadingAPIs(type));

  // Cases:
  // 1. Has specific errors on a success from the API
  // 2. Success with results -> pass the move forward boolean
  // 3. Success with empty result -> call the onSuccess method
  // 4. General error not associated with the rest

  if (result &&
    result.data &&
    result.data.errors &&
    onErrors) {

    dispatch(addErrorsOnAPIs({ type: type, errors: result.data.errors }));
    onErrors(result.data.errors);

    return false;

  } else if (result &&
    result.data &&
    result.data.statusMessage === "success" &&
    result.data.result)  {

    return true;

  } else if (result &&
    result.data &&
    result.data.statusMessage === "success" &&
    onSuccess) {

    onSuccess();
    return false;

  } else {

    let error = {
      error: "GeneralError",
      errorCode: 1000,
      errorMessage: deck().error_1000,
    };

    dispatch(addErrorsOnAPIs({ type: type, errors: [error] }));

    if (onErrors) {
      onErrors([error]);
    }

    return false;
  }

}
