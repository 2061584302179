import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {deck} from "../../../../assets/copy/CopyDeck";
import color_palette from "../../../../assets/color/style-constants";

const Container = styled.div`
  padding-top: 20px;
`;

const TopRow = styled.tr`
  border-bottom: solid 1px #d3d7e8;
`;

const ColTitle = styled.th`
  opacity: 0.8;
  font-size: 10px;
  font-weight: 500;
  color: ${color_palette.dark_text};
  
  text-align: center;
  
  padding: 5px;
  
  padding-right: 25px;
`;

const RowTitle = styled.th`
  font-size: 14px;
  text-align: right;
  color: ${color_palette.dark_text};
  
  padding-right: 30px;
`;

const FieldTitle = styled.td`
  opacity: 0.8;
  font-size: 14px;
  font-weight: 300;
  color: ${color_palette.black_text};
`;

class MAPEElement extends React.Component {

  render() {
    return (
      <Container className="container-fluid">

        <table>
          <thead>
          <TopRow>
            <ColTitle scope="col"/>
            <ColTitle scope="col">{deck().mape_element_m1}</ColTitle>
            <ColTitle scope="col">{deck().mape_element_m2}</ColTitle>
            <ColTitle scope="col">{deck().mape_element_m3}</ColTitle>
            <ColTitle scope="col">{deck().mape_element_m4}</ColTitle>
            <ColTitle scope="col">{deck().mape_element_m5}</ColTitle>
            <ColTitle scope="col">{deck().mape_element_m6}</ColTitle>
          </TopRow>
          </thead>

          <tbody>
          <tr>
            <RowTitle scope="row">{deck().mape_element_mape6_title}</RowTitle>
            <FieldTitle>{this.props.monitoring.m1mape6}</FieldTitle>
            <FieldTitle>{this.props.monitoring.m2mape6}</FieldTitle>
            <FieldTitle>{this.props.monitoring.m3mape6}</FieldTitle>
            <FieldTitle>{this.props.monitoring.m4mape6}</FieldTitle>
            <FieldTitle>{this.props.monitoring.m5mape6}</FieldTitle>
            <FieldTitle>{this.props.monitoring.m6mape6}</FieldTitle>
          </tr>
          <tr>
            <RowTitle scope="row">{deck().mape_element_mape3_title}</RowTitle>
            <FieldTitle>{this.props.monitoring.m1mape3}</FieldTitle>
            <FieldTitle>{this.props.monitoring.m2mape3}</FieldTitle>
            <FieldTitle>{this.props.monitoring.m3mape3}</FieldTitle>
            <FieldTitle>{this.props.monitoring.m4mape3}</FieldTitle>
            <FieldTitle>{this.props.monitoring.m5mape3}</FieldTitle>
            <FieldTitle>{this.props.monitoring.m6mape3}</FieldTitle>
          </tr>
          </tbody>
        </table>

      </Container>
    );
  }
}

MAPEElement.propTypes = {
  monitoring: PropTypes.shape({
    m1mape3: PropTypes.number,
    m1mape6: PropTypes.number,
    m2mape3: PropTypes.number,
    m2mape6: PropTypes.number,
    m3mape3: PropTypes.number,
    m3mape6: PropTypes.number,
    m4mape3: PropTypes.number,
    m4mape6: PropTypes.number,
    m5mape3: PropTypes.number,
    m5mape6: PropTypes.number,
    m6mape3: PropTypes.number,
    m6mape6: PropTypes.number,
  }).isRequired,
};

export default MAPEElement;
