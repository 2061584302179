import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

const StyledIcon = styled(Icon)`
  margin-left: 8px;
`;

const GroupByButton = ({ column }) =>
  column.canGroupBy ? (
    <StyledIcon
      tagindex="0"
      {...column.getGroupByToggleProps()}
      icon={column.isGrouped ? IconNames.TH_DISCONNECT : IconNames.PIVOT_TABLE}
    />
  ) : null;

GroupByButton.propTypes = {
  column: PropTypes.object.isRequired
};

export default GroupByButton;
