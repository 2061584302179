import React from 'react';

class Development extends React.Component {

  render() {

    return (
      <div>
        <h2>Development Page</h2>

        <p>This page is still Work in Progress</p>
      </div>
    );
  }
}

export default Development;
