// Bank Mapping
export const BANK_MAPPING = 'BANK_MAPPING';

export const ADD_LOADING_APIS = 'ADD_LOADING_APIS';
export const REMOVE_LOADING_APIS = 'REMOVE_LOADING_APIS';
export const ADD_ERRORS_ON_APIS = 'ADD_ERRORS_ON_APIS';
export const REMOVE_ERRORS_ON_APIS = 'REMOVE_ERRORS_ON_APIS';

// User Management
export const ADD_USER_GROUPS = "ADD_USER_GROUPS";
